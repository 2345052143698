import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import styles from './OptionChainTable.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setContractData } from '../../actions/ContractDataActions';

const DataTable = React.forwardRef((props, ref) => {

  const { data, totalPortfolioValue, selectedStrike, selectedExpiry, ticker, onCellSelect } = props;

  const formatCurrency = (value) => `$${parseFloat(value).toFixed(2)}`;
  const calculatePremium = (bid) => (parseFloat(bid) * 100).toFixed(2);
  const calculatePercentage = (premium) => ((premium / totalPortfolioValue) * 100).toFixed(2);
  const currentPrice = data.length > 0 ? data[0].StockPrice : null;
  const expiries = [...new Set(data.map(option => option.ExpirationDate))].sort((a, b) => new Date(a) - new Date(b));
  const strikes = [...new Set(data.map(option => option.Strike))].sort((a, b) => a - b);
  const tableContainerRef = useRef(null);
  const currentPriceRowRef = useRef(null);
  const [selectedCellData, setSelectedCellData] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const tableRef = useRef(null); 
  const savedSelections = sessionStorage.getItem('currentSelections');
  const [currentSelections, setCurrenSelections] = useState(savedSelections ? JSON.parse(savedSelections) : {});


  const handleCellSelect = (ticker, strike, expiry) => {
    const option = data.find(opt => opt.Strike === strike && opt.ExpirationDate === expiry);
    if (option) {
      const premium = calculatePremium(option.CloseBidPrice);
      const percentage = calculatePercentage(premium);
      onCellSelect(strike, expiry, premium, percentage);
      setSelectedCellData({
        bidAsk: `${parseFloat(option.CloseBidPrice).toFixed(2)}/${parseFloat(option.CloseAskPrice).toFixed(2)}`,
        premium: formatCurrency(premium),
        percentage: `${percentage}%`
      });
      setSelectedCell({ ticker, strike, expiry });
      let existingSelections = currentSelections;
      existingSelections[ticker] = { strike, expiry };
      setCurrenSelections(existingSelections);

    } else {
      setSelectedCellData(null);
      setSelectedCell(null);
    }
  };

  const getCurrentSelections = () => {
    return currentSelections;
  };

  const clearCurrentSelections = () => {
    setCurrenSelections({});
  };


  useImperativeHandle(ref, () => ({
    getCurrentSelections, clearCurrentSelections
  }));


  useEffect(() => {
    if (tableRef.current && selectedStrike && selectedExpiry) {
      const rows = Array.from(tableRef.current.getElementsByTagName('tr'));
      let foundCell = null;
      const formattedSelectedExpiry = new Date(selectedExpiry).toISOString().split('T')[0];
      const headers = Array.from(tableRef.current.querySelectorAll('thead th'));
      const expiryHeaderIndex = headers.findIndex(header => header.textContent.includes(formattedSelectedExpiry)) * 3;

      rows.forEach((row, rowIndex) => {
        if (rowIndex > 1) { 
          const cells = Array.from(row.getElementsByTagName('td'));
          cells.forEach((cell, cellIndex) => {
            cell.classList.remove(styles.highlightedCell); 
            if (cellIndex === 0 && cell.textContent === selectedStrike.toString()) {
              if (cells[expiryHeaderIndex]) {
                for (let i = 0; i < 3; i++) {
                  if (cells[expiryHeaderIndex + i]) {
                    cells[expiryHeaderIndex + i].classList.add(styles.highlightedCell);
                    foundCell = cells[expiryHeaderIndex + i];
                  }
                }
              }
            }
          });
        }
      });

      if (foundCell) {
        foundCell.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        // foundCell.classList.add(styles.highlightedCell);
      } else {
        console.log("No cell found");
      }
    }
  }, [selectedStrike, selectedExpiry]);


  useEffect(() => {
    if (currentPriceRowRef.current && tableContainerRef.current) {
      const container = tableContainerRef.current;
      const row = currentPriceRowRef.current;
      const containerHeight = container.clientHeight;
      const rowOffsetTop = row.offsetTop;
      const rowHeight = row.clientHeight;
      const scrollPosition = rowOffsetTop - containerHeight / 2 + rowHeight / 2;
      container.scrollTop = scrollPosition;
    }
  }, []);

  const calculateDaysToExpiry = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const timeDiff = expiry - today;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const adjustTableHeight = () => {
      if (tableContainerRef.current) {
        const screenHeight = window.innerHeight;
        const desiredHeight = screenHeight * 0.85;
        tableContainerRef.current.style.maxHeight = `${desiredHeight}px`;
      }
    };
    adjustTableHeight();
    window.addEventListener('resize', adjustTableHeight);
    return () => {
      window.removeEventListener('resize', adjustTableHeight);
    };
  }, []);

  const selectedCellRef = useRef(null);

  useEffect(() => {
    if (selectedCellRef.current) {
      selectedCellRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedCell]);



  return (
    <div className={styles.tableResponsive} ref={tableRef}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>STRIKE</th>
            {expiries.map((expiry, index) => (
              <React.Fragment key={index}>
                <th key={index} colSpan="3">
                  {new Date(expiry).toISOString().split('T')[0]} <span className={styles.textSmall}>({calculateDaysToExpiry(expiry)} DAYS)</span>
                </th>
              </React.Fragment>
            ))}
          </tr>
          <tr>
            <th></th>
            {expiries.map((expiry, index) => (
              <React.Fragment key={index}>
                <th>BID/ASK</th>
                <th>PREMIUM(BID)</th>
                <th>% OF PORTFOLIO VAL</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {strikes.map((strike, rowIndex) => (
            <tr key={rowIndex} ref={strike === currentPrice ? currentPriceRowRef : null} className={strike === currentPrice ? styles.currentPriceRow : ''}>
              <td>{strike}</td>
              {expiries.map((expiry, colIndex) => {
                const option = data.find(opt => opt.Strike === strike && opt.ExpirationDate === expiry);
                const premium = option ? calculatePremium(option.CloseBidPrice) : '-';
                const percentage = option ? calculatePercentage(premium) : '-';
                const isCellSelected = strike === selectedStrike && expiry === selectedExpiry;
                return (
                  <React.Fragment key={colIndex}>
                    <td onClick={() => handleCellSelect(ticker, strike, expiry)}
                      ref={isCellSelected ? selectedCellRef : null}
                      className={isCellSelected ? styles.selectedCell : ''}>
                      {option ? `${parseFloat(option.CloseBidPrice).toFixed(2)}/${parseFloat(option.CloseAskPrice).toFixed(2)}` : '-'}
                    </td>
                    <td onClick={() => handleCellSelect(ticker, strike, expiry)}
                      ref={isCellSelected ? selectedCellRef : null}
                      className={isCellSelected ? styles.selectedCell : ''}>{option ? formatCurrency(premium) : '-'}</td>
                    <td onClick={() => handleCellSelect(ticker, strike, expiry)}
                      ref={isCellSelected ? selectedCellRef : null}
                      className={isCellSelected ? styles.selectedCell : ''}>{option ? `${percentage}%` : '-'}</td>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default DataTable;