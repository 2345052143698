
import { GetPlaidHoldings } from "../Apis/GetPlaidHoldings";
import { GetPlaidPublicToken } from "../Apis/GetPlaidPublicToken";
import { GetUserPlaidAccounts } from "../Apis/GetUserPlaidAccounts";
import { ImportPlaidPortfolio } from "../Apis/ImportPlaidPortfolio";
import { SendPlaidAccessToken } from "../Apis/SendPlaidAccessToken";
import { handleApiError } from './notificationActions';

export const FETCH_LINK_TOKEN_START = "FETCH_LINK_TOKEN_START";
export const FETCH_LINK_TOKEN_SUCCESS = "FETCH_LINK_TOKEN_SUCCESS";
export const FETCH_LINK_TOKEN_FAILURE = "FETCH_LINK_TOKEN_FAILURE";
export const SEND_PLAID_ACCESS_TOKEN_START = "SEND_PLAID_ACCESS_TOKEN_START";
export const SEND_PLAID_ACCESS_TOKEN_FAILURE = "SEND_PLAID_ACCESS_TOKEN_FAILURE";
export const SEND_PLAID_ACCESS_TOKEN_SUCCESS = "SEND_PLAID_ACCESS_TOKEN_SUCCESS";
export const GET_PLAID_ACCOUNTS_START = "GET_PLAID_ACCOUNTS_START";
export const GET_PLAID_ACCOUNTS_FAILURE = "GET_PLAID_ACCOUNTS_FAILURE";
export const GET_PLAID_ACCOUNTS_SUCCESS = "GET_PLAID_ACCOUNTS_FAILURE";
export const GET_PLAID_HOLDINGS_START = "GET_PLAID_HOLDINGS_START";
export const GET_PLAID_HOLDINGS_FAILURE = "GET_PLAID_HOLDINGS_FAILURE";
export const GET_PLAID_HOLDINGS_SUCCESS = "GET_PLAID_HOLDINGS_SUCCESS";
export const IMPORT_PLAID_PORTFOLIO_START = "IMPORT_PLAID_PORTFOLIO_START";
export const IMPORT_PLAID_PORTFOLIO_FAILURE = "IMPORT_PLAID_PORTFOLIO_FAILURE";
export const IMPORT_PLAID_PORTFOLIO_SUCCESS = "IMPORT_PLAID_PORTFOLIO_SUCCESS";
export const SELECT_PLAID_CUSTOMER = "SELECT_PLAID_CUSTOMER";
export const SELECT_PLAID_ACCOUNT = "SELECT_PLAID_ACCOUNT";


export const fetchPlaidLinkToken = () => async dispatch => {
    dispatch({ type: FETCH_LINK_TOKEN_START });
    console.log("here")
    try {
        const data = await GetPlaidPublicToken();
        if(data.status === 200){

            dispatch({ type: FETCH_LINK_TOKEN_SUCCESS, payload: data.result });
        }
        else{
            handleApiError(data, dispatch);
            dispatch({ type: FETCH_LINK_TOKEN_FAILURE, payload: data.error });
        }
    } catch (error) {
        dispatch({ type: FETCH_LINK_TOKEN_FAILURE, payload: error.message });
    }
};

export const sendPlaidAccessToken = (token) => async (dispatch,getState) => {
    const clientUsername = getState().plaid.clientUsername;
    try {
        dispatch({ type: SEND_PLAID_ACCESS_TOKEN_START });
        const response = await SendPlaidAccessToken(token,clientUsername);
        if (response.status === 200) {
            // dispatch(fetchProfile());
            dispatch({ type: SEND_PLAID_ACCESS_TOKEN_SUCCESS });
            return null
        } else {
            handleApiError(response, dispatch);
            dispatch({ type: SEND_PLAID_ACCESS_TOKEN_FAILURE, payload: response.message });
            return response.status
        }
    } catch (error) {
        handleApiError(error, dispatch);
        dispatch({ type: SEND_PLAID_ACCESS_TOKEN_FAILURE, payload: error.message });
        return error.message
    }
};

export const GetPlaidAccounts = () => async (dispatch,getState) => {
    const clientUsername = getState().plaid.clientUsername;
    try {
        dispatch({ type: GET_PLAID_ACCOUNTS_START });
        const response = await GetUserPlaidAccounts(clientUsername);
        if (response.status === 200) {
            // dispatch(fetchProfile());
            dispatch({ type: GET_PLAID_ACCOUNTS_SUCCESS , payload :  response.result});
            return null
        } else {
            handleApiError(response, dispatch);
            dispatch({ type: GET_PLAID_HOLDINGS_FAILURE, payload: response.message });
            return response.status
        }
    } catch (error) {
        handleApiError(error, dispatch);
        dispatch({ type: GET_PLAID_ACCOUNTS_FAILURE, payload: error.message });
        return error.message
    }
};

export const GetPlaidAccountHoldings = () => async (dispatch,getState) => {
    const clientUsername = getState().plaid.clientUsername;
    const plaidAccountId = getState().plaid.selectedAccountId;

    try {
        dispatch({ type: GET_PLAID_HOLDINGS_START });
        const response = await GetPlaidHoldings(plaidAccountId,clientUsername);
        if (response.status === 200) {
            // dispatch(fetchProfile());
            dispatch({ type: GET_PLAID_HOLDINGS_SUCCESS , payload :  response.result});
            return null
        } else {
            handleApiError(response, dispatch);
            dispatch({ type: GET_PLAID_HOLDINGS_FAILURE, payload: response.message });
            return response.status
        }
    } catch (error) {
        handleApiError(error, dispatch);
        dispatch({ type: GET_PLAID_HOLDINGS_FAILURE, payload: error.message });
        return error.message
    }
};

export const PlaidImportPortfolio = (portfolioName) => async (dispatch,getState) => {
    const clientUsername = getState().plaid.clientUsername;
    try {
        dispatch({ type: IMPORT_PLAID_PORTFOLIO_START });
        const response = await ImportPlaidPortfolio(portfolioName,clientUsername);
        if (response.status === 200) {
            // dispatch(fetchProfile());
            dispatch({ type: IMPORT_PLAID_PORTFOLIO_SUCCESS });
            return null
        } else {
            handleApiError(response, dispatch);
            dispatch({ type: IMPORT_PLAID_PORTFOLIO_FAILURE, payload: response.message });
            return response.status
        }
    } catch (error) {
        handleApiError(error, dispatch);
        dispatch({ type: IMPORT_PLAID_PORTFOLIO_FAILURE, payload: error.message });
        return error.message
    }
};

export const selectPlaidCustomer = (clientUsername) => ({
    type: SELECT_PLAID_CUSTOMER,
    payload: clientUsername
});

export const setSelectedPlaidAccountId = (plaidAccountId) => ({
    type: SELECT_PLAID_ACCOUNT,
    payload: plaidAccountId
});