// File path: src/components/ShieldRecommendationv2/ShieldRecommendationv2.js

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ShieldRecommendationv2.scss';
import { CustomDateInput } from '../../Utilities/CustomInput';
import { fetchShieldDataIfNeeded, setSelectedIndex } from '../../actions/shieldDataActions';
import Loader from '../../Components/Loader/Loader';
import Toaster from '../../Components/Toaster/Toaster';
import DatePicker from 'react-datepicker';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from 'moment';
import Dashboard from '../Dashboard/Dashboard';
import ShoppingCart from '../ShieldRecommendation/ShoppingCart';
import { addZeroes } from "../../Utilities/AddZeros";
import { getDefaultFriday, filterThirdFriday, isFriday } from '../../Utilities/ThirdFriday';

function ShieldRecommendationv2(props) {
    const { portfolioId, isEmpty, portfolioDetails, isLoadingOptions, visibleCartItems } = props;
    const dispatch = useDispatch();

    const [shield, setShield] = useState({
        protectionPeriod: getDefaultFriday(),
        assetAllocation: "N/A",
        stdDeviation: "N/A",
        protectionLevelPercent: 0.95,
        downsideVolatility: "N/A",
        RiskScore: "N/A"
    });

    const [outputIndexTicker, setOutputIndexTicker] = useState('');

    const [sliderValue, setSliderValue] = useState(0); // To be set dynamically
    const [tooltipContent, setTooltipContent] = useState({
        protectionLevel: "N/A",
        assetAllocation: "N/A",
        stdDeviation: "N/A",
        downsideVolatility: "N/A",
        RiskScore: "N/A"
    });
    const [tooltipPosition, setTooltipPosition] = useState('');
    // const [selectedIndex, setSelectedIndex] = useState(null);
    const [price, setPrice] = useState('-');
    const bestIndexRef = useRef(null);
    const [selectedStockOptions, setSelectedStockOptions] = useState([]);
    const [selectedIndexOptions, setSelectedIndexOptions] = useState({});
    const [otherHoldingsTickers, setOtherHoldingsTickers] = useState([]);
    const [shoppingFlag, setShoppingFlag] = useState(false);
    const formattedDate = moment(shield.protectionPeriod).format('YYYY-MM-DD');
    const selectedIndex = useSelector((state) => state.shieldData.selectedIndex);

    const shieldDataKey = `${portfolioId}_${formattedDate}_${selectedIndex}`;
    const shieldDataState = useSelector(state => state.shieldData);
    const shieldDataEntry = shieldDataState.data[shieldDataKey];
    const loadingShieldData = shieldDataState.loading[shieldDataKey];
    const shieldData = shieldDataEntry?.shieldData;
    const existingOptionHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};
    const totalPremium = sessionStorage.getItem('totalPremium') || 0;
    // const totalCost = useSelector(state => state.selection.PutBuying.totalCost);
    const indextotalCost = useSelector((state) => state.selection.PutBuying.totalCost);
    console.log("indextotalCost", indextotalCost)
    // const [totalCost, setTotalCost] = useState(Number(sessionStorage.getItem('totalCost')) || 0);
    // console.log(" inside recomm", totalCost)
    const [showAssetAllocation, setShowAssetAllocation] = useState(false);
    const [sliderMin, setSliderMin] = useState(0);
    const [sliderMax, setSliderMax] = useState(100);

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const [totalCost, setTotalCost] = useState(() => JSON.parse(sessionStorage.getItem('totalCost')) || 0);

    useEffect(() => {
        const handleTotalCostUpdate = () => {
            const updatedTotalCost = JSON.parse(sessionStorage.getItem('totalCost')) || 0;
            setTotalCost(updatedTotalCost);
        };

        window.addEventListener('totalCostUpdated', handleTotalCostUpdate);
        return () => {
            window.removeEventListener('totalCostUpdated', handleTotalCostUpdate);
        };
    }, []);


    const clearSessionStorage = () => {
        sessionStorage.removeItem('filteredTickers');
        sessionStorage.removeItem('stockOptions');
        // const existingHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};

        // if (existingHoldings['ShieldRecommendation']) {
        //     delete existingHoldings['ShieldRecommendation'];
        //     sessionStorage.setItem('existingOptionHoldings', JSON.stringify(existingHoldings));
        //     console.log("Removed ShieldRecommendation from existingOptionHoldings");
        // }
    };

    // useEffect(() => {
    //     if (!isEmpty && (selectedIndex || shield.protectionPeriod)) {
    //         resetComponentState();
    //         clearSessionStorage();
    //         dispatch(fetchShieldDataIfNeeded(portfolioId, formattedDate, selectedIndex));

    //     }
    // }, [isEmpty, portfolioId, formattedDate, selectedIndex, dispatch]);

    useEffect(() => {
        if (!isEmpty) {
            if (selectedIndex === null) {
                resetComponentState();
                clearSessionStorage();
                const defaultIndex = bestIndexRef.current;
                dispatch(setSelectedIndex(defaultIndex));
                dispatch(fetchShieldDataIfNeeded(portfolioId, formattedDate, defaultIndex));
            } else {
                dispatch(fetchShieldDataIfNeeded(portfolioId, formattedDate, selectedIndex));
            }
        }
    }, [isEmpty, portfolioId, formattedDate, selectedIndex, dispatch]);

    useEffect(() => {
        dispatch(setSelectedIndex(null));
    }, [portfolioId, dispatch]);

    useEffect(() => {
        if (shieldData) {
            initializeNearestProtectionLevel();
            setSliderMinBasedOnStdDev();
        }
    }, [shieldData]);

    const setSliderMinBasedOnStdDev = () => {
        if (shieldData?.stdev_options?.["-3.0"]?.ProtLevel) {
            const protLevelAtMinus3 = shieldData.stdev_options["-3.0"].ProtLevel[0];
            if (protLevelAtMinus3) {
                setSliderMin(protLevelAtMinus3 * 100);
            }
        }
    };

    const resetComponentState = () => {
        setSelectedStockOptions([]);
        setSelectedIndexOptions({});
        setOtherHoldingsTickers([]);
        setTooltipContent({});
        setPrice('-');
        setSliderValue(95);
        setSliderValue(95);
        updateShield('assetAllocation', "-");
        updateShield('stdDeviation', "-");
        updateShield('protectionLevelPercent', 0);
        updateShield('downsideVolatility', 0);
        updateShield('RiskScore', "-");
    };

    const convertNDXSecDescToXND = (indexOptions) => {
        if (Array.isArray(indexOptions.SecDesc) && indexOptions.SecDesc.some(desc => desc.includes("NDX"))) {
            const modifiedOptions = { ...indexOptions };

            // Convert all occurrences of "NDX" to "XND" in SecDesc
            modifiedOptions.SecDesc = modifiedOptions.SecDesc.map(desc => {
                const updatedDesc = desc.replace("NDX", "XND");

                // Identify and adjust strike price at the end of SecDesc if it exists
                const strikeMatch = updatedDesc.match(/(\d+(\.\d{2})?)$/); // Match numeric ending like "19025.00"
                if (strikeMatch) {
                    const strikeValue = parseFloat(strikeMatch[0]) / 100; // Divide by 100
                    return updatedDesc.replace(/(\d+(\.\d{2})?)$/, Math.round(strikeValue)); // Replace with rounded strike
                }
                return updatedDesc;
            });

            // Adjust the quantity by 10 to reflect mini contracts
            if (Array.isArray(modifiedOptions.ConQty)) {
                modifiedOptions.ConQty = modifiedOptions.ConQty.map(qty => qty * 100);
            }

            // Convert Strike, LastAskPrice, LastBidPrice, and Cost by dividing by 100
            if (Array.isArray(modifiedOptions.Strike)) {
                modifiedOptions.Strike = modifiedOptions.Strike.map(strike => Math.round(strike / 100));
            }
            if (Array.isArray(modifiedOptions.LastAskPrice)) {
                modifiedOptions.LastAskPrice = modifiedOptions.LastAskPrice.map(price => price / 100);
            }
            if (Array.isArray(modifiedOptions.LastBidPrice)) {
                modifiedOptions.LastBidPrice = modifiedOptions.LastBidPrice.map(price => price / 100);
            }
            // if (modifiedOptions.Cost) {
            //     modifiedOptions.Cost /= 100;
            //     setPrice(modifiedOptions.Cost);
            // }

            console.log("Converted indexOptions:", modifiedOptions);
            return modifiedOptions;
        }
        return indexOptions;
    };

    const initializeNearestProtectionLevel = () => {
        const protectionLevelMap = mapProtectionLevelsToValues();
        const targetLevel = 0.95;
        const nearestLevel = findNearestProtectionLevel(protectionLevelMap, targetLevel);
        console.log(" nearest level", nearestLevel)

        if (nearestLevel) {
            if (nearestLevel.price !== '-') {
                setPrice(nearestLevel.price);
            }
            const modifiedOptions = convertNDXSecDescToXND(nearestLevel.IndexOptions);
            console.log("modofi", modifiedOptions)
            const extractedTicker = modifiedOptions?.SecDesc?.[0]?.match(/^[A-Z]+/)?.[0] || '';
            if (extractedTicker && extractedTicker !== outputIndexTicker) {
                setOutputIndexTicker(extractedTicker);
            }
            setSelectedIndexOptions(modifiedOptions || {});
            // setSelectedIndexOptions(nearestLevel.IndexOptions || {});
            setSelectedStockOptions(nearestLevel.StockOptions || []);
            setSliderValue(nearestLevel.protectionLevel * 100);
            updateShield("downsideVolatility", nearestLevel.downsideVolatility);
            updateShield("RiskScore", nearestLevel.RiskScore);
            setShieldState(nearestLevel);
            updateTooltip(nearestLevel);
            addToIndexPuts(modifiedOptions || {});
            const bestIndex = nearestLevel.bestIndex;
            if (bestIndexRef.current === null) { // Set only once on first load
                bestIndexRef.current = bestIndex;
            }
        }
    };
    const addToIndexPuts = (indexOptions) => {
        if (!indexOptions) return;

        const existingHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};
        existingHoldings['indexPuts'] = {};

        const { SecDesc, Strike, ConQty, ExpirationDate, LastAskPrice } = indexOptions;

        SecDesc.forEach((secDescStr, index) => {
            const strikeValue = Strike[index];
            const quantity = ConQty[index];
            const askPrice = LastAskPrice[index];

            if (secDescStr && strikeValue && quantity && ExpirationDate) {
                const stockTicker = secDescStr.replace(/(\d{4}-\d{2}-\d{2}P.*)/, '');
                const newIndexPut = {
                    stockTicker,
                    expiration_date: ExpirationDate,
                    type: 'P',
                    strike: strikeValue,
                    bid_price: askPrice,
                    description: secDescStr,
                    num_contracts: quantity,
                    totalValue: quantity * (askPrice || 0),
                    contractExpired: false,
                    status: "Pending Open"
                };

                if (!existingHoldings['indexPuts'][stockTicker]) {
                    existingHoldings['indexPuts'][stockTicker] = [];
                }
                const isDuplicate = existingHoldings['indexPuts'][stockTicker].some(
                    (contract) =>
                        contract.expiration_date === newIndexPut.expiration_date &&
                        contract.strike === newIndexPut.strike &&
                        contract.type === newIndexPut.type &&
                        contract.num_contracts === newIndexPut.num_contracts
                );

                if (!isDuplicate) {
                    existingHoldings['indexPuts'][stockTicker].push(newIndexPut);
                }
            }
        });

        sessionStorage.setItem('existingOptionHoldings', JSON.stringify(existingHoldings));
    };

    console.log("shield", selectedIndexOptions);
    const setShieldState = (data) => {
        setShield({
            protectionPeriod: shield.protectionPeriod,
            assetAllocation: data.assetAllocation,
            stdDeviation: data.stdDeviation,
            protectionLevelPercent: data.protectionLevel,
            downsideVolatility: data.downsideVolatility,
            RiskScore: data.RiskScore
        });

        // Set tooltip position
        const positionPercentage = ((data.protectionLevel * 100 - sliderMin) / (sliderMax - sliderMin)) * 100;
        setTooltipPosition(`${positionPercentage}%`);
    };

    const updateShield = (key, value) => {
        setShield(prevShield => ({
            ...prevShield,
            [key]: value
        }));
    };
    const formatCurrency = (value) => {
        return !isNaN(value) && value !== null && value !== undefined
            ? `${Number(addZeroes(value)).toLocaleString("en-US", { style: "currency", currency: "USD" })}`
            : '-';
    };
    const updateTooltip = (data) => {
        setTooltipContent({
            protectionLevel: (data.protectionLevel * 100).toFixed(1),
            assetAllocation: data.assetAllocation || 'N/A',
            stdDeviation: data.stdDeviation !== undefined ? data.stdDeviation.toFixed(2) : 'N/A',
            downsideVolatility: data.downsideVolatility !== undefined ? data.downsideVolatility.toFixed(2) : 'N/A',
            RiskScore: data.RiskScore || 'N/A',
        });
    };

    const findNearestProtectionLevel = (protectionLevels, targetLevel) => {
        return protectionLevels.reduce((prev, curr) =>
            Math.abs(curr.protectionLevel - targetLevel) < Math.abs(prev.protectionLevel - targetLevel) ? curr : prev,
            protectionLevels[0]
        );
    };


    // Find the nearest standard deviation that matches the given protection level
    const findNearestStdDeviation = (protectionLevel) => {
        if (!shieldData?.stdev_options) return "N/A";

        let nearestStdDev = null;
        let minDifference = Infinity;

        Object.keys(shieldData.stdev_options).forEach((stdDevKey) => {
            const stdDevData = shieldData.stdev_options[stdDevKey];
            const protLevels = stdDevData?.ProtLevel || [];

            // Find the closest matching protection level within the current std deviation option
            const closestProtLevel = protLevels.reduce((prev, curr) =>
                Math.abs(curr - protectionLevel) < Math.abs(prev - protectionLevel) ? curr : prev,
                protLevels[0]
            );

            const difference = Math.abs(closestProtLevel - protectionLevel);
            if (difference < minDifference) {
                minDifference = difference;
                nearestStdDev = parseFloat(stdDevKey);
            }
        });

        return nearestStdDev;
    };

    // Get asset allocation details for the given protection level
    const getAssetAllocation = (protectionLevel) => {
        const period = Object.keys(shieldData?.ChoosenOption || {})[0];
        const optionData = shieldData?.ChoosenOption[period]?.[protectionLevel];

        if (optionData && optionData.asset_allocation) {
            return optionData.asset_allocation;
        }
        return { stock: 0, bond: 0, volatility: 0, risk_score: 0 };
    };

    const renderMarketShieldTable = () => {
        if (!selectedIndexOptions || Object.keys(selectedIndexOptions).length === 0) {
            return <tr><td colSpan="6">No Data Available</td></tr>;
        }

        console.log("selectedIndexOptions", selectedIndexOptions);

        const { SecDesc, Strike, ConQty, ExpirationDate, LastAskPrice, LastBidPrice } = selectedIndexOptions;

        const calculateOptionCost = (askPrice, quantity, multiplier = 100) => (askPrice || 0) * multiplier * (quantity || 1);

        return SecDesc.map((desc, index) => (
            <tr key={`option-row-${index}`} style={{ fontSize: 20 }}>
                <td>{desc || '-'}</td>
                <td>{ConQty[index] || '-'}</td>
                <td>{Strike[index] ? formatCurrency(Strike[index]) : '-'}</td>
                <td>{ExpirationDate || '-'}</td>
                <td>
                    {LastAskPrice[index] ? formatCurrency(LastAskPrice[index]) : '-'}
                    /{LastBidPrice[index] ? formatCurrency(LastBidPrice[index]) : '-'}
                </td>
                <td>{formatCurrency(calculateOptionCost(LastAskPrice[index], ConQty[index]))}</td>
            </tr>
        ));
    };

    const mapProtectionLevelsToValues = () => {
        if (!shieldData) return [];

        const period = Object.keys(shieldData?.ChoosenOption || {})[0];
        const protectionLevels = Object.keys(shieldData?.ChoosenOption[period] || {})
            .map(level => parseFloat(level))
            .filter(level => level >= sliderMin / 100 && level <= sliderMax / 100);
        console.log("protec", protectionLevels)
        return protectionLevels.map(level => {
            const stdDeviation = findNearestStdDeviation(level);
            const { stock, bond, volatility, risk_score } = getAssetAllocation(level);
            const assetAllocation = `${(stock * 100).toFixed(0)}-${(bond * 100).toFixed(0)}`;
            const downsideVolatility = volatility;
            const price = shieldData?.ChoosenOption[period]?.[level]?.Cost || '-';
            const IndexOptions = shieldData?.ChoosenOption[period][level] || {};
            const StockOptions = shieldData?.ChoosenOption[period]?.[level]?.StockOptions || {};
            const RiskScore = risk_score
            const bestIndex = IndexOptions?.SecDesc?.[0]?.match(/^[A-Z]+/)?.[0] || '';
            sessionStorage.setItem('filteredTickers', JSON.stringify(shieldData?.OtherHolding || []));
            sessionStorage.setItem('stockOptions', JSON.stringify(shieldData?.ChoosenOption[period]?.[level]?.StockOptions || []));
            sessionStorage.setItem('MarketCost', price);

            return {
                protectionLevel: level,
                stdDeviation,
                assetAllocation,
                downsideVolatility,
                price,
                IndexOptions,
                StockOptions,
                RiskScore,
                bestIndex
            };
        });
    };

    const handleSliderChange = (event) => {
        const value = parseFloat(event.target.value) / 100; // Convert to API format
        setSliderValue(value * 100);

        const protectionLevelMap = mapProtectionLevelsToValues();
        if (protectionLevelMap.length > 0) {
            const nearestMatch = findNearestProtectionLevel(protectionLevelMap, value);
            console.log(" neare", nearestMatch)

            if (nearestMatch) {
                updateTooltip(nearestMatch);
                setPrice(nearestMatch.price);
                const modifiedOptions = convertNDXSecDescToXND(nearestMatch.IndexOptions);
                const extractedTicker = modifiedOptions?.SecDesc?.[0]?.match(/^[A-Z]+/)?.[0] || '';
                if (extractedTicker && extractedTicker !== outputIndexTicker) {
                    setOutputIndexTicker(extractedTicker);
                }
                setSelectedIndexOptions(modifiedOptions || {});
                // setSelectedIndexOptions(nearestMatch?.IndexOptions);

                setSelectedStockOptions(nearestMatch?.StockOptions);
                updateShield("RiskScore", nearestMatch.RiskScore);
                updateShield("downsideVolatility", nearestMatch.downsideVolatility);
                setShieldState(nearestMatch);
                addToIndexPuts(modifiedOptions || {});

            }
        }
    };

    const generateFiveTicks = () => {
        const start = sliderMin / 100;
        const end = sliderMax / 100;
        const step = (end - start) / 4;

        // Generate five evenly spaced tick values and round to 0.05 for display
        return Array.from({ length: 5 }, (_, i) => {
            const tickValue = (start + i * step).toFixed(2); // Round to 2 decimals
            return parseFloat(tickValue); // Convert to number for safety
        });
    };

    // Helper function to find the nearest level with valid data
    const findNearestValidLevel = (protectionLevels, targetProtectionLevel, key) => {
        const sortedLevels = protectionLevels
            .filter(pl => pl[key] !== undefined && !isNaN(pl[key])) // Filter out invalid entries
            .sort((a, b) => Math.abs(a.protectionLevel - targetProtectionLevel) - Math.abs(b.protectionLevel - targetProtectionLevel));

        return sortedLevels.length > 0 ? sortedLevels[0][key] : 'N/A'; // Return nearest valid value or 'N/A' if none found
    };

    // Formatting functions
    const formatPercentage = (value) => (value ? `${(value * 100).toFixed(1)}%` : 'N/A');
    const formatDecimal = (value) => (value !== undefined && !isNaN(value) ? value.toFixed(2) : 'N/A');

    const renderSliderTicksForIntervals = () => {
        const intervals = generateFiveTicks();
        const protectionLevels = mapProtectionLevelsToValues();

        return (
            <div className="slider-ticks-container">
                {/* Protection Level Ticks */}
                <div className="protection-level-ticks">
                    {intervals.map((interval, index) => {
                        const tick = protectionLevels.find(pl =>
                            pl.protectionLevel.toFixed(2) === interval.toFixed(2)) || {};

                        const leftPosition = ((interval * 100 - sliderMin) / (sliderMax - sliderMin)) * 100;
                        const protectionLevel = tick.protectionLevel !== undefined
                            ? formatPercentage(tick.protectionLevel)
                            : formatPercentage(findNearestValidLevel(protectionLevels, interval, 'protectionLevel'));

                        return (
                            <div
                                key={`protection-${index}`}
                                className="tick protection-tick"
                                style={{ left: `${leftPosition}%` }}
                            >
                                <div className="tick-label">{protectionLevel}</div>
                            </div>
                        );
                    })}
                </div>

                {/* Standard Deviation Ticks */}
                <div className="std-dev-ticks">
                    {intervals.map((interval, index) => {
                        const tick = protectionLevels.find(pl =>
                            pl.protectionLevel.toFixed(2) === interval.toFixed(2)) || {};

                        const leftPosition = ((interval * 100 - sliderMin) / (sliderMax - sliderMin)) * 100;
                        const stdDeviation = tick.stdDeviation !== undefined
                            ? formatDecimal(tick.stdDeviation)
                            : formatDecimal(findNearestValidLevel(protectionLevels, interval, 'stdDeviation'));

                        return (
                            <div
                                key={`stddev-${index}`}
                                className="tick std-tick"
                                style={{ left: `${leftPosition}%` }}
                            >
                                <div className="tick-label">{stdDeviation}</div>
                            </div>
                        );
                    })}
                </div>

                {/* Asset Allocation or Risk Score Ticks */}
                <div className="asset-allocation-ticks">
                    {intervals.map((interval, index) => {
                        const tick = protectionLevels.find(pl =>
                            pl.protectionLevel.toFixed(2) === interval.toFixed(2)) || {};

                        const leftPosition = ((interval * 100 - sliderMin) / (sliderMax - sliderMin)) * 100;
                        const label = showAssetAllocation
                            ? tick.assetAllocation || findNearestValidLevel(protectionLevels, interval, 'assetAllocation')
                            : tick.RiskScore !== undefined
                                ? formatDecimal(tick.RiskScore)
                                : formatDecimal(findNearestValidLevel(protectionLevels, interval, 'RiskScore'));

                        return (
                            <div
                                key={`tick-${index}`}
                                className="tick"
                                style={{ left: `${leftPosition}%` }}
                                title={showAssetAllocation ? `Asset Allocation: ${tick.assetAllocation}` : `Risk Score: ${tick.RiskScore}`}
                            >
                                <div className="tick-label">{label}</div>
                            </div>
                        );
                    })}
                </div>

                {/* Downside Volatility Ticks */}
                <div className="slider-tick-row">
                    {intervals.map((interval, index) => {
                        const tick = protectionLevels.find(pl =>
                            pl.protectionLevel.toFixed(2) === interval.toFixed(2)) || {};

                        const leftPosition = ((interval * 100 - sliderMin) / (sliderMax - sliderMin)) * 100;
                        const downsideVolatility = tick.downsideVolatility !== undefined
                            ? formatDecimal(tick.downsideVolatility)
                            : formatDecimal(findNearestValidLevel(protectionLevels, interval, 'downsideVolatility'));

                        return (
                            <div
                                key={`downvol-tick-${index}`}
                                className="tick downvol-tick"
                                style={{ left: `${leftPosition}%` }}
                            >
                                <div className="tick-label">{downsideVolatility}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const handleIndexChange = (event) => {
        const newIndex = event.target.value;
        console.log("newIndex", newIndex)
        dispatch(setSelectedIndex(newIndex));
        // setSelectedIndex(newIndex);
        dispatch(fetchShieldDataIfNeeded(portfolioId, formattedDate, newIndex));
    };

    console.log("shield", shield)
    const netCostPremium = (totalPremium - (price || 0) - (totalCost || 0)).toFixed(2);
    console.log("net cost", netCostPremium)
    const portfolioValue = portfolioDetails?.TotalPortfolioValue || 1;
    const costOfIndexPutPercent = ((price / portfolioValue) * 100).toFixed(2);
    const totalPremiumPercent = ((totalPremium / portfolioValue) * 100).toFixed(2);
    const totalCostPercent = ((totalCost / portfolioValue) * 100).toFixed(2);
    const netCostPremiumPercent = (((totalPremium - price - totalCost) / portfolioValue) * 100).toFixed(2);

    return (
        <>
            {(!shoppingFlag) ? (
                <>
                    <div className="toast-container">
                        <Toaster />
                    </div>
                    <div className="drawercontent">
                        {loadingShieldData ? (
                            <div className="loading-overlay">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div className="infocard-wrap">
                                    <div id="selectsimulationlength" className="infocard fix">
                                        <h3>Index</h3>
                                        <Select
                                            className="ai-form-value-bt"
                                            value={selectedIndex || bestIndexRef.current}
                                            onChange={handleIndexChange}
                                        >
                                            <MenuItem value="NDX">
                                                Nasdaq 100 {bestIndexRef.current === "NDX" && "(Best)"}
                                            </MenuItem>
                                            <MenuItem value="SPX">
                                                S&P 500 {bestIndexRef.current === "SPX" && "(Best)"}
                                            </MenuItem>
                                            <MenuItem value="RUT">
                                                Russell 2000 {bestIndexRef.current === "RUT" && "(Best)"}
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <div className="infocard fix">
                                        <h3>Expiration</h3>
                                        <DatePicker
                                            selected={shield.protectionPeriod}
                                            onChange={(date) => updateShield("protectionPeriod", date)}
                                            dateFormat="yyyy-MM-dd"
                                            minDate={moment().toDate()}
                                            filterDate={isFriday}
                                            customInput={<CustomDateInput />}
                                        />
                                        {/* <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={shield.protectionPeriod}
                                            onChange={(date) => updateShield("protectionPeriod", date)}
                                            customInput={<CustomDateInput />}
                                        /> */}
                                    </div>
                                    <div className='slidegroup'>
                                        <div className="protection-label">
                                            SET PROTECTION LEVEL
                                            <span className="arrow-right"><img src="Assets/right-arrow.svg" alt="toggle" /> </span>
                                        </div>
                                        <div className="custom-toggle-container">
                                            <input
                                                type="checkbox"
                                                id="custom-asset-mode-toggle"
                                                checked={!showAssetAllocation}
                                                onChange={() => setShowAssetAllocation(!showAssetAllocation)}
                                            />
                                            <label htmlFor="custom-asset-mode-toggle" className="custom-toggle-label"></label>
                                            <span className="custom-toggle-text">
                                                {/* {showAssetAllocation ? 'Asset Allocation' : 'Risk Score'} */}
                                            </span>
                                        </div>


                                        <div className="label-container">
                                            <div className="label label-asset">{showAssetAllocation ? 'Asset Alloc Equiv' : 'Risk Score'}</div>
                                            <div className="label label-asset">Downside Vol</div>
                                            <div className="label label-floor">Protection Floor (%)</div>
                                            <div className="label label-std">Std (out-of-money)</div>
                                        </div>
                                        <div className='slidecontrol'>

                                            <div className="custom-slider-container">
                                                <input
                                                    type="range"
                                                    min={sliderMin}
                                                    max={sliderMax}
                                                    step="0.01"
                                                    value={sliderValue}
                                                    onChange={handleSliderChange}
                                                    className="custom-slider"
                                                />
                                                <div
                                                    className="movable-tooltip"
                                                    style={{ left: tooltipPosition }}
                                                >
                                                    <div className="tooltip-content">

                                                        <div>{showAssetAllocation ? tooltipContent.assetAllocation : tooltipContent.RiskScore}</div>
                                                        <div>{tooltipContent.downsideVolatility}</div>
                                                        <div>{tooltipContent.protectionLevel}%</div>
                                                        <div>{tooltipContent.stdDeviation}</div>

                                                    </div>
                                                </div>

                                                {renderSliderTicksForIntervals()}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='summaryarea'>
                                    <div className='sumstates'>
                                        <div className="sumvalue">
                                            <label>Beta / R-sq</label>
                                            <div>{shieldData?.Beta || '-'} / {shieldData?.RSq ? parseInt(shieldData.RSq, 10) : '-'}%</div>
                                        </div>
                                        <div className="sumvalue">
                                            <label>{showAssetAllocation ? 'Asset Allocation Equiv (Stock-Bond)' : 'Risk Score'}</label>
                                            <div>{showAssetAllocation ? shield.assetAllocation : shield.RiskScore}</div>
                                            {/* <div>{shield.assetAllocation}/ {shield.RiskScore}</div> */}
                                        </div>
                                        <div className="sumvalue">
                                            <label>Protection Level</label>
                                            <div>{(shield.protectionLevelPercent * 100).toFixed(0)}%</div>
                                        </div>
                                        <div className="sumvalue">
                                            <label>Downside Vol</label>
                                            <div>{parseFloat(shield?.downsideVolatility).toFixed(2) || '-'}</div>
                                        </div>
                                    </div>
                                    <div className='pricearea'>
                                        <div>
                                            <span>Cost of Index Put</span>
                                            <span>
                                                {price !== '-' ? `- ${formatCurrency(price)} (${costOfIndexPutPercent}%)` : 'No Price Available'}
                                            </span>
                                        </div>
                                        <div>
                                            <span>Premium of Selected Covered Calls</span>
                                            <span>
                                                {`${formatCurrency(totalPremium)} (${totalPremiumPercent}%)`}
                                            </span>
                                        </div>
                                        <div>
                                            <span>Cost of Individual Puts</span>
                                            <span>
                                                {totalCost > 0 ? `- ${formatCurrency(totalCost)} (${totalCostPercent}%)` : '-'}
                                            </span>
                                        </div>
                                        <div className='totalprice'>
                                            <span>Net Cost/Premium</span>
                                            <span>
                                                {netCostPremium < 0
                                                    ? `- ${formatCurrency(Math.abs(netCostPremium))} (${Math.abs(netCostPremiumPercent)}%)`
                                                    : `${formatCurrency(netCostPremium)} (${netCostPremiumPercent}%)`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='assettable'>
                                    <h2>Market Shield</h2>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ticker</th>
                                                <th>Quantity</th>
                                                <th>Strike Price</th>
                                                <th>Expiration Date</th>
                                                <th>Ask Price / Bid Price</th>
                                                <th>Market Shield Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderMarketShieldTable()}
                                            {/* <>
                                                {!isLoadingOptions && (
                                                    <Dashboard
                                                        portfolioId={portfolioId}
                                                        isEmpty={isEmpty}
                                                        portfolioDetails={portfolioDetails}
                                                        tabType="PutBuying"
                                                        filteredTickers={otherHoldingsTickers}
                                                        stockOptions={selectedStockOptions}
                                                        indexOptions={selectedIndexOptions}
                                                        // recommendationMode={true}
                                                        indexput={true}
                                                        visibleCartItems={{}}
                                                        setVisibleCartItems={() => { }}
                                                        protectionLevel={(shield.protectionLevelPercent * 100).toFixed(0)}
                                                    />
                                                )}
                                            </> */}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='assettable'>
                                    <h2>ADDITIONAL HEDGES FOR CONCENTRATED POSITIONS</h2>
                                </div>
                                {!isLoadingOptions && (
                                    <Dashboard
                                        portfolioId={portfolioId}
                                        isEmpty={isEmpty}
                                        portfolioDetails={portfolioDetails}
                                        tabType="PutBuying"
                                        filteredTickers={otherHoldingsTickers}
                                        stockOptions={selectedStockOptions}
                                        recommendationMode={true}
                                        visibleCartItems={{}}
                                        setVisibleCartItems={() => { }}
                                        protectionLevel={(shield.protectionLevelPercent * 100).toFixed(0)}
                                    />
                                )}

                                <div className="cart-btn">
                                    <a href="javascript:;" className='ai-btn primary line' onClick={(e) => {
                                        e.preventDefault();
                                        setShoppingFlag(true);
                                    }}>
                                        Preview Trades
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </>) : (
                <div className="shopping-cart">
                    <button className="ai-btn line secondary"
                        onClick={() => setShoppingFlag(false)}> Back </button>
                    <h3> Trade Preview</h3>
                    <div className="shopping-card-container">
                        {shoppingFlag && <ShoppingCart
                            existing_option_holdings={existingOptionHoldings}
                            portfolioId={portfolioId}
                            visibleCartItems={props.visibleCartItems} setVisibleCartItems={props.setVisibleCartItems} />}
                    </div>
                </div>
            )}



        </>
    );
}

export default ShieldRecommendationv2;
