import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function DeleteClient(customer_id) {
    const Token = sessionStorage.getItem("Token");
    const requestOptions = {
        method: "DELETE",
        body: JSON.stringify({ session_id: Token, customer_id }),
        headers: { Authorization: Token },
        redirect: "follow",
    };

    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_GET_ADVISOR_CLIENT_DETAILS,
                requestOptions
            );
            const message = await response.json();
            if (!response.ok) {
                console.log("Could not delete this client");
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            console.log("Error:", error.message);
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
