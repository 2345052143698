import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function AddCsvData(params = {}) {
    const Token = sessionStorage.getItem("Token");
    const payload = { session_id: Token, ...params };
    console.log("payload", payload)

    var requestOptions = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { Authorization: Token },
        redirect: "follow",
    };

    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetchWithTimeout(process.env.REACT_APP_INGEST_CSV_API, requestOptions);
            const message = await response.json();
            if (!response.ok) {
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
