import React, {useState} from 'react';
import './AddClientDrawer.css';
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import ClientPortfolioLineItemsManualImport from "../AddClientPortfolioLineItems/AddClientPortfolioLineItems";


function AddClientDrawer() {
    const dispatch = useDispatch();
    const [addDataMode, setAddDataMode] = useState("manual");
    const handleAddDataSelect = (addDataMode) => {
        setAddDataMode(addDataMode);
    };

    return (
        <div className="drawer">
            <div className="drawer-header">
                <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                <h1>Add Clients</h1>
                <div className="actions">
                <button className="close-drawer" onClick={() => dispatch(closeDrawer())}>✕</button>
                </div>
            </div>
            <div className="drawer-body">
                {/* <div className="main">
                    <div className="card_title" onClick={() => handleAddDataSelect("csv")}>
                        <img className="product_card_img" src='./Assets/CSV.png'></img>
                        <p className="title_text">Import from CSV</p>
                    </div>
                    <div className="card_title" onClick={() => handleAddDataSelect("manual")}>
                        <img className="product_card_img" src='./Assets/Typing.png'></img>
                        <p className="title_text">Enter Manually</p>
                    </div>
                </div> */}
                <div>
                    {/* {addDataMode == "csv" && <AdaptiveCSVImportWizardComponent />} */}
                    {addDataMode == "manual" && <ClientPortfolioLineItemsManualImport />}
                </div>
            </div>
        </div>
    );
};

export default AddClientDrawer;
