import React, { useState, useEffect, useRef } from 'react';
import { useHistory , useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './Navbar.scss';
import UserPool from "../../UserPool";
import { RESET_STATE } from '../../actions/actionTypes';
import { FaUser,FaChartBar } from 'react-icons/fa';
import { fetchProfile } from '../../actions/advisorProfileActions';
import Avatar from '@mui/material/Avatar';

function Navbar() {
    const dispatch = useDispatch();
    const history = useHistory();
    const modalRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const advisorProfile = useSelector((state) => state.advisorProfile.profileData)
    const profileImage = useSelector(state => state.userPicture.userPictureData?.profile_picture) || null;
    const selectedAcType = sessionStorage.getItem("selectedAcType");
    const menuItems = [
        // { name: 'Dashboard', path: '/dashboard' },
        // {
        //     name: sessionStorage.getItem("selectedAcType") === 'advisor' ? 'Advisor Profile' : 'Investor Profile',
        //     path: '/AdvisorProfileScreen'
        // },
        { name: selectedAcType === 'Advisor' ? 'Advisor Profile' : 'Investor Profile', path: '/AdvisorProfileScreen' },
        { name: 'Client Portfolios', path: '/ClientPortfolios' },
    ];
    let { path } = useRouteMatch();
    const [activeMenuItem, setActiveMenuItem] = useState(path);
    const handleLogOut = () => history.push("/");

    const nameInitial = Object.keys(advisorProfile).length > 0 ? advisorProfile.firstname.charAt(0).toUpperCase() : '';
    const familyNameInitial = Object.keys(advisorProfile).length > 0 ? advisorProfile.lastname.charAt(0).toUpperCase() : '';
    const userInitials = nameInitial + familyNameInitial;

    const navigate = (path) => {
        history.push(path);

    };
    console.log(activeMenuItem)
    useEffect(() => {
        if (Object.keys(advisorProfile).length === 0) {

            dispatch(fetchProfile());
        }
    }, [dispatch, advisorProfile]);


    const UserModal = () => {
        return (
            <div className="user-modal" ref={modalRef}>
                <div className="modal-item" onClick={()=>navigate("/AdvisorProfileScreen")}>
                    {/* <FaUser className="modal-icon"/> */}
                    <img src="Assets/profile.svg" alt="Profile" className="modal-icon" />
                    Profile
                </div>
                <div className="modal-item" onClick={()=>navigate("/ClientPortfolios")}>
                    {/* <FaChartBar className="modal-icon"/> */}
                    <img src="Assets/portfoio.svg" alt="Profile" className="modal-icon" />
                    Portfolios
                </div>
                <div className="modal-item" onClick={() => logout()}>
                    {/* <img src="Assets/log_out.svg" alt="Logout" className="modal-icon" /> */}
                    <img src="Assets/signout.svg" alt="Profile" className="modal-icon" />
                    Logout
                </div>

            </div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function logout() {
        console.log(" logout")
        const user = UserPool.getCurrentUser();
        if (user) {
            console.log("in logout", user)
            user.signOut();
            window.sessionStorage.clear();
            window.localStorage.clear();
            dispatch({ type: RESET_STATE });
            handleLogOut();

        }
    }

    return (
        <div className="navbar">
            {/* <a className='toggle'>
                <span></span>
                <span></span>
                <span></span>
            </a> */}
            <img className="logo" alt='Logo' src="Assets/adaptive_logo.png"></img>
            {/* <ul className="navbar-center">
                {menuItems.filter((item)=>item.path === activeMenuItem).map(item => (
                    <li
                        key={item.path}
                        className={`menu-item ${activeMenuItem === item.path ? 'active' : ''}`}
                        
                    >
                        {item.name}
                    </li>
                ))}
            </ul> */}
            <div className="navbar-right">
                {/* <div className="search-bar">
                    <input type="text" placeholder="Type portfolio name" />
                    <SearchIcon className="search-icon" />
                </div> */}
                <a className="user-info" onClick={() => setShowModal(!showModal)}>
                    <span className="user-avatar">
                        {profileImage ? (
                            <Avatar
                                alt="User Avatar"
                                src={profileImage}
                                sx={{ width: 40, height: 40 }}
                            />
                        ) : (
                            userInitials
                        )}
                    </span>
                    {showModal && <UserModal />}
                    <div className='user-detail'>
                        <span className="user-name">{(Object.keys(advisorProfile).length > 0 ? advisorProfile.firstname : '') + " " + (Object.keys(advisorProfile).length > 0 ? advisorProfile.lastname : '')}</span>
                        <span className="user-role">{selectedAcType}</span>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Navbar;
