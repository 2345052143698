import { FaCalendarAlt } from 'react-icons/fa';

export const CustomDateInput = ({ value, onClick }) => (
    <div className="custom-date-input" onClick={onClick}>
        {/* <FaCalendarAlt
        style={{
            position: 'absolute',
            right: '50px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
            color: '#555'
        }}
        /> */}
        <input
            type="text"
            value={value}
            readOnly
            placeholder="Select a date"
            style={{
                border: '1px solid #ccc',
                padding: '8px 5px',
                width: '80%',
                borderRadius: '4px',
                // paddingRight: '30px' // Make space for the icon
            }}
        />

    </div>
);

