import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function PostUserPicture(payload) {
    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    var raw = JSON.stringify({
        session_id: Token,
        encoded_payload: [
            {
                type: 'profile_picture',
                payload: payload.profile_picture,
            },
            {
                type: 'firm_logo',
                payload: payload.company_logo,
            },
        ],
    });

    var requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: header,
    };

    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_USER_PICTURE_API,
                requestOptions
            );
            const message = await response.json();
            if (!response.ok) {
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
