import { 
    FETCH_AVAILABLE_TICKERS_START,
    FETCH_AVAILABLE_TICKERS_SUCCESS,
    FETCH_AVAILABLE_TICKERS_FAILURE 
} from "../actions/availableTickersAction";

const initialState = {
    availableTickers: {}
};

export const availableTickersReducer = (state = initialState, action) => {
    switch (action.type){
        case FETCH_AVAILABLE_TICKERS_START:
            return { ...state, isLoading: true, error: null };
        case FETCH_AVAILABLE_TICKERS_SUCCESS:
            return { ...state, isLoading: false, availableTickers: action.payload.details.result, error: null };
        case FETCH_AVAILABLE_TICKERS_FAILURE:
            return { ...state, isLoading: false, error: action.payload.details.error};
        default:
            return state;
    }

}

export default availableTickersReducer