const FetchOptionChainPrices = (OptionData) => {
  
    console.log("Calling the API OptionData", OptionData)
      return fetch("https://iqr45ow81a.execute-api.us-east-1.amazonaws.com/dev/get-prices-for-chain", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ OptionData })
      })

      
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log("Price API call successful")
        return response.json();
      })
      .catch(error => {
        console.log("API call failed")
        console.error("Error fetching data:", error);
        throw error;
      });
    }
    
    export default FetchOptionChainPrices;
    