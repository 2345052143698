// reducers/drawerReducer.js

import { OPEN_DRAWER, CLOSE_DRAWER } from '../actions/drawerActions';

const initialState = {
    activeDrawer: null,
    data: {}
};

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_DRAWER:
            return {
                ...state,
                activeDrawer: action.payload.drawerName,
                data: action.payload.data
            };
        case CLOSE_DRAWER:
            return {
                ...state,
                activeDrawer: null,
                data: {}
            };
        default:
            return state;
    }
};

export default drawerReducer;
