
import { GetAdvisorProfile } from "../Apis/GetAdvisorProfile";
import { UpdateAdvisorProfile } from "../Apis/UpdateAdvisorProfile";
import { handleApiError } from './notificationActions';

export const FETCH_PROFILE_START = "FETCH_PROFILE_START";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";


export const fetchProfile = () => async dispatch => {
    dispatch({ type: FETCH_PROFILE_START });

    try {
        const data = await GetAdvisorProfile();
        if(data.status === 200){

            dispatch({ type: FETCH_PROFILE_SUCCESS, payload: data.result[0] });
        }
        else{
            handleApiError(data, dispatch);
        }
    } catch (error) {
        dispatch({ type: FETCH_PROFILE_FAILURE, payload: error.message });
    }
};

export const updateProfile = (payload) => async (dispatch) => {

    try {
        const response = await UpdateAdvisorProfile(payload);
        if (response.status === 200) {
            // dispatch(fetchProfile());
            dispatch({ type: UPDATE_PROFILE_SUCCESS });
            return null
        } else {
            handleApiError(response, dispatch);
            dispatch({ type: UPDATE_PROFILE_FAILURE, payload: response.message });
            return response.status
        }
    } catch (error) {
        handleApiError(error, dispatch);
        dispatch({ type: UPDATE_PROFILE_FAILURE, payload: error.message });
        return error.message
    }
};