import React, { useState, useEffect, useMemo } from "react";
import "./FactorAnalysis.scss";
import Loader from '../../Components/Loader/Loader';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TagManager from "react-gtm-module";
import { addZeroes } from "../../Utilities/AddZeros";
import { FetchFactorAnalytics } from "../../Apis/FetchFactorAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { handleApiError } from "../../actions/notificationActions";
import { toPng } from "html-to-image";
import { updatePdfData } from "../../actions/pdfDataActions";
import TooltipComponent from "../TooltipComponent/TooltipComponent";
import { MaterialReactTable } from "material-react-table";
import CaptureButtons from "../CaptureButtons/CaptureButtons";
import { customColumnSort } from "../../Utilities/CustomColumnSort";

function FactorAnalysis({ portfolioId, isEmpty }) {
  var currObj = {
    style: "currency",
    currency: "USD",
  };
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(true);
  const [bdopen, setBdOpen] = React.useState(true);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [FATable, setFATable] = React.useState([]);
  const [FactorTable, setFactorTable] = React.useState([]);
  const [HedgeableData, sethedgeabledata] = React.useState([]);
  const [UnhedgeableData, setunhedgeabledata] = React.useState([]);
  const [tabletabvalue, setTableTabValue] = React.useState("Hedgeable Assets");
  const [factortabvalue, setFactorTabValue] = React.useState("Factor Analysis");
  const [selectedPortfolioName, setSelectedPortfolioName] = React.useState("");
  const [R2Benchmark, setR2Benchmark] = React.useState(0);
  const [R2Factor, setR2Factor] = React.useState(0);
  const [BetaBenchmark, setBetaBenchmark] = React.useState(0);
  const [BetaFactor, setBetaFactor] = React.useState([]);
  const [TEBenchmark, setTEBenchmark] = React.useState(0);
  const [TEFactor, setTEFactor] = React.useState(0);
  const [R2BenchmarkNum, setR2BenchmarkNum] = React.useState(25);
  const [R2FactorNum, setR2FactorNum] = React.useState(25);
  const [PortfolioEmpty, setPortfolioEmpty] = React.useState(false);
  const factorAnalysisApiData = useSelector(
    (state) => state.pdfData["Factor Analysis"]
  );

  const HedgeData = useMemo(
    () =>
      HedgeableData.map((value) => ({
        sr: value.number + 1,
        symbol: value.Ticker,
        company: value.Name,
        quantity: Number(addZeroes(Number(parseFloat(value.Quantity).toFixed(2)))).toLocaleString("en-US"),
        closingprice: Number(addZeroes(Number(parseFloat(value.ClosePrice).toFixed(2)))).toLocaleString("en-US", currObj),
        weight: (value.Weight * 100).toFixed(2) + "%",
        MktVal : Number(addZeroes(Number(parseFloat(value.MktVal).toFixed(2)))).toLocaleString("en-US", currObj)
      })),
    [HedgeableData]
  );

  const UnhedgeData = useMemo(
    () =>
      UnhedgeableData.map((value) => ({
        sr: value.number + 1,
        symbol: value.Ticker,
        company: value.Name,
        quantity: value.Quantity.toFixed(2)
      })),
    [UnhedgeableData]
  );

  const totalSums = HedgeableData.reduce(
    (acc, curr) => {
      acc.MktVal += curr.MktVal;
      acc.weight += (curr.Weight * 100);
      return acc;
    },
    {
      MktVal: 0,
      weight: 0,
    }
  );

  const HedgeableColumns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      { accessorKey: "symbol", header: "Symbol", footer: "Portfolio Total:", size: "130"},
      { accessorKey: "company", header: "Position Name", footer: "" },
      {  
        accessorKey: "quantity", 
        header: "Quantity", 
        footer: "" ,
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'quantity'),
      },
      {
        accessorKey: "closingprice",
        header: "Closing Price",
        footer: "",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'closingprice'),
      },
      {
        accessorKey: "MktVal",
        header: "Market Value",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'MktVal'),
        footer: Number(addZeroes(Number(parseFloat(totalSums.MktVal).toFixed(2)))).toLocaleString("en-US", currObj),
        size: "170"
      },
      {
        accessorKey: "weight",
        header: "Weight",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'weight'),
        footer: "100%",
      },
    ],
    [totalSums]
  );
  const UnhedgeableColumns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      { accessorKey: "symbol", header: "Symbol", footer: "Portfolio Total:", size: "130"},
      { accessorKey: "company", header: "Position Name", footer: "" },
      { 
        accessorKey: "quantity", 
        header: "Quantity", 
        footer: "" ,
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'quantity'),
      },
    ],
    [totalSums]
  );

  const captureAndDispatchData = async (response) => {
    if (response.status === 200) {
      const data = response.result;
      dispatch(
        updatePdfData("Factor Analysis", {
          dataFlag: "Data Available",
          allData: response,
          portfolioid: portfolioId,
        })
      );
    } else {
      dispatch(
        updatePdfData("Factor Analysis", {
          dataFlag: "Data Not Available",
          portfolioid: portfolioId,
        })
      );
    }
  };

  const setApiData = (response) => {
    if (response.status === 200) {
      if (response.result["message"] === "No tickers were identified") {
        setPortfolioEmpty(true);
        setBdOpen(false);
        return;
      }
      const data = response.result;
      setFATable(data["factor_analysis"]);
      setFactorTable(data["factor"]);
      sethedgeabledata(data["recognised"]);
      setunhedgeabledata(data["unrecognised"]);
      setR2Benchmark(
        data["r2_benchmark"] === "N.A."
          ? data["r2_benchmark"]
          : (data["r2_benchmark"] * 100).toFixed(2) + "%"
      );
      setR2BenchmarkNum(data["r2_benchmark"] * 100);
      setR2Factor(
        data["r2_factor"] === "N.A."
          ? data["r2_factor"]
          : (data["r2_factor"] * 100).toFixed(2) + "%"
      );
      if (data["r2_factor"] !== "N.A.") {
        console.log("data", data["r2_factor"]);
        setR2FactorNum(data["r2_factor"] * 100);
      }
      setBetaBenchmark(parseFloat(data["beta_benchmark"]).toFixed(2));
      formatBetaFactor(data["beta_factor"]);
      setTEBenchmark(
        data["te_benchmark"] === "N.A."
          ? data["te_benchmark"]
          : (data["te_benchmark"] * 100).toFixed(2) + "%"
      );
      setTEFactor(
        data["te_factor"] === "N.A."
          ? data["te_factor"]
          : (data["te_factor"] * 100).toFixed(2) + "%"
      );
      TagManager.dataLayer({
        dataLayer: {
          event: "Factor Analytics",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
      setTimeout(() => {
        captureAndDispatchData(response);
      }, 1000);
      setBdOpen(false);
    } else {
      handleApiError(response, dispatch);
      setTimeout(() => {
        captureAndDispatchData(response);
      }, 1000);
      setBdOpen(false);
      return;
    }
  };

  const isApiDataPresent = () => {
    return (
      factorAnalysisApiData &&
      factorAnalysisApiData.portfolioid === portfolioId &&
      factorAnalysisApiData.dataFlag === "Data Available"
    );
  };

  useEffect(() => {
    if (!isEmpty && portfolioId && (!isApiDataPresent())) {
      setBdOpen(true);
      FetchFactorAnalytics(portfolioId).then((response) => {
        setApiData(response);
      });
    } else if (isApiDataPresent()) {
      setApiData(factorAnalysisApiData.allData);
    }
  }, [portfolioId]);

  // useEffect(() => {
  //   if (!isEmpty && portfolioId !== undefined && portfolioId !== '') {
  //     setBdOpen(true);
  //     if(factorAnalysisApiData && portfolioId === factorAnalysisApiData.portfolioid){
  //       setApiData(factorAnalysisApiData.allData);
  //     }else{
  //       FetchFactorAnalytics(portfolioId).then((response) => {
  //         setApiData(response);
  //       });
  //     }
  //   }
  // }, []);

  function handleTableTabChange(tabletabvalue) {
    setTableTabValue(tabletabvalue);
  }

  function handleFactorTableTabChange(tabletabvalue) {
    setFactorTabValue(tabletabvalue);
  }

  const headers = ["", "Portfolio", "Benchmark", "Factor"];
  const factor_headers = ["#", "Factor", "Weight", "Category"];

  const formatBetaFactor = (value) => {
    const formattedValue = value
      .split(",")
      .map((num) => parseFloat(num).toFixed(2))
      .join(", ");
    setBetaFactor(formattedValue);
  };

  const updatedData = Object.entries(FATable).map(([key, value]) => {
    const updatedValue = Object.entries(value).reduce((acc, [k, v]) => {
      const newKey =
        k === "imp_vol"
          ? "Implied Volatility"
          : k === "hist_vol"
          ? "Historical Volatility"
          : k === "symbol"
          ? "Symbol"
          : k;
      if (
        newKey === "Implied Volatility" ||
        newKey === "Historical Volatility"
      ) {
        v = v === "N.A." ? "N.A." : (parseFloat(v) * 100).toFixed(2) + "%";
      }
      return { ...acc, [newKey]: v };
    }, {});
    const newValue = {
      Symbol: value.Symbol || selectedPortfolioName,
      ...updatedValue,
    };
    return { [key]: newValue };
  });

  const uniqueKeys = Array.from(
    new Set(updatedData.flatMap((obj) => Object.keys(obj[Object.keys(obj)[0]])))
  );

  const TwoCircles = ({ apart = 22 }) => {
    const svgWidth = 200;
    const circleRadius = 40;

    const circle1X = (svgWidth - apart) / 2;
    const circle2X = (svgWidth - apart) / 2 + apart;

    return (
      <svg width={svgWidth} height="100" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx={circle1X}
          cy="45"
          r={circleRadius}
          fill="#60034C"
          stroke="#666666"
          strokeWidth="5"
        />
        <circle
          cx={circle2X}
          cy="45"
          r={circleRadius}
          fill="#60034C"
          stroke="#666666"
          strokeWidth="5"
        />
        <path
          d={`M${circle1X},45
              m-${circleRadius},0
              a${circleRadius},${circleRadius} 0 1,0 ${circleRadius * 2},0
              a${circleRadius},${circleRadius} 0 1,0 -${circleRadius * 2},0
              M${circle2X},45
              m-${circleRadius},0
              a${circleRadius},${circleRadius} 0 1,1 ${circleRadius * 2},0
              a${circleRadius},${circleRadius} 0 1,1 -${circleRadius * 2},0`}
          fill="#ffffff"
          fill-rule="evenodd"
        />
      </svg>
    );
  };

  return (
    <>
      {!isEmpty ? (
        <>
          {bdopen ? (
            <div className="loading-overlay">
              <Loader/>
            </div>
          ) : (
            <div className="drawercontent">
              <div className="risk-cont portfolio_page-holder factoranalysis">
                <div className="factorexposure">
                  <div className="portfolio-table contributio-table factortbl1 sum-container">
                    <CaptureButtons elementType='table' chartName={factortabvalue === "Factor Analysis"?"Factor_Analysis_Table_1":"Factor_Analysis_Table_2"} activeTab="Factor Analysis" 
                      tableHeaders={factortabvalue === "Factor Analysis"?headers:factor_headers} tableData={factortabvalue === "Factor Analysis"?FATable:FactorTable}>
                    </CaptureButtons>
                    <div className="table_holder table_head">
                      <div className="tabheader">
                        <ul className="subnavbar-menu fixed">
                          <li>
                            <a
                              id="factoranalysistab"
                              className={
                                factortabvalue === "Factor Analysis"
                                  ? "SUBITEM-selected "
                                  : "SUBITEM"
                              }
                              onClick={() =>
                                handleFactorTableTabChange("Factor Analysis")
                              }
                            >
                              Factor Analysis{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              id="factorvaluetab"
                              className={
                                factortabvalue === "Factor"
                                  ? "SUBITEM-selected"
                                  : "SUBITEM"
                              }
                              onClick={() =>
                                handleFactorTableTabChange("Factor")
                              }
                            >
                              Factor
                            </a>
                          </li>
                        </ul>{" "}
                        
                        {show && (
                          <span
                            onClick={() => setShow(!show)}
                            className="minus"
                          >
                            -
                          </span>
                        )}{" "}
                        {!show && <span onClick={() => setShow(!show)}>+</span>}
                      </div>
                      {show && (
                        <>
                          {factortabvalue === "Factor Analysis" && (
                            <div
                              id="factoranalysistable"
                              className="table_height tabbody"
                            >
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header, index) => (
                                      <TableCell key={index}>
                                        {header}
                                        <TooltipComponent id={header} />
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {uniqueKeys.map((key, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {key}
                                        <TooltipComponent id={key} />
                                      </TableCell>
                                      {updatedData.map((obj, i) => {
                                        const subObj = obj[Object.keys(obj)[0]];
                                        return (
                                          <TableCell key={i}>
                                            {subObj[key]}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                              <TableFooter>
                                <TableRow></TableRow>
                              </TableFooter>
                            </div>
                          )}
                          {factortabvalue === "Factor" && (
                            <div
                              id="factortable"
                              className="table_height tabbody"
                            >
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    {factor_headers.map((header, index) => (
                                      <TableCell key={index}>
                                        {header}
                                        <TooltipComponent id={header} />
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Object.values(FactorTable).map(
                                    (item, index) => (
                                      <TableRow key={item.number}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          {item.StockTicker}
                                        </TableCell>
                                        <TableCell>
                                          {(
                                            parseFloat(item.Weight) * 100
                                          ).toFixed(2) + "%"}
                                        </TableCell>
                                        <TableCell>
                                          {item.Description}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              <TableFooter>
                                <TableRow></TableRow>
                              </TableFooter>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="portriskgrid sum-container">
                    <CaptureButtons elementType='chart' id='benchmarkAndFactorDiv' chartName="Factor_Analysis_Chart" activeTab="Factor Analysis"></CaptureButtons>
                    <h3 onClick={() => setShow1(!show1)}>
                      Benchmark & Factor Analysis{" "}
                      {show1 && <span className="minus">-</span>}{" "}
                      {!show1 && <span>+</span>}
                    </h3>
                    {show1 && (
                      <>
                        <div id="benchmarkAndFactorDiv" className="errorgrid">
                          <div id="benchmarkportfolio" className="trackingerror">
                            <h3>Benchmark Portfolio</h3>
                            <div id="benchGraph">
                              <TwoCircles apart={100 - R2BenchmarkNum} />
                            </div>
                            <div className="errolist">
                              <div className="tagspan">
                                <h4>R-Squared</h4>
                                <span className="tag primary">{R2Benchmark}</span>
                              </div>
                              <div className="tagspan">
                                <h4>Tracking Error</h4>
                                <span className="tag secondary">
                                  {TEBenchmark}
                                </span>
                              </div>
                              <div className="tagspan">
                                <h4>Beta</h4>
                                <span className="tag gray">{BetaBenchmark}</span>
                              </div>
                            </div>
                          </div>
                          <div id="factorportfolio" className="trackingerror">
                            <h3>Factor Portfolio</h3>
                            <div id="factGraph">
                              <TwoCircles apart={100 - R2FactorNum} />
                            </div>
                            <div className="errolist">
                              <div className="tagspan">
                                <h4>R-Squared</h4>
                                <span className="tag primary">{R2Factor}</span>
                              </div>
                              <div className="tagspan">
                                <h4>Tracking Error</h4>
                                <span className="tag secondary">{TEFactor}</span>
                              </div>
                              <div className="tagspan">
                                <h4>Beta</h4>
                                <span className="tag gray">{BetaFactor}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="portfolio-table contributio-table nogap factortbl sum-container">
                  <CaptureButtons elementType='table' chartName="Portfolio_Details_Table" activeTab="Portfolio Details" tableHeaders={HedgeableColumns} tableData={HedgeData}></CaptureButtons>                    
                  <div
                    id="portfoliodetailstable"
                    className="table_holder table_head ai-toggle-con m0i"
                  >
                    <h3 onClick={() => setShow2(!show2)} className="ai-toggle-ttl">
                      {show2 && <span className="minus">-</span>}{" "}
                      {!show2 && <span>+</span>}
                      <span className="tgltitle">Portfolio Details</span>  
                    </h3>
                    {show2 && (
                      <>
                        <ul className="subnavbar-menu fixed">
                          <li>
                            <a
                              className={
                                tabletabvalue === "Hedgeable Assets"
                                  ? "SUBITEM-selected "
                                  : "SUBITEM"
                              }
                              onClick={() =>
                                handleTableTabChange("Hedgeable Assets")
                              }
                            >
                              {" "}
                              Hedgeable Assets{" "}
                              <TooltipComponent id={"Hedgeable"} />
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                tabletabvalue === "Unhedgeable Assets"
                                  ? "SUBITEM-selected"
                                  : "SUBITEM"
                              }
                              onClick={() =>
                                handleTableTabChange("Unhedgeable Assets")
                              }
                            >
                              Unhedgeable Assets{" "}
                              <TooltipComponent id={"Unhedgeable"} />
                            </a>
                          </li>
                        </ul>
                        <TableContainer className="table_height tabbody">
                          {tabletabvalue === "Hedgeable Assets" && (
                            <>
                              <MaterialReactTable
                                columns={HedgeableColumns}
                                data={HedgeData}
                                enableStickyHeader
                                enableStickyFooter
                                enableBottomToolbar={false}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                enableColumnResizing
                                enableColumnOrdering
                                enableRowPinning
                                enablePagination={false}
                                enableFullScreenToggle={false}
                                muiTableContainerProps={{
                                  sx: {
                                    maxHeight: "400px",
                                  },
                                }}
                                initialState={{
                                  density: "compact",
                                }}
                                muiTableHeadCellProps={{
                                  sx: {
                                    backgroundColor: "white",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                  },
                                }}
                                muiTableFootCellProps={{
                                  sx: {
                                    backgroundColor: "white",
                                    position: "sticky",
                                    bottom: 0,
                                    zIndex: 1,
                                  },
                                }}
                              />
                            </>
                          )}
                          {tabletabvalue === "Unhedgeable Assets" && (
                            <>
                              <MaterialReactTable
                                columns={UnhedgeableColumns}
                                data={UnhedgeData}
                                enableStickyHeader
                                enableStickyFooter
                                enableBottomToolbar={false}
                                enableColumnActions={false}
                                enableDensityToggle={false}
                                enableColumnResizing
                                enableColumnOrdering
                                enableRowPinning
                                enablePagination={false}
                                enableFullScreenToggle={false}
                                muiTableContainerProps={{
                                  sx: {
                                    maxHeight: "400px",
                                  },
                                }}
                                initialState={{
                                  density: "compact",
                                }}
                                muiTableHeadCellProps={{
                                  sx: {
                                    backgroundColor: "white",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                  },
                                }}
                                muiTableFootCellProps={{
                                  sx: {
                                    backgroundColor: "white",
                                    position: "sticky",
                                    bottom: 0,
                                    zIndex: 1,
                                  },
                                }}
                              />
                            </>
                          )}
                        </TableContainer>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="drawercontent">
          <p>Portfolio is Empty. Please add stock to proceed</p>
        </div>
      )}
    </>
  );
}

export default FactorAnalysis;
