import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import ADSidebar from '../../Components/ADSidebar/ADSidebar';
import CustomerPortfolio from '../../Components/TableComponent/CustomerPortfolio';
import './ADClientSummaryScreen.css';

function AdvisorClientSummaryScreen() {

    return (
        <>
            <Navbar />

            <div className="container">
                <ADSidebar />
                <main className="main-content">
                    <CustomerPortfolio />
                </main>
            </div>
        </>
    );
}

export default AdvisorClientSummaryScreen;
