const tooltipContent = {
    "Portfolio Risk": "Downside volatility of a portfolio.",
    "Risk Contribution": "Percentage of total portfolio risk attributable to individual holdings.",
    "Stock Volatility": "Downside volatility of an individual holding (annualized).",
    "Benchmark": "Refers to the S&P 500 index as a point of comparison for the portfolio being analyzed.",
    "Factor": "Refers to a potential basket of market indexes, and associated ETF’s as a point of comparison for the portfolio being analyzed.",
    "Hedgeable": "Eligible Assets that can be protected by Adaptive Shield.",
    "Unhedgeable": "Non-Eligible Assets that can't be protected by Adaptive Shield.",
    "Implied Volatility":"This forward-looking measure of risk is derived from market prices for stock and index options, which theoretically reflect the market’s expectations for the magnitude of changes in the underlying price of an asset. Possible distortions include market-moving events such as a scheduled release of quarterly earnings. The VIX, currently used as Adaptive’s measure of <a href='https://adaptive-investments.com/app/tools/rw'>Market Weather</a>, is an estimation of average implied volatility of the constituents of S&P 500 market index. Read more about implied volatility at Wikipedia and Investopedia.",
    "Protection Level": "Prefered level of protection for your given portfolio.",
    "Risk Return Ratio": "return / downside volatility (annualized)",
    "Shield Cost(%)": "% of portfolio value",
    "Portfolio Implied Downside Volatility (without Shield)": "Standard deviation of negative returns for a given portfolio.",
    "Asset Allocation": "Target downside risk expressed as Asset Allocation (Stock/Bond)",
    "Closest Matching Index": "Market Indexes closest matching risk of portfolio.",
    "Concentrated Holdings": "Stocks with high idiosyncratic risks that can be hedged with stock puts.",
    "Portfolio Analytics": "Portfolio Analytics",
    "Shield Recommendations": "Shield Recommendations",
    "Clone Portfolio": "Clone Portfolio"
};

export default tooltipContent;