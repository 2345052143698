    import React, { useCallback } from 'react';
    import AvatarEditor from 'react-avatar-editor';
    import Modal from '@mui/material/Modal';
    import Box from '@mui/material/Box';
    import Slider from '@mui/material/Slider';

    const modalStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 600,
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    export const AvatorEditorModal = ({ open, onClose, editorRef, image, scale, setScale, onSave, width, height, borderRadius }) => {
        const handleScaleChange = useCallback((event, newValue) => {
            setScale(newValue);
        }, [setScale]);
    
        return (
            <Modal
                open={open}
                onClose={() => {
                    onClose();
                    setScale(1.2);
                }}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={modalStyle}>
                    <h2 id="modal-title">Edit Image</h2>
                    <AvatarEditor
                        ref={editorRef}
                        image={image}
                        width={width}
                        height={height}
                        border={50}
                        borderRadius={borderRadius}
                        color={[255, 255, 255, 0.6]}
                        scale={scale}
                        rotate={0}
                    />
                    <Slider
                        value={scale}
                        onChange={handleScaleChange}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="zoom-slider"
                    />
                    <div className='drawerbottom actions'>
                        <button className='ai-btn primary solid' onClick={onSave} aria-label="Save changes">Save</button>
                        <button className='ai-btn red solid' onClick={onClose} style={{ marginLeft: 8 }} aria-label="Cancel editing">Cancel</button>
                    </div>
                </Box>
            </Modal>
        );
    };
    
    export const ViewImageModal = ({ open, onClose,onDelete, imageSrc, altText }) => {
        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="common-modal"
                aria-describedby="modal-with-image"
            >
                <Box 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        width: '90%',
                        maxWidth: 600,
                        maxHeight: '90vh',
                        overflow: 'auto',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="actions">
                            <button className="close-drawer" onClick={onClose}>✕</button>
                        </div>
                    </div>
                    <div>
                        <img 
                            src={imageSrc} 
                            alt={altText} 
                            style={{ maxWidth: '100%', height: 'auto' }} 
                        />
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <div className='drawerbottom actions'>
                            <button className='ai-btn primary solid'onClick={onClose} ><img src="./Assets/close-icon.svg" alt="Close Icon" />Close</button>
                            <button className='ai-btn red solid' onClick={onDelete}>Remove</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        );
    };