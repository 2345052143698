import { GetADClientPortfolio } from "../Apis/GetADClientPortfolio";
import { handleApiError } from './notificationActions';

export const FETCH_PORTFOLIO_START = "FETCH_PORTFOLIO_START";
export const FETCH_PORTFOLIO_SUCCESS = "FETCH_PORTFOLIO_SUCCESS";
export const FETCH_PORTFOLIO_FAILURE = "FETCH_PORTFOLIO_FAILURE";
export const FETCH_PORTFOLIO_EMPTY = "FETCH_PORTFOLIO_EMPTY";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const SELECT_PORTFOLIO = 'SELECT_PORTFOLIO';
export const TOGGLE_PORTFOLIO_SELECTION = 'TOGGLE_PORTFOLIO_SELECTION';
export const PORTFOLIO_ADDED = 'PORTFOLIO_ADDED';
export const TOGGLE_CLIENT_SELECTION = 'TOGGLE_CLIENT_SELECTION';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const TOGGLE_PORTFOLIO_HEDGEABLE_FLAG = 'TOGGLE_PORTFOLIO_HEDGEABLE_FLAG';


export const fetchPortfolio = () => async dispatch => {
    dispatch({ type: FETCH_PORTFOLIO_START });

    try {
        const data = await GetADClientPortfolio();
        if(data.status === 200){
            if (!data.result.length) {
                dispatch({ type: FETCH_PORTFOLIO_EMPTY });
                return;
            }
            dispatch({ type: FETCH_PORTFOLIO_SUCCESS, payload: data.result });
        }
        else{
            handleApiError(data, dispatch);
        }
    } catch (error) {
        dispatch({ type: FETCH_PORTFOLIO_FAILURE, payload: error.message });
    }
};

export const togglePortfolioSelection = (portfolioId,actionType) => ({
    type: TOGGLE_PORTFOLIO_SELECTION,
    payload: {portfolioId:portfolioId, actionType:actionType}
});

export const togglePortfolioHedgeableFlag = (portfolioId,CustomerId) => ({
    type: TOGGLE_PORTFOLIO_HEDGEABLE_FLAG,
    payload: {portfolioId:portfolioId, CustomerId:CustomerId}
});

export const toggleClientSelection = (CustomerId,clickType) => ({
    type: TOGGLE_CLIENT_SELECTION,
    payload: {CustomerId:CustomerId,clickType:clickType}
});

export const portfolioAdded = () => ({
    type: PORTFOLIO_ADDED
});

export const selectPortfolio = (portfolioId,actionType) => ({
    type: SELECT_PORTFOLIO,
    payload: {portfolioId:portfolioId, actionType:actionType}
});

export const selectClient = (CustomerId) => ({
    type: SELECT_CLIENT,
    payload: {CustomerId:CustomerId}
});