import {
    FETCH_PROFILE_START,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAILURE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
} from '../actions/advisorProfileActions';

const initialState = {
    profileData: {},
    isLoading: false,
    error: null,
};



const advisorProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILE_START:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                profileData: action.payload,
                error: null,
            };
        case UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case FETCH_PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                profileData: {}
            };
        default:
            return state;
    }
};

export default advisorProfileReducer;
