import React, { useState } from "react";
import './OnboardClient.scss';
import { useHistory } from "react-router-dom";
import ClientPortfolioLineItemsManualImport from "../AddClientPortfolioLineItems/AddClientPortfolioLineItems";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function AdaptiveManualImportWizardComponent() {
    const [importStage, setImportStage] = React.useState(1);
    const history = useHistory();
    const onBoardScreen = () => history.push("/OnboardScreen");

    return(
        <>
            <h1 className="title">Adaptive Import Wizard</h1>
            <div className="onboarding">
                <div className={importStage === 1 ? 'onboardstep active' : 'onboardstep'}>
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.6797 22.6667H21.3197" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.6797 17.3334H21.3197" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3333 12H16.7133C16.268 12 15.8507 11.7773 15.604 11.4067L13.7293 8.59467C13.4813 8.22267 13.0653 8 12.62 8H6.66667C5.19333 8 4 9.19333 4 10.6667V25.3333C4 26.8067 5.19333 28 6.66667 28H25.3333C26.8067 28 28 26.8067 28 25.3333V14.6667C28 13.1933 26.8067 12 25.3333 12Z" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.66699 8V6.66667C6.66699 5.19333 7.86033 4 9.33366 4H22.667C24.1403 4 25.3337 5.19333 25.3337 6.66667V12" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className="round_title_text">Add Portfolio</p>
                </div>
                <div className={importStage === 2 ? 'onboardstep active' : 'onboardstep'}>
                    <div className="icon">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6667 28.0001H6.66667C5.19333 28.0001 4 26.8067 4 25.3334V13.3334C4 11.8601 5.19333 10.6667 6.66667 10.6667H18.6667C20.14 10.6667 21.3333 11.8601 21.3333 13.3334V25.3334C21.3333 26.8067 20.14 28.0001 18.6667 28.0001Z" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.5 19.8785L11.612 21.9905L16.2213 17.3799" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.33301 10.6667V6.66667C9.33301 5.19333 10.5263 4 11.9997 4H25.333C26.8063 4 27.9997 5.19333 27.9997 6.66667V20C27.9997 21.4733 26.8063 22.6667 25.333 22.6667H21.333" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p className="round_title_text">Complete</p>
                </div>
            </div>
            {importStage === 1 && (
                <>
                    <div className="center">
                        <ClientPortfolioLineItemsManualImport onSubmitRedirectRoute='/ClientPortfolios' />
                    </div>
                    <div className="button-div">
                        <button className="ai-btn line secondary" onClick={() => onBoardScreen()}>
                            Skip
                        </button>
                    </div>
                </>
            )}
            {/* {importStage === 2 &&
            (
                <div className="welcome">
                    <img src="./Assets/Single.png"></img>
                    <h2>
                        Portfolio successfully imported.
                    </h2>
                    <div className="button-div">
                        <button className="ai-btn solid secondary">
                            Go to  Dashboard
                        </button>
                    </div>
                </div>
            )} */}
        </>
    )

}

export default AdaptiveManualImportWizardComponent;