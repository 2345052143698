import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from "react-router-dom";
import Loader from '../../Components/Loader/Loader';
import { VerifySubscription } from "../../Apis/VerifySubscription";
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

function SubscriptionVerification() {

    const history = useHistory();
    const dispatch = useDispatch();
    const Subscribed = () => history.push("/ClientPortfolios");
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptionStatus, setSubscritionStatus] = useState('');

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        setIsLoading(true);
        sleep(100).then(() => {
            VerifySubscription().then((data) => {
                if (data.status === 200) {
                    if (data["result"] === "active" || data["result"] === "trialing") {
                        sessionStorage.setItem('subscriptionStatus', 'active');
                        setSubscritionStatus("completed");
                        setIsLoading(false);
                        TagManager.dataLayer({
                            dataLayer: {
                              event: 'Subscription Purchased',
                              userType: sessionStorage.getItem("selectedAcType"),
                              user_id: sessionStorage.getItem("clientId"),
                            },
                          });
                    } else {
                        setSubscritionStatus(data["result"]);
                        setIsLoading(false);
                    }
                } else {
                    handleApiError(data, dispatch);
                }
            }
            ).finally(() => setIsLoading(false));
        });
    }, []);

    const handleExit = () => {
        return <Redirect to={{ pathname: "/OnboardScreen", state: { stage: 1 } }} />;
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            {isLoading ? (
                <div className="loading-overlay">
                    <Loader/>
                </div>
            ) : (
                <div>
                    <h1 className="boardlogo"><img src="./Assets/logo.svg" /></h1>
                    <>
                        {subscriptionStatus === "completed" ?
                            (<div className="welcome">
                                <img src="./Assets/payment-success.svg"></img>
                                <h2>
                                    Congratulations. Your Adaptive Core free trial starts now. We welcome your feedback on the services we're building!
                                </h2>
                                <div className="button-div">
                                    <button className="ai-btn line primary" onClick={() => Subscribed()}>
                                        Next
                                    </button>
                                </div>
                            </div>) : (
                                <div className="welcome">
                                    <img src="./Assets/payment-fail.svg"></img>
                                    <h2>
                                        Payment for the service failed. Please review your payment details.
                                    </h2>
                                    <div className="button-div">
                                        <button className="ai-btn line secondary" onClick={() => handleExit()}>
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </>
                </div>
            )}
        </>
    );
}

export default SubscriptionVerification;