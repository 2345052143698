const initialState = {
  isLoading: false,
  error: null,
  data: { calls: {}, puts: {}, loaded: false },
  lastFetchedPortfolioId: null,
  lastFetchedDetailsHash: '',
};

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS_DATA':
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        lastFetchedPortfolioId: action.payload.portfolioId,
        lastFetchedDetailsHash: action.payload.detailsHash,
      };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default optionsReducer;
