import React from "react";
import MainSlider from "../../Components/MainSlider/MainSlider";
import LoginWindow from "../../Components/LoginWindow/LoginWindow";
import "./LoginScreen.scss";
import ForgotPassword from "../../Components/ForgotPassword/ForgotPassword";
import OTPConfirmation from "../../Components/OTPConfirmationScreen/OTPConfirmation";
import Signup from "../../Components/Signup/Signup";
import { useState } from "react";
import Toaster from "../../Components/Toaster/Toaster";

function LoginScreen({ default_screen , user_type}) {
  const [screen, setScreen] = useState(default_screen === null ? "Login" : default_screen);
  const [userEmail, setUserEmail] = useState('');
  const [clientMetadata, setClientMetadata] = useState(null);

  //   setUserDetails({
  //     userEmail: email,
  //     userName: email
  //   });
  // };
  console.log("screen", userEmail)
  const screenSwitch = () => {
    switch (screen) {
      case "Login":
        return (
          <LoginWindow setScreen={setScreen} handleOTPConfirmation={setUserEmail} />
        )
      case "Sign Up":
        return (
          <Signup setScreen={setScreen} setClientMetadata={setClientMetadata} userType={user_type}/>
        )
      case "OTP":
        return (
          <OTPConfirmation setScreen={setScreen} clientMetadata={clientMetadata} />
        )
      case "Forgot Password":
        return (
          <ForgotPassword setScreen={setScreen} />
        )
      default:
        return (
          <LoginWindow setScreen={setScreen} handleOTPConfirmation={setUserEmail} />
        )
    }
  }
  return (
    <section className="loginwrapper">
      <div className="toast-container">
        <Toaster />
      </div>
      <section className="ai-slide-content">
        <MainSlider />
      </section>
      <div className="ai-login-container">
        <div className="ai-login-form">
          <img className='b-logo' src="./Assets/logo.svg" />
          {
            screenSwitch()
          }
        </div>
        <footer>
          <div className="ia-footer">
            <div>
              <ul className="ia-footer-links">
                <li><a className="ai-link" href="https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=843770" target="_blank">ADV Part 2</a></li>
                <li><a className="ai-link" href="https://reports.adviserinfo.sec.gov/crs/crs_318716.pdf" target="_blank">CRS</a></li>
                <li><a className="ai-link" href="https://brokercheck.finra.org/" target="_blank">FINRA BrokerCheck</a></li>
              </ul>
              <p>
                Adaptive Investment Solutions LLC is neither a broker/dealer nor an investment advisor.
                Investment advice is offered through Adaptive Advisers LLC, a SEC-registered advisor
                which is affiliated with Adaptive Investment Solutions LLC. Adaptive Advisers LLC is not
                the guarantor of any downside protection. Check the background of Adaptive Advisers
                LLC on FINRA BrokerCheck. 2023&copy; Adaptive Investment Solutions LLC
              </p>
            </div>
            <ul className="ia-footer-links">
              <li>
                <a className="ai-link" href="https://adaptive-investments.com/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="ai-link" href="https://adaptive-investments.com/terms-conditions/" target="_blank">
                  Terms &#38; Conditions
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>

    </section>
  );
}

export default LoginScreen;
