import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import ShieldRecommendationv2 from '../ShieldRecommendationv2/ShieldRecommendationv2';
// import ShieldRecommendation from '../ShieldRecommendation/ShieldRecommendation';
import Dashboard from '../Dashboard/Dashboard';
import Loader from '../../Components/Loader/Loader';
import Toaster from '../Toaster/Toaster';
import { fetchOptionsDataIfNeeded } from '../../actions/OptionDataAction';
import { togglePortfolioSelection } from '../../actions/portfolioActions';
import CollarView from '../CollarView/CollarView';

function RecommendationDrawer() {

    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    // const { isLoading, error, data } = useSelector(state => state.options);
    const { isLoading, error, data: optionsData } = useSelector(state => state.options);
    const [activeTabLabel, setActiveTabLabel] = useState('Shield Recommendation');
    const portfolioDetails = useSelector(state => state.portfolioDetails[drawerData.portfolioId]?.details);
    const isPortfolioLoading = useSelector(state => state.portfolioDetails[drawerData.portfolioId]?.isLoading);
    const advisorProfile = useSelector(state => state.advisorProfile.profileData);
    const [visibleCartItems, setVisibleCartItems] = useState({
        marketShield: false,
        marketShieldPlus: false,
        CallWriting:false,
        PutBuying:false
    });
    const [outputIndexTicker, setOutputIndexTicker] = useState('TSLA'); // Track output index ticker

    // const fetchOptionsData = () => {
    //     if (!portfolioDetails) return;

    //     const portfolioTickers = portfolioDetails.PortfolioLineItem.Hedgeable.map(item => ({
    //         ticker: item.Ticker,
    //         quantity: item.Quantity,
    //         price: item.ClosePrice || 0,
    //     }));

    //     if (outputIndexTicker) {
    //         const indexTicker = { ticker: outputIndexTicker, quantity: 10, price: 0 };
    //         dispatch(fetchOptionsDataIfNeeded([...portfolioTickers, indexTicker], drawerData.portfolioId));
    //     }
    // };

    useEffect(() => {
        if (portfolioDetails?.PortfolioLineItem?.Hedgeable) {
            const portfolioData = portfolioDetails.PortfolioLineItem.Hedgeable.map(item => ({
                ticker: item.Ticker,
                quantity: item.Quantity,
                price: item.ClosePrice || 0,
            }));
            dispatch(fetchOptionsDataIfNeeded(portfolioData, drawerData.portfolioId));
        }
    }, [dispatch, portfolioDetails, drawerData.portfolioId]); 
    

    // useEffect(() => {
    //     fetchOptionsData();
    // }, [portfolioDetails, outputIndexTicker, drawerData.portfolioId]); // Fetch when outputIndexTicker changes

    const tabs = [
        { label: 'Shield Recommendation', key: 'ShieldRecommendation', component: ShieldRecommendationv2 },
        { label: 'Call Writing', key: 'CallWriting', component: Dashboard },
        { label: 'Put Buying', key: 'PutBuying', component: Dashboard },
        // { label: 'Collar', key: 'Collar', component: CollarView },
    ];

    const renderTabComponent = (tab) => {
        if (activeTabLabel === tab.label) {
            const Component = tab.component;
            return (
                <Component
                    portfolioId={drawerData.portfolioId}
                    isEmpty={drawerData.isEmpty}
                    portfolioDetails={portfolioDetails}
                    advisorProfile={advisorProfile}
                    tabType={tab.key}
                    optionsData={optionsData}
                    isLoadingOptions={isLoading} 
                    visibleCartItems={visibleCartItems}
                    setVisibleCartItems={setVisibleCartItems}
                    // onOutputIndexChange={handleOutputIndexChange}
                />
            );
        }
        return null;
    };

    const handleCloseDrawer = (portfolioId) => {
        dispatch(togglePortfolioSelection(portfolioId));
        dispatch(closeDrawer());
    }
    const handleOutputIndexChange = (newIndex) => {
        setOutputIndexTicker('TSLA');
    };

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawer">
                {isLoading || isPortfolioLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>) : (
                    <>
                        <div className="drawer-header">
                            <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                            <h1>{drawerData.Name} / {drawerData.portfolio}</h1>
                            <div className="drawerbottom actions">
                                <button className='ai-btn primary solid'
                                    onClick={() => dispatch(openDrawer('Analytics', { portfolioId: drawerData.portfolioId, Name: drawerData.Name, portfolio: drawerData.portfolio, isEmpty: drawerData.isEmpty }))}>Risk Analytics</button>
                                    <button className="close-drawer" onClick={() => handleCloseDrawer(drawerData.portfolioId)}>✕</button>
                            </div>
                        </div>
                        <>
                            {!drawerData.isEmpty ? (
                                <>
                                    <div className="tab">
                                        {tabs.map(tab => (
                                            <button key={tab.label} onClick={() => setActiveTabLabel(tab.label)} className={activeTabLabel === tab.label ? 'active' : ''}>
                                                {tab.label}
                                            </button>
                                        ))}
                                    </div>
                                    {tabs.map(tab => renderTabComponent(tab))}
                                </>
                            ): (
                                <div className="drawercontent">
                                    <p>Portfolio is Empty. Please add stock to proceed</p>
                                </div>
                            )}
                        </>
                    </>
                )}
            </div>
        </>
    );
};

export default RecommendationDrawer;