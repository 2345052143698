import React, { useState, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { TabContainer, TabPane, TabContent } from "react-bootstrap";
import { addZeroes } from "../../Utilities/AddZeros";
import { useSelector } from "react-redux";
import TooltipComponent from "../TooltipComponent/TooltipComponent";
import { MaterialReactTable } from "material-react-table";
import CaptureButtons from "../CaptureButtons/CaptureButtons";
import { customColumnSort } from "../../Utilities/CustomColumnSort";
var currObj = {
  style: "currency",
  currency: "USD",
};

const BackTestV2Table = ({
  PortfolioDetailsTable,
  showPortfolioShield,
  ProtectionLevel,
  userSelectedData
}) => {
  const drawerData = useSelector((state) => state.drawer.data);
  const portfolioId = drawerData.portfolioId;
  const [HedgeableData, setHedgeableData] = useState(PortfolioDetailsTable?.Hedgeable || []);
  const [UnhedgeableData, setUnhedgeableData] = useState(PortfolioDetailsTable?.Unhedgeable || []);
  const [show2, setShow2] = useState(true);

  const HedgeData = useMemo(
    () =>
      HedgeableData.map((value,i) => ({
        sr: i + 1,
        symbol: value.Ticker,
        company: value.Name,
        quantity: Number(addZeroes(Number(parseFloat(value.Quantity).toFixed(2)))).toLocaleString("en-US"),
        closingprice: Number(addZeroes(Number(parseFloat(value.ClosePrice).toFixed(2)))).toLocaleString("en-US", currObj),
        weight: (value.Weight * 100).toFixed(2) + "%",
        MktVal : Number(addZeroes(Number(parseFloat(value.MktVal).toFixed(2)))).toLocaleString("en-US", currObj)
      })),
    [HedgeableData]
  );

  const UnhedgeData = useMemo(
    () =>
      UnhedgeableData.map((value) => ({
        sr: value.number + 1,
        symbol: value.Ticker,
        company: value.Name,
        quantity: value.Quantity.toFixed(2)
      })),
    [UnhedgeableData]
  );

  const totalSums = HedgeableData.reduce(
    (acc, curr) => {
      acc.MktVal += curr.MktVal;
      acc.weight += (curr.Weight * 100);
      return acc;
    },
    {
      MktVal: 0,
      weight: 0,
    }
  );

  const HedgeableColumns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      { accessorKey: "symbol", header: "Symbol", footer: "Portfolio Total:", size: "130"},
      { accessorKey: "company", header: "Position Name", footer: "" },
      {  
        accessorKey: "quantity", 
        header: "Quantity", 
        footer: "" ,
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'quantity'),
      },
      {
        accessorKey: "closingprice",
        header: "Closing Price",
        footer: "",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'closingprice'),
      },
      {
        accessorKey: "MktVal",
        header: "Market Value",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'MktVal'),
        footer: Number(addZeroes(Number(parseFloat(totalSums.MktVal).toFixed(2)))).toLocaleString("en-US", currObj),
        size: "170"
      },
      {
        accessorKey: "weight",
        header: "Weight",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'weight'),
        footer: "100%",
      },
    ],
    [totalSums]
  );

  const UnhedgeableColumns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      { accessorKey: "symbol", header: "Symbol", footer: "Portfolio Total:", size: "130"},
      { accessorKey: "company", header: "Position Name", footer: "" },
      { 
        accessorKey: "quantity", 
        header: "Quantity", 
        footer: "" ,
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'quantity'),
      },
    ],
    [totalSums]
  );


  const [tabletabvalue, setTableTabValue] = React.useState("Hedgeable Assets");

  function handleTableTabChange(tabletabvalue) {
    setTableTabValue(tabletabvalue);
  }

  return (
    <div className="portfolio-table contributio-table nogap factortbl sum-container">
      <CaptureButtons elementType='table' chartName="Portfolio_Details_Table" activeTab="Portfolio Details" tableHeaders={HedgeableColumns} tableData={HedgeData}></CaptureButtons>
      <div className="table_holder table_head ai-toggle-con m0i">
        <h3 onClick={() => setShow2(!show2)} className="ai-toggle-ttl">
          {show2 && <span className="minus">-</span>} {!show2 && <span>+</span>}
          <span className="tgltitle">Portfolio Details</span>
        </h3>
        {show2 && (
          <>
            <ul className="subnavbar-menu fixed">
              <li>
                <a
                  className={
                    tabletabvalue === "Hedgeable Assets"
                      ? "SUBITEM-selected "
                      : "SUBITEM"
                  }
                  onClick={() =>
                    handleTableTabChange("Hedgeable Assets")
                  }
                >
                  {" "}
                  Hedgeable Assets{" "}
                  <TooltipComponent id={"Hedgeable"} />
                </a>
              </li>
              <li>
                <a
                  className={
                    tabletabvalue === "Unhedgeable Assets"
                      ? "SUBITEM-selected"
                      : "SUBITEM"
                  }
                  onClick={() =>
                    handleTableTabChange("Unhedgeable Assets")
                  }
                >
                  Unhedgeable Assets{" "}
                  <TooltipComponent id={"Unhedgeable"} />
                </a>
              </li>
            </ul>
            <TableContainer className="table_height tabbody">
              {tabletabvalue === "Hedgeable Assets" && (
                <>
                  <MaterialReactTable
                    columns={HedgeableColumns}
                    data={HedgeData}
                    enableStickyHeader
                    enableStickyFooter
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableDensityToggle={false}
                    enableColumnResizing
                    enableColumnOrdering
                    enableRowPinning
                    enablePagination={false}
                    enableFullScreenToggle={false}
                    muiTableContainerProps={{
                      sx: {
                        maxHeight: "400px",
                      },
                    }}
                    initialState={{
                      density: "compact",
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        backgroundColor: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      },
                    }}
                    muiTableFootCellProps={{
                      sx: {
                        backgroundColor: "white",
                        position: "sticky",
                        bottom: 0,
                        zIndex: 1,
                      },
                    }}
                  />
                </>
              )}
              {tabletabvalue === "Unhedgeable Assets" && (
                <>
                  <MaterialReactTable
                    columns={UnhedgeableColumns}
                    data={UnhedgeData}
                    enableStickyHeader
                    enableStickyFooter
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableDensityToggle={false}
                    enableColumnResizing
                    enableColumnOrdering
                    enableRowPinning
                    enablePagination={false}
                    enableFullScreenToggle={false}
                    muiTableContainerProps={{
                      sx: {
                        maxHeight: "400px",
                      },
                    }}
                    initialState={{
                      density: "compact",
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        backgroundColor: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      },
                    }}
                    muiTableFootCellProps={{
                      sx: {
                        backgroundColor: "white",
                        position: "sticky",
                        bottom: 0,
                        zIndex: 1,
                      },
                    }}
                  />
                </>
              )}
            </TableContainer>
          </>
        )}
      </div>
    </div>
  );
};

export default BackTestV2Table;
