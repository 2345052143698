import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import Loader from '../../Components/Loader/Loader';
import Toaster from '../Toaster/Toaster';
import { handleApiError, showNotification } from '../../actions/notificationActions';
import { updateProfile } from '../../actions/advisorProfileActions';
import TagManager from 'react-gtm-module';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import { AvatorEditorModal, ViewImageModal } from './AvatorEditor';
import { updateUserPicture , deleteUserPicture } from '../../actions/userPictureActions';

const UpdateProfile = () => {
    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const [editData, setEditData] = useState({});
    const [firstName, setFirstName] = useState(drawerData.firstName);
    const [lastName, setLastName] = useState(drawerData.lastName);
    const [email, setEmail] = useState(drawerData.emailid);
    const [companyName, setCompanyName] = useState(drawerData.companyname);
    const [contactNumber, setContactNumber] = useState(drawerData.contactno);
    const [crdNumber, setCrdNumber] = useState(drawerData.crd_number);
    const [isLoading, setIsLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(useSelector(state => state.userPicture.userPictureData?.profile_picture) || null);
    const [companyLogoImage, setCompanyLogoImage] =  useState(useSelector(state => state.userPicture.userPictureData?.firm_logo) || null);
    const [openProfileEditor, setOpenProfileEditor] = useState(false);
    const [openCompanyEditor, setOpenCompanyEditor] = useState(false);
    const [tempProfileImage, setTempProfileImage] = useState(null);
    const [tempCompanyLogoImage, setTempCompanyLogoImage] = useState(null);
    const [profileScale, setProfileScale] = useState(1.2);
    const [companyScale, setCompanyScale] = useState(1.2);
    const [profileInputKey, setProfileInputKey] = useState(Date.now());
    const [companyInputKey, setCompanyInputKey] = useState(Date.now());

    const [avatarOpen, setAvatarOpen] = useState(false);
    const [companyLogoOpen, setCompanyLogoOpen] = useState(false);

    const profileImageRef = useRef(null);
    const companyImageRef = useRef(null);
    const profileEditorRef = useRef(null);
    const companyEditorRef = useRef(null);

    let currentProfileImage = useSelector(state => state.userPicture.userPictureData?.profile_picture) || null
    let currentCompanyLogoImage = useSelector(state => state.userPicture.userPictureData?.firm_logo) || null

    const isValidImage = (file) => {
        return file && file instanceof File && file.type.startsWith('image/') && file.size <= 2 * 1024 * 1024;
    };

    const handleImageChange = useCallback((event, setTempImage, setOpenEditor, setScale, setInputKey) => {
        const file = event.target.files[0];
        if (file) {
            if (!isValidImage(file)) {
                dispatch(showNotification("Please select a valid image file (max 2 MB)", "error", "toaster"));
                return;
            }
            const img = new Image();
            const reader = new FileReader();
            reader.onloadend = () => {
                img.src = reader.result;
            };
            img.onload = () => {
                // const maxWidth = 1920;
                // const maxHeight = 1080;
    
                // if (img.width > maxWidth || img.height > maxHeight) {
                //     dispatch(showNotification(`Image resolution should not exceed ${maxWidth}x${maxHeight}.`, "error", "toaster"));
                //     return;
                // }
                setTempImage(reader.result);
                setScale(1.2);
                setOpenEditor(true);
            };
            reader.onerror = () => {
                dispatch(showNotification("Failed to read image file", "error", "toaster"));
            };
            reader.readAsDataURL(file);
        } else {
            dispatch(showNotification("No file selected", "info", "toaster"));
        }
        setInputKey(Date.now());
    }, [dispatch]);
    

    const handleEditClick = (ref) => {
        if (ref.current) {
            ref.current.click();
        }
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);
        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }
        return new Blob([u8arr], { type: mime });
    };
    
    const handleSaveImage = (editorRef, setImage, setOpenEditor) => {
        try {
            if (editorRef.current) {
                const canvas = editorRef.current.getImage();
                const dataURL = canvas.toDataURL('image/jpeg', 1);
                const blob = dataURLtoBlob(dataURL);
                const fileSizeMB = blob.size / (1024 * 1024);
                if (fileSizeMB > 2) {
                    dispatch(showNotification("Image size should be less than 2 MB.", "error", "toaster"));
                    return;
                }
                setImage(dataURL);
                setOpenEditor(false);
            } else {
                dispatch(showNotification("Unable to save image", "info", "toaster"));
            }
        } catch (error) {
            dispatch(showNotification("An error occurred while saving the image.", "error", "toaster"));
        }
    };
    
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const promises = [];
            if (editData && Object.keys(editData).length > 0) {
                promises.push(dispatch(updateProfile(editData)));
            }
            if (profileImage !== currentProfileImage || companyLogoImage !== currentCompanyLogoImage) {
                const userPicture = {
                    'profile_picture': profileImage,
                    'company_logo': companyLogoImage
                };
                promises.push(dispatch(updateUserPicture(userPicture)));
            }
            await Promise.all(promises);
            dispatch(showNotification("Profile Updated Successfully", "success", "toaster"));
            TagManager.dataLayer({
                dataLayer: {
                    event: 'User Profile Updated',
                    userType: sessionStorage.getItem("selectedAcType") || "guest",
                    user_id: sessionStorage.getItem("clientId") || "unknown",
                },
            });
            dispatch(closeDrawer());
        } catch (error) {
            handleApiError(error, dispatch);
        } finally {
            setIsLoading(false); 
        }
    };

    const handleProfilePictureDeletion = async () => {
        setIsLoading(true);
        if (profileImage === currentProfileImage) {
            setAvatarOpen(false);
            dispatch(deleteUserPicture('profile_picture'));
            setProfileImage(null);
        } else {
            setProfileImage(null);
            setAvatarOpen(false);
        }
        dispatch(showNotification("Profile Picture removed Successfully", "success", "toaster"));
        setIsLoading(false);
    };
    
    const handleFirmLogoDeletion = async () => {
        setIsLoading(true);
        if (companyLogoImage === currentCompanyLogoImage) {
            setCompanyLogoOpen(false);
            dispatch(deleteUserPicture('firm_logo'));
            setCompanyLogoImage(null);
        } else {
            setCompanyLogoImage(null);
            setCompanyLogoOpen(false);
        }
        dispatch(showNotification("Company Logo removed Successfully", "success", "toaster"));
        setIsLoading(false);
    };

    const handleDeleteUserPicture = async (image_type) => {
        try {
            if (image_type === 'profile_picture') {
                handleProfilePictureDeletion();
            } else if (image_type === 'firm_logo') {
                handleFirmLogoDeletion();
            }
        } catch (error) {
            handleApiError(error, dispatch);
        } 
    };

    const handleInputChange = (e) => {
        setEditData({
            ...editData,
            [e.target.name]: e.target.value
        });
    };

    const handleCloseDrawer = (portfolioId) => {
        dispatch(closeDrawer());
    }

    const handleAvatarClick = (image_type) => {
        if (image_type === 'profile_picture') {
            if (profileImage) {
                setAvatarOpen(true);
            } 
        } else if (image_type === 'company_logo') {
            if (companyLogoImage) {
                setCompanyLogoOpen(true);
            }
        }
    };
    
    const profileAvatar = useMemo(() => (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="standard"
            badgeContent={
                <label htmlFor="avatar-upload">
                    <IconButton
                        onClick={() => handleEditClick(profileImageRef)}
                        sx={{ p: 1, bgcolor: '#efe6ed' }}
                        color='primary'
                        aria-label="Edit profile picture"
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            }
        >
            <Avatar
                alt="User Avatar"
                src={profileImage}
                sx={{ width: 120, height: 120, border: '2px solid white' }}
                onClick={()=> handleAvatarClick('profile_picture')}
            />
        </Badge>
    ), [profileImage]);

    const companyAvatar = useMemo(() => (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="standard"
            badgeContent={
                <label htmlFor="logo-upload">
                    <IconButton
                        onClick={() => handleEditClick(companyImageRef)}
                        sx={{ p: 1, bgcolor: '#efe6ed' }}
                        color='primary'
                        aria-label="Edit company logo"
                    >
                        <EditIcon />
                    </IconButton>
                </label>
            }
        >
            <Avatar
                alt="Company Logo"
                src={companyLogoImage}
                sx={{ width: 120, height: 120, border: '2px solid white' }}
                onClick={()=> handleAvatarClick('company_logo')}
            />
        </Badge>
    ), [companyLogoImage]);

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className='drawerbackdrop'></div>
            <div className="drawer popout">
                {isLoading ? (
                    <div className="loading-overlay">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="drawer-header">
                            <h1>Edit Profile</h1>
                            <div className="actions">
                                <button className="close-drawer" onClick={handleCloseDrawer}>✕</button>
                            </div>
                        </div>
                        <div className='drawercontent'>
                            <div className='formdata'>
                                <h2>Personal Information</h2>
                                <div className="ai-form-group">
                                    <Stack direction="row" spacing={2} style={{
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}>
                                        <input
                                            type="file"
                                            key={profileInputKey}
                                            ref={profileImageRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={(event) => handleImageChange(event, setTempProfileImage, setOpenProfileEditor, setProfileScale, setProfileInputKey)}
                                        />
                                        {profileAvatar}
                                    </Stack>
                                </div>
                                <div className='ai-form-group'>
                                    <label>First Name</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        name='firstname'
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            handleInputChange(e);
                                        }}
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>Last Name</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        placeholder="Last Name"
                                        name='lastname'
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            handleInputChange(e);
                                        }}
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>Contact Number</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        placeholder="Contact Number"
                                        name='contactno'
                                        value={contactNumber}
                                        onChange={(e) => {
                                            setContactNumber(e.target.value);
                                            handleInputChange(e);
                                        }}
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>CRD Number</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        name='crd_number'
                                        placeholder="CRD Number"
                                        value={crdNumber}
                                        onChange={(e) => {
                                            setCrdNumber(e.target.value);
                                            handleInputChange(e);
                                        }}
                                    />
                                </div>
                                <h2>Company Information</h2>
                                <div className="ai-form-group">
                                    <Stack direction="row" spacing={2} style={{
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}>
                                        <input
                                            type="file"
                                            key={companyInputKey}
                                            ref={companyImageRef}
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={(event) => handleImageChange(event, setTempCompanyLogoImage, setOpenCompanyEditor, setCompanyScale, setCompanyInputKey)}
                                        />
                                        {companyAvatar}
                                    </Stack>
                                </div>
                                <div className='ai-form-group'>
                                    <label>Company Name</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        placeholder="Company Name"
                                        name='companyname'
                                        value={companyName}
                                        onChange={(e) => {
                                            setCompanyName(e.target.value);
                                            handleInputChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='drawerbottom actions'>
                            <button className='ai-btn red solid' onClick={handleCloseDrawer}><img src="./Assets/close-icon.svg" alt="Close Icon" />Close</button>
                            <button className='ai-btn primary solid' onClick={handleSubmit}>Save</button>
                        </div>
                        <AvatorEditorModal
                            open={openProfileEditor}
                            onClose={() => {
                                setOpenProfileEditor(false);
                                setTempProfileImage(null);
                                setProfileScale(1.2);
                            }}
                            editorRef={profileEditorRef}
                            image={tempProfileImage}
                            scale={profileScale}
                            setScale={setProfileScale}
                            onSave={() => handleSaveImage(profileEditorRef, setProfileImage, setOpenProfileEditor)}
                            width={300}
                            height={300}
                            borderRadius={150}
                        />
                        <AvatorEditorModal
                            open={openCompanyEditor}
                            onClose={() => {
                                setOpenCompanyEditor(false);
                                setTempCompanyLogoImage(null);
                                setCompanyScale(1.2);
                            }}
                            editorRef={companyEditorRef}
                            image={tempCompanyLogoImage}
                            scale={companyScale}
                            setScale={setCompanyScale}
                            onSave={() => handleSaveImage(companyEditorRef, setCompanyLogoImage, setOpenCompanyEditor)}
                            width={300}
                            height={200}
                            borderRadius={0}
                        />
                        <ViewImageModal
                            open={avatarOpen}
                            onClose={() => setAvatarOpen(false)}
                            onDelete={() => handleDeleteUserPicture('profile_picture')}
                            imageSrc={profileImage}
                            altText="Profile Picture"
                        />
                        <ViewImageModal
                            open={companyLogoOpen}
                            onClose={() => setCompanyLogoOpen(false)}
                            onDelete={() => handleDeleteUserPicture('firm_logo')}
                            imageSrc={companyLogoImage}
                            altText="Company Logo"
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default UpdateProfile;