import React, { memo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { addZeroes } from "../../Utilities/AddZeros";

function PortfolioReturnGraph({ data, gradientId }) {

    const xMax = Math.ceil(data[data.length - 1]["interval"]);
    const xMin = Math.floor(data[0]["interval"]);
    const yMax = Math.max(...data.map(d => d.count)).toFixed(2);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Return: ${payload[0].payload.interval.toFixed(2)}%`}</p>
                    <p className="label">{`Cumulative Probability of Lower Returns: ${(payload[0].payload.probability_loss * 100).toFixed(2)}%`}</p>
                    <p className="label">{`Cumulative Probability of Higher Returns: ${(payload[0].payload.probability_higher * 100).toFixed(2)}%`}</p>
                </div>
            );
        }
        return null;
    };

    // Determine the x-axis ticks
    // const interval = (xMax - xMin) / 10;
    const xTicks = [];
    for (let i = Math.ceil(xMin / 5) * 5; i <= xMax; i += 5) {
        xTicks.push(i);
    }
    if (!xTicks.includes(0)) {
        xTicks.push(0);
        xTicks.sort((a, b) => a - b);
    }
    console.log(xTicks)
    console.log(xMin)
    console.log(xMax)

    return (
        <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <defs>
                        <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
                            <stop offset="5%" stopColor="#60034C" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#60034C" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="interval"
                        domain={[xMin, xMax+5]}
                        // ticks={xTicks}
                        tick={{ fontSize: 10 }}  // Adjust font size if needed
                        tickFormatter={(tick) => `${tick.toFixed(2)}%`}
                        label={{ value: "Portfolio Return", position: 'insideBottom', offset: -5, fill: "#60034C" }}
                    />
                    <YAxis
                        tick={{ fontSize: 12 }}
                        tickFormatter={(tick) => `${tick.toFixed(2)}%`}
                        domain={[0, yMax]}
                        label={{ value: "Probability (%)", position: 'insideLeft', angle: -90, fill: "#60034C" }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        dataKey="count"
                        fill={`url(#${gradientId})`}
                        barSize={10}
                        gradientUnits="userSpaceOnUse"
                        radius={[10, 10, 0, 0]}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default memo(PortfolioReturnGraph);
