import React, { useState, useRef, useEffect, useMemo } from "react";
import './PdfSummary.scss';
import { useSelector, useDispatch } from 'react-redux';
import TagManager from "react-gtm-module";
import Loader from '../../Components/Loader/Loader';
import { addZeroes } from "../../Utilities/AddZeros";
import PdfComponent from '../PDFComponent/PdfComponent';
import Toaster from '../Toaster/Toaster';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { updateDisplayElement, updateCustomNote, deletePdfSummaryDataElement, updatePdfSummaryData, updateCustomTitle } from '../../actions/pdfSummaryActions';
import IconButton from '@mui/material/IconButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { pdfSummaryNotification } from "../../actions/notificationActions";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function PdfSummary(portfolioId,isEmpty){

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const dispatch = useDispatch();
    const pdfSummaryData = useSelector(state => state.pdfSummary);
    console.log('pdfSummaryData',pdfSummaryData)
    const [bdopen, setBdOpen] = React.useState(false);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const drawerData = useSelector(state => state.drawer.data);
    const portfolioDetails = useSelector(state => state.portfolioDetails[drawerData.portfolioId].details);
    const advisorProfile = useSelector(state => state.advisorProfile.profileData);
    
    const [notes, setNotes] = useState({});
    const [visibleNotes, setVisibleNotes] = useState({});

    const [titles, setTitles] = useState({});
    const [visibleTitles, setVisibleTitles] = useState({});

    const [dailogOpen, setDailogOpen] = useState(false);
    const [emails, setEmails] = useState([advisorProfile.emailid]);
    const [emailValue, setEmailValue] = useState("");
    const [emailError, setEmailError] = useState("");

    useEffect(() => {
        const titles = Object.entries(pdfSummaryData).reduce((acc, [k, v]) => {
            acc[k] = v.elementTitle;
            return acc;
        }, {});
        setTitles(titles);
    }, [pdfSummaryData]);

    const handleAddNoteClick = (chartName) => {
        setVisibleNotes(prev => ({ ...prev, [chartName]: true }));
        if (!notes[chartName]) {
            setNotes(prevNotes => ({ ...prevNotes, [chartName]: '' }));
        }
    };

    const handleClearNote = (chartName) => {
        setNotes(prevNotes => ({ ...prevNotes, [chartName]: '' }));
    };

    const handleSaveNote = (chartName) => {
        dispatch(updateCustomNote(chartName,notes[chartName]))
        setVisibleNotes(prev => ({ ...prev, [chartName]: false }));
    };

    const handleChangeNote = (chartName, value) => {
        setNotes(prevNotes => ({ ...prevNotes, [chartName]: value }));
    };

    const handleDeleteNote = (chartName) => {
        if (notes[chartName]) {
            setNotes(prevNotes => {
                const { [chartName]: _, ...newNotes } = prevNotes;
                return newNotes;
            });
            dispatch(updateCustomNote(chartName,''))
        }
    };

    const handleEditNote = (chartName) => {
        setVisibleNotes(prev => ({ ...prev, [chartName]: true }));
    }

    const handleAddTitleClick = (titleName) => {
        setVisibleTitles(prev => ({ ...prev, [titleName]: true }));
        if (!titles[titleName]) {
            setTitles(prevTitles => ({ ...prevTitles, [titleName]: '' }));
        }
    };

    const handleSaveTitle = (chartName) => {
        dispatch(updateCustomTitle(chartName,titles[chartName]))
        setVisibleTitles(prev => ({ ...prev, [chartName]: false }));
    };

    const handleChangeTitle = (chartName, event) => {
        setTitles(prevTitles => ({ ...prevTitles, [chartName]: event.target.value }));
    };

    const handleDeleteTitle = (chartName) => {
        if (titles[chartName]) {
            setTitles(prevTitles => {
                const { [chartName]: _, ...newTitles } = prevTitles;
                return newTitles;
            });
            dispatch(updateCustomTitle(chartName,''))
        }
    };

    const handleEditTitle = (chartName) => {
        setVisibleTitles(prev => ({ ...prev, [chartName]: true }));
    }

    const handleClearTitle = (chartName) => {
        setTitles(prevTitles => ({ ...prevTitles, [chartName]: '' }));
    };

    const toggleDisplayElement = (componentData) => {
        dispatch(updateDisplayElement(componentData.chartName, !componentData.displayElement));
    };

    const deletePdfSummaryData = (componentData) => {
        dispatch(deletePdfSummaryDataElement(componentData.chartName));
        dispatch(pdfSummaryNotification("Analytics Details removed from PDF Summary",'info'))
    }

    const handleClickOpen = () => {
        setDailogOpen(true);
      };
    
    const handleClose = () => {
        setDailogOpen(false);
    };

    const isInList = (email) => {
        return emails.includes(email);
    };
    
    const isEmail = (email) => {
        return /^[\w\d.-]+@[\w\d.-]+\.[a-zA-Z]{2,}$/.test(email);
    };

    const handleKeyDown = (e) => {
        if (["Enter", "Tab", ","].includes(e.key)) {
          e.preventDefault();
          const trimmedValue = emailValue.trim();
    
          if (trimmedValue && isValid(trimmedValue)) {
            setEmails((prevItems) => [...prevItems, trimmedValue]);
            setEmailValue("");
            setEmailError(null);
          }
        }
    };

    const handleChange = (e) => {
        setEmailValue(e.target.value);
        setEmailError(null);
    };

    const handleDelete = (item) => {
        setEmails((prevItems) => prevItems.filter((i) => i !== item));
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text");
        const pasteEmails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[a-zA-Z]{2,}/g);
    
        if (pasteEmails) {
          const toBeAdded = pasteEmails.filter(
            (email) => !isInList(email) && isValid(email)
          );
          if (toBeAdded.length) {
            setEmails((prevItems) => [...prevItems, ...toBeAdded]);
            setEmailError(null);
          }
        }
    };

    const isValid = (email) => {
        let errorMessage = null;
        if (isInList(email)) {
          errorMessage = `${email} has already been added.`;
        } else if (!isEmail(email)) {
          errorMessage = `${email} is not a valid email address.`;
        }
        if (errorMessage) {
          setEmailError(errorMessage);
          return false;
        }
        return true;
    };
    

    const GenerateChart = ({ imgData, displayElement }) => {
        return (
          <div className={displayElement?"table_holder table_head m0i": "table_holder table_head m0i grey"}>
            <img src={imgData} />
          </div>
        );
    };

    const GenerateTableFooter = ({chartName, totalSums}) => {
        if(chartName === 'Risk_Contribution_Table'){
            return (
                <TableRow>
                    <TableCell colSpan="3">Portfolio Total:</TableCell>
                    <TableCell>100%</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.marketContribution).toFixed(2)))).toLocaleString("en-US", currObj)}</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.idiosyncraticContribution).toFixed(2))))}</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.diversifiedRisk).toFixed(2))))}</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            )
        } else if (chartName === 'Portfolio_Details_Table'){
            return(
                <TableRow>
                    <TableCell colSpan="3">Portfolio Total:</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(portfolioDetails.TotalPortfolioValue).toFixed(2)))).toLocaleString("en-US", currObj)}</TableCell>
                    <TableCell>100%</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            )
        } else if(chartName === 'Backtest_Table'){
            return(
                <TableRow>
                    <TableCell colSpan="3">Portfolio Total:</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.totalMSShieldCost).toFixed(2)))).toLocaleString("en-US", currObj)}</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.totalMSCompoundedShieldCost).toFixed(2)))).toLocaleString("en-US", currObj)}</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.totalMSShieldPayout).toFixed(2)))).toLocaleString("en-US", currObj)}</TableCell>
                    <TableCell>{Number(addZeroes(Number(parseFloat(totalSums.totalMSCompoundedPayout).toFixed(2)))).toLocaleString("en-US", currObj)}</TableCell>
                </TableRow>
            )
        } else{
            <TableRow>
                <TableCell></TableCell>
            </TableRow>
        }
        
            
    }

    const GenerateTable = ({ tableHeader, tableData, totalSums, displayElement, chartName }) => {
        return (
            <div className={displayElement?"table_holder table_head m0i": "table_holder table_head m0i grey"}>
                <TableContainer className="table_height">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                        <TableRow>
                            {tableHeader.map((headerItem, index) => (
                                <TableCell key={index}>
                                {headerItem.header}
                                </TableCell>
                            ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((rowData, index) => (
                                <TableRow key={index}>
                                    {tableHeader.map((headerItem, index) => (
                                        <TableCell key={index}>{rowData[headerItem.accessorKey]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <GenerateTableFooter chartName={chartName} totalSums={totalSums}></GenerateTableFooter>
                    </TableFooter>
                </Table>
                </TableContainer>
            </div>
        );
    };

    const FactorTables = ({tableHeader, tableData, displayElement}) => {
        const updatedData = Object.entries(tableData).map(([key, value]) => {
            const updatedValue = Object.entries(value).reduce((acc, [k, v]) => {
              const newKey =
                k === "imp_vol"
                  ? "Implied Volatility"
                  : k === "hist_vol"
                  ? "Historical Volatility"
                  : k === "symbol"
                  ? "Symbol"
                  : k;
              if (
                newKey === "Implied Volatility" ||
                newKey === "Historical Volatility"
              ) {
                v = v === "N.A." ? "N.A." : (parseFloat(v) * 100).toFixed(2) + "%";
              }
              return { ...acc, [newKey]: v };
            }, {});
            const newValue = {
              Symbol: value.Symbol || portfolioDetails.selectedPortfolioName,
              ...updatedValue,
            };
            return { [key]: newValue };
        });
        const uniqueKeys = Array.from(
            new Set(updatedData.flatMap((obj) => Object.keys(obj[Object.keys(obj)[0]])))
        );

        return(
            <div className={displayElement?"table_height tabbody":"table_height tabbody grey"}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {tableHeader.map((header, index) => (
                            <TableCell key={index}>
                            {header}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    {tableData.length !== undefined ? (
                        <TableBody>
                            {Object.values(tableData).map(
                            (item, index) => (
                                <TableRow key={item.number}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    {item.StockTicker}
                                </TableCell>
                                <TableCell>
                                    {(
                                    parseFloat(item.Weight) * 100
                                    ).toFixed(2) + "%"}
                                </TableCell>
                                <TableCell>
                                    {item.Description}
                                </TableCell>
                                </TableRow>
                            )
                            )}
                        </TableBody>
                    ) : (
                        <TableBody>
                            {uniqueKeys.map((key, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                {key}
                                </TableCell>
                                {updatedData.map((obj, i) => {
                                    const subObj = obj[Object.keys(obj)[0]];
                                    return (
                                        <TableCell key={i}>
                                        {subObj[key]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
                <TableFooter>
                    <TableRow></TableRow>
                </TableFooter>
            </div>
        );
    }

    const SummaryTable = ({tableHeader, tableData, displayElement}) =>{
        const colOneKeys = ['MARKET VALUE HEDGEABLE ($)', 'NUMBER OF HOLDINGS','PORTFOLIO VOLATILITY (ANNUALIZED)','INDEX VOLATILITY (HISTORICAL)','BEST MATCH INDEX','R-SQUARE','BETA','TRACKING ERROR'];
        const colOneObj = tableData?Object.fromEntries(Object.entries(tableData).filter(([key]) => colOneKeys.includes(key))): {};
        const colTowObj = tableData?Object.fromEntries(Object.entries(tableData).filter(([key]) => !colOneKeys.includes(key))):{};

        return(
            <div className={displayElement?'shieldrecc two sum-container':'shieldrecc two sum-container grey'}>
                <div className='split'>
                    <div className="card-container">
                        <div className="header-card">
                            {colOneObj && Object.keys(colOneObj).map((key) =>(
                                <div>{key}</div>
                            ))}
                        </div>
                        <div key={1} className="card small">
                            {colOneObj && Object.entries(colOneObj).map(([key, value]) => (
                                <div>{value}</div>
                            ))}
                        </div>   
                    </div>
                </div>
                <div className='split'>
                    <div className="card-container">
                        <div className="header-card">
                            {colTowObj && Object.keys(colTowObj).map((key) =>(
                                <div>{key}</div>
                            ))}
                        </div>
                        <div key={1} className="card small">
                            {colTowObj && Object.entries(colTowObj).map(([key, value]) => (
                                <div>{value}</div>
                            ))}
                        </div>   
                    </div>
                </div>
            </div>
        )
    }

    const renderDynamicComponent = (componentData) => {
        const { chartName, ImgData, tableHeader, tableData, totalSums, customNote, displayElement, activeTab } = componentData;
        if(chartName.includes('Table')){
            if(['Factor_Analysis_Table_1','Factor_Analysis_Table_2'].indexOf(chartName) !== -1){
                return <FactorTables tableHeader={tableHeader} tableData={tableData} displayElement={displayElement}/>;
            }else if (['Summary_Analytics_Table_1'].indexOf(chartName) !== -1){
                return <SummaryTable tableHeader={tableHeader} tableData={tableData} displayElement={displayElement}/>
            } else{
                return <GenerateTable tableHeader={tableHeader} tableData={tableData} totalSums={totalSums} displayElement={displayElement} chartName={chartName}/>;
            }
        } else{
            return <GenerateChart imgData={ImgData} displayElement={displayElement} />;
        }
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return;
        }

        const reorderedData = Array.from(Object.entries(pdfSummaryData));
        const [movedItem] = reorderedData.splice(source.index, 1);
        reorderedData.splice(destination.index, 0, movedItem);

        dispatch(updatePdfSummaryData('',Object.fromEntries(reorderedData)));

        const reorderedNotes = {};
        const reorderedVisibleNotes = {};

        reorderedData.forEach(([key]) => {
            reorderedNotes[key] = notes[key] || '';
            reorderedVisibleNotes[key] = visibleNotes[key] || false;
        });

        setNotes(reorderedNotes);
        setVisibleNotes(reorderedVisibleNotes);
    };

    const handleDownloadReport = async () => {
        setIsGeneratingPdf(true);
        try {
            const DownloadPdf = PdfComponent({ pdfSummaryData, advisorProfile, portfolioDetails });
            await DownloadPdf();
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGeneratingPdf(false);
        }
    };
    
    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            {Object.keys(pdfSummaryData).length > 0 ? (
                <>
                {bdopen ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>
                ) : (
                    <div className="drawercontent padtop">
                        <div className="containertop actions">
                                <button class="ai-btn primary solid" onClick={handleClickOpen}><img src="./Assets/Mail-white.svg" />Email Report</button>
                                <button className='ai-btn secondary solid' onClick={() => handleDownloadReport()} disabled={isGeneratingPdf}><img src="./Assets/FileDownload.svg" />{isGeneratingPdf ? 'Generating PDF...' : 'Download Report'} </button>
                            </div>
                        
                            <Dialog open={dailogOpen} onClose={handleClose}>
                                <DialogTitle>Enter Email Ids to send PDf Report
                                    <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                                </DialogTitle>
                                <DialogContent>
                                <div>
                                    {emails.map((item) => (
                                        <div className="tag-item" key={item}>
                                        {item}
                                        <button
                                            type="button"
                                            className="ai-btn red line"
                                            onClick={() => handleDelete(item)}
                                        >
                                            <img src="./Assets/Delete-r.svg"/>
                                        </button>
                                        </div>
                                    ))}
                                    <div className="ai-form-group">
                                    <input
                                        className="ai-form-value"
                                        value={emailValue}
                                        placeholder="Type or paste email addresses and press `Enter`..."
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                        onPaste={handlePaste}
                                    />
                                    </div>
                                    {emailError && <p className="error">{emailError}</p>}
                                </div>
                                </DialogContent>
                            </Dialog>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable key="section-id" droppableId="section-id" type="REORDER">
                                    {(provided) => (
                                        <div
                                            className="pdffile"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {Object.keys(pdfSummaryData).map((sectionKey,index) => {
                                                const sectionData = pdfSummaryData[sectionKey];
                                                return(
                                                sectionData.activePortfolioId === drawerData.portfolioId ? (
                                                    <Draggable key={sectionKey} draggableId={sectionKey} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={sectionData.displayElement ? 'visible dragcontent' : 'hidden dragcontent'}
                                                            >
                                                                {visibleTitles[sectionKey]?(
                                                                    <div className="summarynote">
                                                                        <textarea
                                                                            value={titles[sectionKey] || ''}
                                                                            onChange={(e) => handleChangeTitle(sectionKey, e)}
                                                                        />
                                                                        <div className="summaryaction">
                                                                            <button
                                                                                className="ai-btn red solid"
                                                                                onClick={() => handleClearTitle(sectionKey)}
                                                                            >
                                                                                Clear
                                                                            </button>
                                                                            <button
                                                                                className="ai-btn primary solid"
                                                                                onClick={() => handleSaveTitle(sectionKey)}
                                                                            >
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ):(
                                                                    <>
                                                                    {!visibleTitles[sectionKey] && sectionData.elementTitle !== '' ? (
                                                                        <div className="blocknote">
                                                                            <p>{sectionData.elementTitle}</p>
                                                                            <div className="noteedit">
                                                                                <button
                                                                                    className="ai-btn primary solid"
                                                                                    title="Edit Custom Title"
                                                                                    onClick={() => handleEditTitle(sectionKey)}
                                                                                >
                                                                                    <img src="./Assets/Note.svg"/>
                                                                                </button>
                                                                                <button
                                                                                    className="ai-btn solid red"
                                                                                    title="Delete Custom Title"
                                                                                    onClick={() => handleDeleteTitle(sectionKey)}
                                                                                >
                                                                                    <img src="./Assets/Delete-w.svg"/>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                            className="addnote"
                                                                            onClick={() => handleAddTitleClick(sectionKey)}
                                                                            title="Add Title"
                                                                        >
                                                                            <img src="./Assets/Note-b.svg"/>  Add Title
                                                                        </button>
                                                                    )}
                                                                    </>
                                                                )}
                                                                {renderDynamicComponent(sectionData)}
                                                                <div className="block-actions">
                                                                    <button
                                                                        className="ai-btn primary solid"
                                                                        onClick={() => toggleDisplayElement(sectionData)}
                                                                    >
                                                                        <img src={sectionData.displayElement ? './Assets/Visible.svg' : './Assets/Hidden.svg'} />
                                                                    </button>
                                                                    <button
                                                                        className="ai-btn red solid"
                                                                        onClick={() => deletePdfSummaryData(sectionData)}
                                                                    >
                                                                        <img src="./Assets/Delete-w.svg" />
                                                                    </button>
                                                                </div>
                                                                {visibleNotes[sectionKey]?(
                                                                    <div className="summarynote">
                                                                        <ReactQuill
                                                                            value={notes[sectionKey] || ''}
                                                                            onChange={(value) => handleChangeNote(sectionKey, value)}
                                                                        />
                                                                        <div className="summaryaction">
                                                                            <button
                                                                                className="ai-btn red solid"
                                                                                onClick={() => handleClearNote(sectionKey)}
                                                                            >
                                                                                Clear
                                                                            </button>
                                                                            <button
                                                                                className="ai-btn primary solid"
                                                                                onClick={() => handleSaveNote(sectionKey)}
                                                                            >
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ):(
                                                                    <>
                                                                    {!visibleNotes[sectionKey] && sectionData.customNote !== '' ? (
                                                                        <div className="blocknote">
                                                                            <div className="note-content"
                                                                                dangerouslySetInnerHTML={{ __html: sectionData.customNote || '' }}
                                                                            />
                                                                            <div className="noteedit">
                                                                                <button
                                                                                className="ai-btn primary solid"
                                                                                title="Edit Custom Note"
                                                                                onClick={() => handleEditNote(sectionKey)}
                                                                                >
                                                                                <img src="./Assets/Note.svg" alt="Edit Note" />
                                                                                </button>
                                                                                <button
                                                                                className="ai-btn solid red"
                                                                                title="Delete Custom Note"
                                                                                onClick={() => handleDeleteNote(sectionKey)}
                                                                                >
                                                                                <img src="./Assets/Delete-w.svg" alt="Delete Note" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                        className="addnote"
                                                                        onClick={() => handleAddNoteClick(sectionKey)}
                                                                        title="Add Note"
                                                                        >
                                                                        <img src="./Assets/Note-b.svg" alt="Add Note" /> Add Note
                                                                        </button>
                                                                    )}
                                                                    </>

                                                                )}
                                                            </div>
                                                        )}
                                                    </Draggable>) : (
                                                        <div>
                                                            <p>No data captured</p>
                                                        </div>
                                                    )
                                                )                                                
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                    </div>
                )}
                </>
            ):(
                <div className="drawercontent">
                  <p>Please add atleast one Analytics to generate PDF Report</p>
                </div>
              )}
        </>
    )
}

export default PdfSummary;