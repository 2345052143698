// src/reducers/optimizerReducer.js

// Import action constants from optimizerActions
import {
    OPTIMIZER_REQUEST,
    OPTIMIZER_SUCCESS,
    OPTIMIZER_FAILURE
} from '../actions/optimizerActions';

// Initial state for optimizer reducer
const initialState = {
    isLoading: false,
    data: null,
    error: null
};

// Reducer function to handle optimizer actions
const optimizerReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPTIMIZER_REQUEST:
            return { ...state, isLoading: true, error: null };
        case OPTIMIZER_SUCCESS:
            return { ...state, isLoading: false, data: action.payload, error: null };
        case OPTIMIZER_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};

export default optimizerReducer;
