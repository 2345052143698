import { CheckSessionTimeout } from "./CheckSessionTimeout";
import pako from 'pako';

export async function FetchForwardTest(portfolio_id, simulation_period, protection_period, protection_level, number_of_paths) {

    const Token = sessionStorage.getItem("Token");

    const header = { Authorization: Token, 'Accept-Encoding': 'gzip' };

    var raw = JSON.stringify({
        session_id : Token,
        portfolio_id: portfolio_id,
        simulation_period: simulation_period,
        protection_period: protection_period,
        protection_level: protection_level,
        number_of_paths: number_of_paths,
    });
    console.log(" raw", raw)

    var requestOptions = {
        method: "POST",
        headers: header,
        body: raw,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetch(
                process.env.REACT_APP_PORTFOLIO_FORWARD_SIMULATION_API,
                requestOptions
            );
            const rawData = await response.text();
            const compressedData = atob(rawData)
            const uint8Array = new Uint8Array(compressedData.length);
            for (let i = 0; i < compressedData.length; i++) {
                uint8Array[i] = compressedData.charCodeAt(i);
            }
            const uncompressedData = pako.inflate(uint8Array, { to: 'string' });
            const data = JSON.parse(uncompressedData);
            if (data !== "") {
                return { status: response.status, result: data };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
