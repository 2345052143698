import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function GetAdvisorProfile() {

    const Token = sessionStorage.getItem("Token");

    const header = { Authorization: Token };

    var requestOptions = {
        method: "GET",
        headers: header,
        redirect: "follow",
    };

    if (!CheckSessionTimeout(Token)) {
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_ADVISOR_PORTFOLIO + "?session_id=" + Token,
                requestOptions
            );
            const message = await response.json();
            if (!response.ok) {
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
