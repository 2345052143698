import {
    FETCH_PORTFOLIO_DETAILS_START,
    FETCH_PORTFOLIO_DETAILS_SUCCESS,
    FETCH_PORTFOLIO_DETAILS_FAILURE,
    FETCH_PORTFOLIO_DETAILS_LOADING,
    UPDATE_PORTFOLIO_NEEDS_UPDATE
} from '../actions/portfolioDetailsActions';

const initialState = {
    portfolioDetails: {}
};

export const portfolioDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PORTFOLIO_DETAILS_START:
            return {
                ...state,
                [action.payload.portfolioId]: {
                    isLoading: true,
                    error: null,
                    details: null,
                    isEmpty: false,
                    needsUpdate: false
                },
            };
        case FETCH_PORTFOLIO_DETAILS_SUCCESS:
            return {
                ...state,
                [action.payload.portfolioId]: {
                    isLoading: false,
                    details: action.payload.details,
                    isEmpty: action.payload.isEmpty,
                    needsUpdate: false,
                    error: null
                },
            };
        case FETCH_PORTFOLIO_DETAILS_FAILURE:
            return {
                ...state,
                [action.payload.portfolioId]: {
                    ...state[action.payload.portfolioId],
                    isLoading: false,
                    error: action.payload.error,
                    needsUpdate: true
                },
            };
        case FETCH_PORTFOLIO_DETAILS_LOADING:
            return {
                ...state,
                [action.payload.portfolioId]: {
                    ...state[action.payload.portfolioId],
                    isLoading: action.payload.isLoading
                }
            };
        case UPDATE_PORTFOLIO_NEEDS_UPDATE:
            return {
                ...state,
                [action.payload.portfolioId]: {
                    ...state[action.payload.portfolioId],
                    needsUpdate: true,
                },
            };
        default:
            return state;
    }
};

export default portfolioDetailsReducer;