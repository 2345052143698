const FetchOptionChain = (portfolioData) => {
  console.log("Calling the API ", portfolioData)
    return fetch("https://iqr45ow81a.execute-api.us-east-1.amazonaws.com/dev/get-option-chain-v2", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ portfolio: portfolioData })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log("API call successful")
      return response.json();
    })
    .catch(error => {
      console.log("API call failed")
      console.error("Error fetching data:", error);
      throw error;
    });
  }
  
  export default FetchOptionChain;
  