import {fetchWithTimeout} from "./Timeout";
import {CheckSessionTimeout} from "./CheckSessionTimeout";

// TODO: Change naming wherever used
export async function ClientDepthManualAddApi(data) {
  const Token = sessionStorage.getItem("Token");

  const header = {Authorization: Token};

  var raw = JSON.stringify({
    session_id: Token,
    data: [data]
  });

  var requestOptions = {
    method: "POST",
    body: raw,
    redirect: "follow",
    headers: header,
  };

  if (!CheckSessionTimeout(Token)){
    try {
        const response = await fetchWithTimeout(
            process.env.REACT_APP_CLIENT,
            requestOptions
        );
        const message = await response.json();
        if (!response.ok) {
            return { status: response.status, error: message.error || message };
        } else {
            return { status: response.status, result: message.result };
        }
    } catch (error) {
        return { status: 0, error: error.message };
    }
} else {
    return { status: 401 };
}
}
