import moment from 'moment';

export const getNextThirdFriday = () => {
    let currentDate = moment.utc();
    let thirdFriday = currentDate.clone().startOf('month').day(5);

    if (thirdFriday.date() < 15) {
        thirdFriday.add(14, 'days');
    } else {
        thirdFriday.add(21, 'days');
    }

    if (thirdFriday.isSameOrBefore(currentDate, 'day')) {
        thirdFriday.add(1, 'month').startOf('month').day(5);
        if (thirdFriday.date() < 15) {
            thirdFriday.add(14, 'days');
        } else {
            thirdFriday.add(21, 'days');
        }
    }

    return thirdFriday.toDate();
};

export const isThirdFriday = (date) => {
    const utcDate = moment.utc(date);
    const day = utcDate.date();
    return utcDate.day() === 5 && day >= 15 && day <= 21;
};

export const filterThirdFriday = (date) => {
    return isThirdFriday(date);
};

export const getNextFriday = (date) => {
    const dayOfWeek = date.day();
    if (dayOfWeek <= 4) {
        return date.add(5 - dayOfWeek, 'days');
    } else {
        return date.add(7 - (dayOfWeek - 5), 'days');
    }
};

export const isFriday = (date) => date.getDay() === 5; 

export const getDefaultFriday = () => {
    let targetDate = moment.utc().add(2, 'months').startOf('month');
    const firstFriday = targetDate.day(5);
    if (firstFriday.month() !== targetDate.month()) {
        firstFriday.add(7, 'days');
    }
    const thirdFriday = firstFriday.add(14, 'days');

    return thirdFriday.toDate();
};