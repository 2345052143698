import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideNotification } from '../../actions/notificationActions';
import './Toaster.scss';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useHistory } from "react-router-dom";
import UserPool from "../../UserPool";
import TagManager from "react-gtm-module";
import { RESET_STATE } from '../../actions/actionTypes';

const Toaster = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const notification = useSelector(state => state.notification);
    const { message, alertType, popupType, redirectLink, loginRedirect, onSuccessRouteTo, confirmActionYes, confirmActionNo } = notification;

    useEffect(() => {
        if (popupType === 'toaster') {
            const timeout = setTimeout(() => dispatch(hideNotification()), 5000);
            return () => clearTimeout(timeout);
        }
    }, [dispatch, popupType]);

    const handleConfirmYes = () => {
        if (confirmActionYes) confirmActionYes();
        dispatch(hideNotification());
    };

    const handleConfirmNo = () => {
        if (confirmActionNo) confirmActionNo();
        dispatch(hideNotification());
    };

    const handleRedirect = () => {
        if (loginRedirect) {
            const user = UserPool.getCurrentUser();
            if (user) {
                dispatch(hideNotification());
                user.signOut();
                window.sessionStorage.clear();
                window.localStorage.clear();
                dispatch({ type: RESET_STATE });
                history.push("/");

                TagManager.dataLayer({
                    dataLayer: {
                        'event': 'User LogOut',
                        'userType': sessionStorage.getItem("selectedAcType"),
                        'user_id': sessionStorage.getItem("clientId")
                    },
                });
            }
        } else if (onSuccessRouteTo) {
            history.push(onSuccessRouteTo);
            dispatch(hideNotification());
        } else {
            dispatch(hideNotification());
        }
    };

    if (!message) return null;

    return (
        <>
            {popupType === 'toaster' && (
                <div className={`ai-toast ${alertType}`}>
                    <div className='toast-image'>
                        <img src={`./Assets/ai-${alertType}.svg`} alt={`${alertType}`} />
                    </div>
                    <div className='toast-content'>
                        <h3 className="toast-title">{alertType.toUpperCase()}</h3>
                        <div className="toast-message">{message}</div>
                    </div>
                    <a className='close' onClick={() => dispatch(hideNotification())}>-</a>
                </div>
            )}
            {popupType === 'popup' && (
                <div className="popup" id="popup">
                    <div className={alertType}>
                        <div className="popup-object">
                            <div className="heading">
                                <InfoOutlinedIcon className="icon" />
                                <span>{alertType.toUpperCase()}</span>
                            </div>
                            <div className="body-message">
                                <p>{message} {redirectLink && <a style={{ color: "Blue" }} onClick={() => history.push(redirectLink)}> (link)</a>}</p>
                            </div>
                            <button className="ai-btn primary solid small" onClick={handleRedirect}>OK</button>
                        </div>
                    </div>
                </div>
            )}
            {popupType === 'confirm' && (
                <div id="confirmationpopup" className="popup">
                    <div className="warning">
                        <div className="popup-object">
                            <div className="heading">
                                <CancelOutlinedIcon className="icon" />
                                <span>Warning</span>
                            </div>
                            <div className="body-message">
                                <p>{message}</p>
                            </div>
                            <div className="button-row">
                                <button className="ai-btn primary solid" onClick={handleConfirmYes}>Yes</button>
                                <button className="ai-btn secondary solid" onClick={handleConfirmNo}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Toaster;