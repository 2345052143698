export async function GetBacktestGraphData(portfolio_id, date1, date2, level, period,protection_model,asset_allocation,target_downside_risk) {

  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const Token = sessionStorage.getItem("Token");
  const header = { Authorization: Token };

  var raw = JSON.stringify({
    portfolio_id: portfolio_id,
    protection_period: period,
    protection_level: level,
    protection_model: protection_model,
    from_date: date1,
    to_date: date2,
    asset_allocation: asset_allocation,  
    target_downside_risk: target_downside_risk,
    session_id:Token
  });
  // console.log("raw", raw)
  var requestOptions = {
    method: "POST",
    body: raw,
    headers: header,
    redirect: "follow",
    timeout: 30000
  };

  if (d1 >= d2) {
    return false
  }
  else {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACK_TEST,
        requestOptions
      );

      if (!response.ok) {
        return false;
      }
      let text = await response.text();
      // Replace 'Infinity' and '-Infinity' with a valid number (e.g., 0)
      text = text.replace(/Infinity/g, '0').replace(/-Infinity/g, '0');
      const data = JSON.parse(text);

      console.log("grph", data);
      if (data) {
        return data;
      } else {
        return false;
      }

    } catch (error) {
      if (error.name === "AbortError") {
        //alert("The request timed out, Please try again");
        window.location.reload();
      }
      else {
        console.error(error)
        return 0
      }
    }
  }
}
