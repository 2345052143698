import OptionChainOptimizer from '../Apis/OptionChainOptimizer';

// Action Types
export const OPTIMIZER_REQUEST = 'OPTIMIZER_REQUEST';
export const OPTIMIZER_SUCCESS = 'OPTIMIZER_SUCCESS';
export const OPTIMIZER_FAILURE = 'OPTIMIZER_FAILURE';

// Action Creators
const optimizerRequest = () => ({ type: OPTIMIZER_REQUEST });

const optimizerSuccess = (data) => ({ type: OPTIMIZER_SUCCESS, payload: data });

const optimizerFailure = (error) => ({ type: OPTIMIZER_FAILURE, payload: error });

// Async Action to Fetch Optimizer Data
export const fetchOptimizer = (portfolioData, expiry, target) => async (dispatch) => {
    dispatch(optimizerRequest());

    try {
        // Call the optimizer API
        const response = await OptionChainOptimizer(portfolioData, expiry, target);

        // Ensure response is valid
        if (response && Object.keys(response).length > 0) {
            dispatch(optimizerSuccess(response)); // Dispatch success action
            return response; // Return response for further use
        } else {
            dispatch(optimizerFailure('Optimizer API returned empty data'));
            return null;
        }
    } catch (error) {
        dispatch(optimizerFailure(error.message || 'Failed to call the optimizer API'));
        return null;
    }
};
