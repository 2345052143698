import React, { useState } from "react";
import './OnboardClient.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { UploadCsvFile } from "../../Apis/OnboardImportCSV";
import { ConfirmAdvisorCSV } from "../../Apis/ConfirmAdvisorCSV";
import { useHistory } from "react-router-dom";
import Loader from '../../Components/Loader/Loader';
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from "react-gtm-module";


function AdaptiveCSVImportWizardComponent() {

    const [importStage, setImportStage] = React.useState(1);
    const [selectedFile, setSelectedFile] = React.useState();
    const [fileError, setFileError] = React.useState(null);
    const [bdopen, setBdOpen] = React.useState(false);
    const [GotData, setGotData] = React.useState(false);
    const [popupState, setPopupState] = React.useState(false);
    const [csvPopupState, setCsvPopupState] = React.useState(false);
    const [popupMessage, setPopupMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
    const history = useHistory();
    const clientPortfolios = () => history.push("/ClientPortfolios");
    const onBoardScreen = () => history.push("/OnboardScreen")
    const dispatch = useDispatch();

    const [csvData, setCsvData] = React.useState([]);
    console.log("csv", csvData)
    const csvFileBase64 = "Q2xpZW50IElELENsaWVudCBOYW1lLEFjY291bnQgSUQsQWNjb3VudCBEZXNjcmlwdGlvbixTeW1ib2wsQ3VzaXAsUXVhbnRpdHkNCkMzMjU1NixXZW5keSBCbG9nZ3MsQTEyMzQ3LEZpZGVsaXR5IElSQSxBTVpOLDIzMTM1MTA2LDEwMDANCkMzMjU1NixXZW5keSBCbG9nZ3MsQTEyMzQ3LEZpZGVsaXR5IElSQSxBQVBMLCwxMDAwDQpDMzI1NTYsV2VuZHkgQmxvZ2dzLEExMjM0NyxGaWRlbGl0eSBJUkEsTkZMWCwsMTAwMA0KQzMyNTU2LFdlbmR5IEJsb2dncyxBMTIzNDcsRmlkZWxpdHkgSVJBLFRTTEEsLDEwMDANCkMzMjU1NixXZW5keSBCbG9nZ3MsQTEyMzQ3LEZpZGVsaXR5IElSQSxNRVRBLCwxMDAwDQpDMzI1NTYsV2VuZHkgQmxvZ2dzLEExMjM0NyxGaWRlbGl0eSBJUkEsR09PR0wsLDEwMDANCkMzMjU2MixKb2UgU2NvdHQsQTkxMjM1MyxGaWRlbGl0eSBJUkEsQUFQTCwsMzcwDQpDMzI1NjIsSm9lIFNjb3R0LEE5MTIzNTMsRmlkZWxpdHkgSVJBLE5GTFgsLDEwMDANCkMzMjU2MixKb2UgU2NvdHQsQTkxMjM1NSxFbXBsb3llZSBTdG9jayxUU0xBLCw1MDA=";

    const handleFileRead = async (file) => {
        setBdOpen(true);
        const reader = new FileReader();
        reader.onloadend = (onLoadEndEvent) => {
            UploadCsvFile(onLoadEndEvent.target.result.split(",")[1]).then((data) => {
                if (data.status === 200) {
                    console.log("response", data);
                    var lyst = []
                    for (let [key, value] of Object.entries(data.result)) {
                        var plist = []
                        for (let [k, v] of Object.entries(value.portfolios)) {
                            plist.push({
                                portfolio_name: v.portfolio_name,
                                pli: v.line_items,
                                account_id: v.account_id,
                                total_market_value: v["total _portfolio_value"]
                            })
                        }
                        lyst.push({
                            id: key,
                            client_name: value.full_name,
                            portfolio: plist,
                            external_id: value.external_id
                        })
                    }

                    setCsvData([...lyst]);
                    setBdOpen(false);
                    setImportStage(2);
                } else{
                    handleApiError(data, dispatch);
                }
            }).finally(()=>{setBdOpen(false)});
        };
        reader.readAsDataURL(file);
    };

    function downloadCSVFile(e) {
        e.preventDefault();
        const element = document.createElement("a");
        const file = new Blob([atob(csvFileBase64)], { type: "text/csv;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = "SampleTemplate.csv";
        document.body.appendChild(element);
        element.click();
    }

    const onDrop = React.useCallback((event) => {
        setBdOpen(true);
        event.preventDefault();
        if (event.dataTransfer.items) {
            let file = event.dataTransfer.items[0].getAsFile();
            var fileExt = file.name.split('.').pop();
            var fileExt = file.name.split('.').pop();
            var fileExt = file.name.split('.').pop();
            if (fileExt !== "csv") {
                setFileError('File format is not recognized. Please upload a valid CSV file');
                setBdOpen(false);
                return;
            }
            handleFileRead(file);
            setSelectedFile(file);
            setFileError(null);
        }
    }, []);

    const onFileChange = (event) => {
        setBdOpen(true);
        const [file] = event.target.files;
        var fileExt = file.name.split('.').pop();
        if (fileExt !== "csv") {
            setFileError('File format is not recognized. Please upload a valid CSV file');
            setBdOpen(false);
            return;
        }
        handleFileRead(file);
        setSelectedFile(file);
        setFileError(null);
    };
    // Helper function to parse CSV

    // import React, { useState } from 'react';

    function CollapsibleTable({ data }) {
        return (
            <>
                <div className="customer-portfolio">
                    {data.map((item, idx) => (
                        <div key={idx} className="customer">
                            <div className='portfoliohead'>
                                <h2><p>{item['external_id']}</p>
                                    <p>{item['client_name']}</p></h2>
                            </div>
                            <div className='portfolios'>
                                <DetailsTable portfolios={item.portfolio} />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    function DetailsTable({ portfolios }) {
        const [filter, setFilter] = useState('recognized');  // 'all', 'recognized', 'unrecognized'

        return (
            <>
                <div class="asset-btn">
                    {/* <button onClick={() => setFilter('all')}>All</button> */}
                    <button className="" onClick={() => setFilter('recognized')}>Recognized</button>
                    <button className="" onClick={() => setFilter('unrecognized')}>Unrecognized</button>
                </div>
                {portfolios.map((portfolio, idx) => (
                    <PortfolioTable key={idx} portfolio={portfolio} filter={filter} />
                ))}
            </>
        );
    }
    const confirmCSVData = () => {
        setBdOpen(true);
        ConfirmAdvisorCSV().then((response) => {
            setImportStage(3);
            TagManager.dataLayer({
                dataLayer: {
                  event: 'Onboard Client Portfolio',
                  userType: sessionStorage.getItem("selectedAcType"),
                  user_id: sessionStorage.getItem("clientId"),
                  onboarding_type: 'CSV Import'
                },
              });
            console.log("complete", response);
        }).finally(() => { setBdOpen(false) })
    }
    function PortfolioTable({ portfolio, filter }) {
        const [expanded, setExpanded] = useState(false);

        const filteredLineItems = portfolio['pli'].filter(item => {
            if (filter === 'all') return true;
            if (filter === 'recognized') return item.recognized;
            if (filter === 'unrecognized') return !item.recognized;
        });

        return (

            <div className='portfoliocard'>
                <div className='portlist'>
                    <div className='listhead'>
                        <h2>{portfolio['portfolio_name']} - {portfolio['account_id']} - {Number(portfolio['total_market_value']).toFixed(2)}</h2>
                        <button onClick={() => setExpanded(!expanded)}><img src="Assets/c_down.svg" alt="toggle" /></button>
                    </div>
                </div>
                {expanded && (
                    <div className='clientdata'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Symbol</th>
                                    <th>Quantity</th>
                                    <th>Close Price</th>
                                    <th>Market Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredLineItems.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>{item.symbol}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item['close_price']}</td>
                                        <td>{Number(item['market_value']).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    }

    // export default CollapsibleTable;


    console.log(JSON.stringify(csvData))
    return (<>
        <div className="toast-container">
            <Toaster />
        </div>
        {bdopen ? (
            <div className="loading-overlay">
                <Loader/>
            </div>) : (
            <>
                <h1 className="title">Adaptive Import Wizard</h1>
                <div className="onboarding">
                    <div className="onboardstep">
                        <div className="icon"><img className="" src='./Assets/Wave.svg'></img></div>
                        <p className="round_title_text">Import CSV</p>
                    </div>
                    <div className="onboardstep">
                        <div className="icon"><img className="" src='./Assets/BankConnect.svg'></img></div>
                        <p className="round_title_text">Verify Import</p>
                    </div>
                    <div className="onboardstep">
                        <div className="icon"><img className="" src='./Assets/Docs.svg'></img></div>
                        <p className="round_title_text">Complete</p>
                    </div>
                </div>
                {importStage === 1 &&
                    (
                        <div className="welcome">
                            <div className="welcomecon">
                                <button className="ai-btn line primary" onClick={(e) => downloadCSVFile(e)}>
                                    Download Sample CSV <ArrowForwardIosIcon />
                                </button>
                                <div
                                    className="upload-section"
                                    id="upload-section"
                                    onDrop={onDrop}
                                    onDragOver={(event) => event.preventDefault()}
                                >
                                    <input
                                        type="file"
                                        id="file-upload"
                                        accept=".csv"
                                        onChange={onFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div class="text-container">
                                        <label className="full-label" htmlFor="file-upload">
                                            <img src="./Assets/documents-file-excel.svg" alt="Click here to Upload Csv or Drag & Drop file here" />
                                            <br></br>
                                            <p> Click here to Upload CSV or Drag & Drop file here ...
                                                {/* {selectedFile && <span className='c-secondary'>{selectedFile.name}</span>} */}
                                            </p>
                                        </label>
                                        {fileError && <p className="file-error" style={{color: 'red'}}>{fileError}</p>}
                                        {/* {fileError && <p className="file-error">{fileError}</p>} */}
                                    </div>
                                </div>
                            </div>
                            <div className="button-div">
                                <button className="ai-btn line grey" onClick={() => onBoardScreen()}>
                                    Previous <ArrowBackIosNewIcon />
                                </button>
                                <button className="ai-btn line primary" onClick={() => setImportStage(2)}>
                                    Next <ArrowForwardIosIcon />
                                </button>
                                <button className="ai-btn solid secondary" onClick={() => onBoardScreen()}>
                                    Exit 
                                </button>
                            </div>
                        </div>

                    )
                }
                {importStage === 2 &&
                    (
                        <div className="welcome">
                            <div className="welcomecon">
                                {/* <div className="toogle-switch">
                                <h2>Hedgeable</h2>
                                <FormControlLabel control={<Switch defaultChecked />} />
                                <h2>Unhedgeable</h2>
                            </div> */}
                                <div className="importtable" clienttable>
                                    <CollapsibleTable data={csvData} />
                                </div>
                            </div>
                            <div className="button-div">
                                <button className="ai-btn line grey" onClick={() => setImportStage(1)}>
                                    Back
                                </button>
                                <button className="ai-btn line primary" onClick={() => confirmCSVData()}>
                                    Import
                                </button>
                                <button className="ai-btn solid secondary" onClick={() => onBoardScreen()}>
                                    Exit
                                </button>
                            </div>
                        </div>
                    )}

                {importStage === 3 &&
                    (
                        <div className="welcome">
                            <img src="./Assets/Single.png"></img>
                            <h2>
                                Portfolio successfully imported.
                            </h2>
                            <div className="button-div">
                                {/* <button className="ai-btn line primary" onClick={() => setImportStage(1)}>
                                    Upload Another
                                </button> */}
                                <button className="ai-btn solid secondary" onClick={() => clientPortfolios()}>
                                    Go to  Dashboard
                                </button>
                            </div>
                        </div>
                    )}
            </>)}
    </>
    );
}

export default AdaptiveCSVImportWizardComponent;
