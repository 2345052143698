import React, { useState } from 'react';
import Loader from '../../Components/Loader/Loader';
import './ClonePortfolio.css';
import { PostClonePortfolioApi } from '../../Apis/PostClonePortfolio';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import { portfolioAdded } from '../../actions/portfolioActions';
import { addZeroes } from "../../Utilities/AddZeros";
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from "react-gtm-module";
import { togglePortfolioSelection } from '../../actions/portfolioActions';

const ClonePortfolio = () => {
    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioValue, setPortfolioValue] = useState('');
    const [formatPortfolioValue, setFormatPortfolioValue] = useState('');
    const [selectedClients, setSelectedClients] = useState({});
    const [searchClients, setSearchClients] = useState('');
    const [clients , setClients] = useState([]);

    const [cFlag ,setCFlag] =useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [clonePortfolioErrors,setClonePortfolioErrors] = React.useState([]);

    const drawerData = useSelector(state => state.drawer.data);
    const portfolioId = drawerData.portfolioId;
    const portfolio = drawerData.portfolio;
    const clientName = drawerData.client;

    if(cFlag){
        if(drawerData.customers !== ""  && drawerData.customers.length > 0){
            let lyst = []
            for (let [key, val] of Object.entries(drawerData.customers)) {
                lyst.push({
                    'CustomerId':val.CustomerId,
                    'client_name': val.FirstName+' '+val.LastName,
                    'client_username': val.Username
            })}
            lyst  = new Set(lyst);
            setClients(Array.from(lyst));
            setCFlag(false);
        }
    }

    const handleSelect = (CustomerId) => {
        setSelectedClients(prevState => ({
            ...prevState,
            [CustomerId]: !prevState[CustomerId]
        }));
    };

    function changeValue(event, value) {
        let errors = {};
        let trimmedValue = value.trim().replace(/[^0-9.]/g, '');
        if (event.key === "Enter") {
            let float_value = parseFloat(trimmedValue);
            if (isNaN(float_value) || trimmedValue === "") {
                errors.portfolioValue = "Please enter a valid value to proceed further.";
                return;
            }
            if (float_value === 0) {
                errors.portfolioValue = "Value cannot be zero. Please enter a valid value.";
                return;
            }
            
            setFormatPortfolioValue(float_value);
            setPortfolioValue((Number(
                addZeroes(
                    Number(float_value.toFixed(2))
                )
            ).toLocaleString("en-US", currObj)));
        }
        setClonePortfolioErrors(errors);
    }

    function handleChangeValue(event, value) {
        let text = event.target.value.replace(/[^\d\.]/gi, '');
        if ((text.split('.').length - 1) > 1) {
            return;
        }

        let lastCharIsAdot = text.substr(text.length - 1, 1) === ".";

        if (text === "0" || text === "." || isNaN(text)) {
            return;
        } else if (event.target.value === "") {
            setPortfolioValue("");
        } else {
            if (lastCharIsAdot) event.target.value += ".";
            setPortfolioValue(Number(text).toLocaleString("en-US"));
            let float_value = parseFloat(event.target.value.replace(/,/g, ''));
            setFormatPortfolioValue(float_value);
        }
    }

    const validationCheckClonePortfolio = () =>{
        let validFlag = true;
        let errors = {};
        
        if(!portfolioName){
            validFlag = false;
            errors.portfolioName = "Please enter a valid portfolio name";
        }
        if(!portfolioValue){
            validFlag = false;
            errors.portfolioValue = "Please enter a valid portfolio value";
        }

        if(Object.keys(selectedClients).length === 0){
            validFlag = false;
            errors.selectedClients = "Please select a client";
        }
        if(Object.keys(selectedClients).length > 0){
            let truCount = 0;
            for (let [key, val] of Object.entries(selectedClients)){
                if(val){
                    truCount = truCount+1
                }
            }
            if(truCount === 0){
                validFlag = false;
                errors.selectedClients = "Please select atleast one client to clone portfolio"
            }
        }
        setClonePortfolioErrors(errors);
        return validFlag;
    }

    const postClonePortfolio = async () =>{
        let lyst = [];
        for (const [k, v] of Object.entries(selectedClients)) {
            if (v === true) {
                let clientusername = clients.filter((client) => client.CustomerId.toString() === k).map((row) => row.client_username).toString()
                lyst.push(clientusername);
            }
        }
        if(validationCheckClonePortfolio()){
            setIsLoading(true);
            const response =  await PostClonePortfolioApi(portfolioId,portfolioName,lyst,formatPortfolioValue);
            if (response.status === 200) {
                dispatch(portfolioAdded());
                dispatch(closeDrawer());
                TagManager.dataLayer({
                    dataLayer: {
                      event: 'Portfolio Cloned',
                      userType: sessionStorage.getItem("selectedAcType"),
                      user_id: sessionStorage.getItem("clientId")
                    },
                  });
                  
            } else {
                handleApiError(response, dispatch);
            }
            setIsLoading(false)
        }
    }

    const handleCloseDrawer = (portfolioId) => {
        dispatch(togglePortfolioSelection(portfolioId));
        dispatch(closeDrawer());
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawer">
            {isLoading ? (
                <div className="loading-overlay">
                    <Loader/>
                </div>) : (
                <>
                    <div className="drawer-header">
                        <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                        <h1>Choose the model portfolio you'd like to replicate.</h1>
                        <div className="actions">
                        <button className="close-drawer" onClick={() => handleCloseDrawer(drawerData.portfolioId)}>✕</button>
                        </div>
                    </div>

                    <div className='drawercontent'>
                        <div className="inputs">
                            <div>
                                <label>Portfolio Name</label>
                                <input type="text" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} placeholder='Portfolio Name' />
                                <p className='ai-error-message'>{clonePortfolioErrors.portfolioName}</p>
                            </div>
                            <div>
                                <label>Portfolio Value</label>
                                <input type="text" value={portfolioValue} 
                                    onChange={handleChangeValue} 
                                    onKeyUp={(event) => changeValue(event, event.target.value)} 
                                    placeholder='Portfolio Value' />
                                <p className='ai-error-message'>{clonePortfolioErrors.portfolioValue}</p>
                            </div>
                        </div>

                        <div className='portfolioname'>
                            <div className="selected-portfolios">
                                <div key={`${clientName} - ${portfolio}`} className="portfolio-entry">
                                    <p>Selected Portfolio : <span className="client-name">{clientName}</span> <span className="portfolio-name">{portfolio}</span></p>
                                </div>
                            </div>
                        </div> 

                        <div className='inputs'>
                            <input
                                type="text"
                                placeholder="Search clients"
                                value={searchClients}
                                onChange={e => setSearchClients(e.target.value)}
                            />
                        </div>
                        
                        <div className='inputs'>
                            <div>
                                <ul className="portfolio-list">
                                    {clients.filter((client) =>{
                                        if (searchClients === "") {
                                            return client;
                                        } else if (
                                            client.client_name.toLowerCase().includes(searchClients.toLowerCase())
                                        ) {
                                            return client;
                                        }
                                    }).map((c) => (
                                        <div>
                                            <li key={c.CustomerId}>
                                                {c.client_name}
                                                <button
                                                    className={selectedClients[c.CustomerId] ? 'selected' : ''}
                                                    onClick={() => handleSelect(c.CustomerId)}
                                                >
                                                    {selectedClients[c.CustomerId] ? '✓ Selected' : 'Select'}
                                                </button>
                                            </li>
                                        </div>
                                    ))}
                                    <p className='ai-error-message'>{clonePortfolioErrors.selectedClients}</p>
                                </ul>
                            </div>
                        </div>
                        <div className="drawerbottom actions">
                            <button className='ai-btn solid blue' onClick={() => dispatch(closeDrawer())}>X Close</button>
                            <button className='actions ai-btn solid primary' onClick={postClonePortfolio}>Clone & Save</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    </>
    );
};

export default ClonePortfolio;