import React from "react";
import Navbaruserprofile from "../../Components/Navbaruserprofile/Navbaruserprofile";
import AdvisorProfile from '../../Components/AdvisorProfile/AdvisorProfile';

function AdvisorProfileScreen() {

    return (
        <>
            <Navbaruserprofile />
            <div className="container">
                <main className="profile-content">
                    <AdvisorProfile />
                </main>
            </div>
        </>
    );
}

export default AdvisorProfileScreen;
