import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';
import { toJpeg, toPng } from 'html-to-image';
import { useDispatch, useSelector } from "react-redux";
import { updatePdfSummaryData } from "../../actions/pdfSummaryActions";
import { pdfSummaryNotification } from "../../actions/notificationActions";

function CaptureButtons(props){
    const dispatch = useDispatch();
    const pdfSummaryData = useSelector(state => state.pdfSummary);
    const drawerData = useSelector(state => state.drawer.data);

    const downloadGraph = () => {
        const block = document.getElementById(props.id)
        toJpeg(block, { quality: 1.0 , backgroundColor:'white'})
            .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = props.chartName+'.jpeg';
            link.href = dataUrl;
            link.click();
        });
    }

    const captureGraphToPdf = async () => {
        if(pdfSummaryData && props.chartName in pdfSummaryData && drawerData.portfolioId === pdfSummaryData[props.chartName].activePortfolioId){
            dispatch(pdfSummaryNotification("Analytics Details for this element are already captured for PDF Summary",'info'))
        } else {
            let pdf_summary = {};
            if(props.elementType === 'chart'){
                const image = await toPng(document.getElementById(props.id));
                pdf_summary = {
                    activeTab: props.activeTab,
                    ImgData: image,
                    chartName: props.chartName,
                    userSelectedData: props.userSelectedData,
                    displayElement:true,
                    customNote:'',
                    activePortfolioId:drawerData.portfolioId,
                    elementTitle:props.activeTab
                };
            }
            else{
                pdf_summary = {
                    activeTab: props.activeTab,
                    tableHeader:props.tableHeaders,
                    tableData:props.tableData,
                    chartName:props.chartName,
                    userSelectedData:props.userSelectedData,
                    displayElement: true,
                    totalSums:props.totalSums,
                    customNote:'',
                    activePortfolioId:drawerData.portfolioId,
                    elementTitle:props.activeTab
                };
            }
            dispatch(updatePdfSummaryData(props.chartName, pdf_summary));
            dispatch(pdfSummaryNotification("Analytics Details captured successfully",'success'))
        }
    }

    return(
        <>
            {props.elementType ==='chart'?(
                <div className='sumbtn'>
                    <button className="ai-btn primary pdf line plain" onClick={() => captureGraphToPdf()} title="Add to PDF Report"><img src="./Assets/Addpdf.svg" /></button>
                    <button className="ai-btn primary line image plain" onClick={() => downloadGraph()}  title="Download As Image"><img src="./Assets/image-download.svg" /></button>
                </div>
            ):(
                <div className='sumbtn'>
                    <button className="ai-btn primary pdf line plain" onClick={() => captureGraphToPdf()} title="Add To PDF"><img src="./Assets/Addpdf.svg" /></button>
                </div>
            )}
        </>
    )
}
export default CaptureButtons