import React from "react";
import SubscriptionVerification from "../../Components/SubscriptionVerification/SubscriptionVerification";
// import './OnBoardClientPortfolioScreen.css';

function VerificationScreen() {

    return (
        <>
        <main className="onboard_wrap">
            <SubscriptionVerification/>
        </main>
        </>
    );
}

export default VerificationScreen;
