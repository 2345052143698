import React from 'react';
import './Loader.scss';

const Loader = ({ size = 'large' }) => {
  // Define size mappings based on the size prop
  const loaderSize = size === 'small' ? '16px' : '100px'; // 16px for small, 40px for large by default

  return (
    <div className="loader" style={{ width: loaderSize, height: loaderSize }}>
      <svg className="loader-svg" viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "#60034c", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#e58b76", stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <circle className="loader-circle" cx="50" cy="50" r="45" strokeLinecap="round" /> {/* Keep radius same, will scale with size */}
      </svg>
    </div>
  );
};

export default Loader;
