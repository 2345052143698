import FetchOptionChain from "../Apis/FetchOptionChain";
import FetchOptionChainPrices from "../Apis/FetchOptionChainPrices";
import { handleApiError } from "./notificationActions";
import { resetAllDashboards } from "./selectionActions";

export const setOptionsData = (data, portfolioId, detailsHash) => ({
  type: 'SET_OPTIONS_DATA',
  payload: { data, portfolioId, detailsHash },
});

export const setLoading = (isLoading) => ({
  type: 'SET_LOADING',
  payload: isLoading,
});

export const setError = (error) => ({
  type: 'SET_ERROR',
  payload: error,
});


function addMonthsToDate(date, months) {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
}

const calculateTotalPremiumAndCost = (closestOptions, portfolioData) => {
  let totalPremium = 0;
  let totalCost = 0;

  Object.entries(closestOptions.calls).forEach(([ticker, callOption]) => {
    const bidPrice = parseFloat(callOption.CloseBidPrice);
    const portfolioItem = portfolioData.find(item => item.ticker === ticker);
    const quantityMultiplier = portfolioItem ? Math.floor(portfolioItem.quantity / 100) : 0;
    totalPremium += (bidPrice * quantityMultiplier * 100);
  });

  // Object.entries(closestOptions.puts).forEach(([ticker, putOption]) => {
  //   const askPrice = parseFloat(putOption.CloseAskPrice);
  //   const portfolioItem = portfolioData.find(item => item.ticker === ticker);
  //   const quantityMultiplier = portfolioItem ? Math.floor(portfolioItem.quantity / 100) : 0;
  //   totalCost += (askPrice * quantityMultiplier * 100);
  // });
  sessionStorage.setItem('totalPremium', JSON.stringify(totalPremium));
  sessionStorage.setItem('totalCost', JSON.stringify(totalCost));
};


function chunkArray(array, size) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
}

const parseDate = (dateStr) => new Date(dateStr);


export const fetchOptionsDataIfNeeded = (portfolioData, portfolioId) => async (dispatch, getState) => {
  console.log(" in fetch option", portfolioData)
  const { options } = getState();

  const detailsHash = JSON.stringify(portfolioData);

  if (!options.isLoading) {
    dispatch(resetAllDashboards());
    const storedOptimizerPortfolioId = parseInt(sessionStorage.getItem('optimizerPortfolioId'));
    const isSamePortfolio = storedOptimizerPortfolioId === portfolioId;
    console.log("storedOptimizerPortfolioId", storedOptimizerPortfolioId, portfolioId, isSamePortfolio)
    // If different portfolio, clear optimizer data
    if (!isSamePortfolio) {
      sessionStorage.removeItem('optimizerData');
      sessionStorage.removeItem('optimizedPremium');
      sessionStorage.removeItem('optimizerPortfolioId');
    }
    sessionStorage.removeItem('callWritingCartItems');
    sessionStorage.removeItem('putBuyingCartItems');
    sessionStorage.removeItem('existingOptionHoldings');
    sessionStorage.removeItem('selectedRecommendations');
    dispatch(setLoading(true));
    const portfolioChunks = chunkArray(portfolioData, 100);
    console.log("portfolioData", portfolioData)
    console.log("portfolioChunks", portfolioChunks)

    let combinedPricedData = {
      C: {},
      P: {}
    };

    await Promise.allSettled(portfolioChunks.map(async (chunk) => {
      try {
        // const optionsData = await FetchOptionChain(chunk);
        // console.log("optionsData", optionsData)
        // const pricedData = await FetchOptionChainPrices(optionsData).catch(err => {
        //   console.error("Error fetching prices for options data:", err);
        //   return { C: {}, P: {} }; // Return empty structure in case of error
        // });

        const optionsData = await FetchOptionChain(chunk);
        const optionsChunks = chunkArray(Object.keys(optionsData), 20);

        for (const tickers of optionsChunks) {
          const chunkedData = tickers.reduce((acc, ticker) => {
            acc[ticker] = optionsData[ticker];
            return acc;
          }, {});

          const pricedData = await FetchOptionChainPrices(chunkedData).catch(err => {
            console.error("Error fetching prices for options data:", err);
            return { C: {}, P: {} };
          });
        // Combine priced data for calls and puts separately
        ['C', 'P'].forEach(type => {
          Object.keys(pricedData[type]).forEach(stock => {
            if (!combinedPricedData[type][stock]) {
              combinedPricedData[type][stock] = [];
            }
            combinedPricedData[type][stock].push(...pricedData[type][stock]);
          });
        });
        }
      } catch (error) {
        console.error("Error fetching options data:", error);
      }
    }));

    console.log("combinedPricedData", combinedPricedData, portfolioData);

    const now = new Date();
    const oneMonthFromNow = addMonthsToDate(now, 1);
    const findClosestOption = (options, oneMonthFromNow, targetMultiplier) => {
      let bestOption = null;
      // const targetDate = parseDate(oneMonthFromNow);
      //subtract 1 day from one month from now
      const targetDate = new Date(oneMonthFromNow - 1);
      const sortedOptions = options.sort((a, b) => {
        const dateDiffA = Math.abs(parseDate(a.ExpirationDate) - targetDate);
        const dateDiffB = Math.abs(parseDate(b.ExpirationDate) - targetDate);
  
        if (dateDiffA !== dateDiffB) {
          return dateDiffA - dateDiffB;
        } else {
          const strikeDiffA = Math.abs(a.Strike - (a.ClosePrice * targetMultiplier));
          const strikeDiffB = Math.abs(b.Strike - (b.ClosePrice * targetMultiplier));
          return strikeDiffA - strikeDiffB;
        }
      });
      bestOption = sortedOptions[0];
      return bestOption;
    };
    

    const processOptions = (optionsData, isCall) => {
      const oneMonthFromNow = new Date();
      oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

      const targetMultiplier = isCall ? 1.10 : 0.90;

      return Object.keys(optionsData).reduce((acc, ticker) => {
        const options = optionsData[ticker];
        const closestOption = findClosestOption(options, oneMonthFromNow, targetMultiplier);
        if (closestOption) {
          acc[ticker] = closestOption;
        }
        console.log("acc", acc);
        return acc;
      }, {});
    };

    // Process calls and puts with the updated logic
    const closestOptions = {
      calls: processOptions(combinedPricedData.C, true),
      puts: processOptions(combinedPricedData.P, false)
    };

    calculateTotalPremiumAndCost(closestOptions, portfolioData);


    try {
      // Dispatch the combinedPricedData with its structure preserved
      dispatch(setOptionsData(combinedPricedData, portfolioId, detailsHash));
    } catch (dispatchError) {
      console.error("Error dispatching priced options data:", dispatchError);
      dispatch(setError(dispatchError.toString()));
    } finally {
      dispatch(resetAllDashboards());  
      dispatch(setLoading(false));
    }
  }
};