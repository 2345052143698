import React, { useState, useRef } from 'react';
import Loader from '../../Components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsDrawer from "../../Components/AnalyticsDrawer/AnalyticsDrawer";
import { openDrawer } from '../../actions/drawerActions';
import { toggleClientSelection, togglePortfolioSelection, selectPortfolio, selectClient } from '../../actions/portfolioActions';
import { fetchPortfolioDetailsIfNeeded ,fetchPortfolioDetails} from '../../actions/portfolioDetailsActions';
import AddClientDrawer from "../AddClientDrawer/AddClientDrawer";
import AddPortfolioDrawer from '../AddPortfolioDrawer/AddPorfolioDrawer';
import ClonePortfolio from '../ClonePortfolio/ClonePortfolio';
import EditPortfolio from '../EditPortfolio/EditPortfolio';
import ModalPortfolio from '../ModalPortfolio/ModalPortfolio';
import RecommendationDrawer from '../RecommendationDrawer/RecommendationDrawer';
import ImportCSVPortfolioDrawer from '../ImportCSVPortfolioDrawer/ImportCSVPortfolioDrawer';
import './ADSidebar.scss';
import { UpdateClient } from '../../Apis/UpdateClient';
import { DeleteClient } from '../../Apis/DeleteClient';
import { fetchPortfolio } from '../../actions/portfolioActions';
import { handleApiError, showConfirmNotification } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from "react-gtm-module";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { clearPdfSummaryData } from '../../actions/pdfSummaryActions';

const DRAWER_COMPONENTS = {
    AddClientDrawer: AddClientDrawer,
    AddNewPortfolio: AddPortfolioDrawer,
    Recommendation: RecommendationDrawer,
    ClonePortfolio: ClonePortfolio,
    ModalPortfolio: ModalPortfolio,
    EditPortfolio: EditPortfolio,
    Analytics: AnalyticsDrawer,
    ImportCSVPortfolioDrawer: ImportCSVPortfolioDrawer
};

function Sidebar() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [addClientDrawerShow, setAddClientDrawerShow] = useState(false);
    const { customers, selectedPortfolioIds, selectedClientIds ,selectedScrollId, activePortfolio, activeClient} = useSelector(state => state.portfolio);
    const activeDrawer = useSelector(state => state.drawer.activeDrawer);
    const DrawerComponent = DRAWER_COMPONENTS[activeDrawer];
    const [editClientStage, setEditClientStage] = useState(1);
    const [editClientName, setEditClientName] = useState("");
    const [editClientExternalId, setEditClientExternalId] = useState("");
    const [editClientErrors, setEditClientErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [deleteClientId, setDeleteClientId] = React.useState();
    const [openStates, setOpenStates] = useState({});
    const [pendingDrawer, setPendingDrawer] = useState(null);
    const [loading, setLoading] = useState({});
    const portfolioDetailsState = useSelector(state => state.portfolioDetails);

    const modalRef = useRef();
    const handleCheckboxChange = (portfolio, customer) => {
        dispatch(togglePortfolioSelection(portfolio.PortfolioId));
        dispatch(fetchPortfolioDetailsIfNeeded(portfolio.PortfolioId));
        dispatch(selectClient(customer.CustomerId));
        dispatch(clearPdfSummaryData(portfolio.PortfolioId))
        //dispatch(selectPortfolio(portfolio.PortfolioId,'drawer'));
        //dispatch(toggleClientSelection(customerId,'Click'));
        setOpenStates(prev => ({
            ...prev,
            [portfolio.PortfolioId]: !prev[portfolio.PortfolioId]
        }));
        if(activeDrawer !== null){
            openDrawerWithCheck(portfolio,customer)
        }
    };

    React.useEffect(() => {
        if (pendingDrawer) {
            const details = portfolioDetailsState[pendingDrawer.portfolioId];
            if (details && !details.isLoading && !details.needsUpdate) {
                const isEmpty = !(details.details?.PortfolioLineItem?.Hedgeable?.length > 0);
                dispatch(openDrawer(pendingDrawer.type, {
                    portfolioId: pendingDrawer.portfolioId,
                    Name: pendingDrawer.name,
                    portfolio: pendingDrawer.portfolioName,
                    isEmpty: isEmpty
                }));
                setPendingDrawer(null);
            }
        }
    }, [portfolioDetailsState, pendingDrawer, dispatch]);

    const openDrawerWithCheck = (portfolio, customer) => {
        const loadingKey = `${portfolio.PortfolioId}-${activeDrawer}`;
        const details = portfolioDetailsState[portfolio.PortfolioId];
    
        const startLoading = () => setLoading(prev => ({ ...prev, [loadingKey]: true }));
        const stopLoading = () => setLoading(prev => ({ ...prev, [loadingKey]: false }));
        
        if (details === undefined) {
            startLoading();
            dispatch(fetchPortfolioDetails(portfolio.PortfolioId))
                .finally(stopLoading());
            setPendingDrawer({
                type: activeDrawer,
                portfolioId: portfolio.PortfolioId,
                name: `${customer.FirstName} ${customer.LastName}`,
                portfolioName: portfolio.PortfolioName
            });
        } else {
            const isEmpty = !(details.details?.PortfolioLineItem?.Hedgeable?.length > 0);
            dispatch(openDrawer(activeDrawer, {
                portfolioId: portfolio.PortfolioId,
                Name: `${customer.FirstName} ${customer.LastName}`,
                portfolio: portfolio.PortfolioName,
                isEmpty: isEmpty
            }));
        }
    };


    const handleAddClientToggle = () => {
        setAddClientDrawerShow(!addClientDrawerShow);
    };

    const [showModal, setShowModal] = useState({
        visible: false,
        clientUsername: null,
        customerId: null,
        position: { x: 0, y: 0 }
    });

    const handleOpenModal = (e, client) => {
        setShowModal({
            visible: true,
            clientUsername: client.Username,
            customerId: client.CustomerId,
            position: { x: e.clientX, y: e.clientY }
        });
    };

    const handleCloseModal = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setShowModal({ ...showModal, visible: false });
        }
    };

    const closeModal = () => {
        setShowModal({ ...showModal, visible: false });
    };

    const toggleCollapsible = (clientUsername,CustomerId) => {
        setOpenStates(prevStates => ({
            ...prevStates,
            [clientUsername]: !prevStates[clientUsername]
        }));
        dispatch(toggleClientSelection(CustomerId,'toggle'));
    };

    React.useEffect(() => {
        const updatedOpenStates = { ...openStates };
        customers.forEach(customer => {
            if (!(customer.Username in updatedOpenStates)) {
                updatedOpenStates[customer.Username] = false;
            }
        });
        setOpenStates(updatedOpenStates);
    }, [customers]);

    React.useEffect(() => {
        document.addEventListener('mousedown', handleCloseModal);
        return () => {
            document.removeEventListener('mousedown', handleCloseModal);
        };
    }, []);

    const validEditClientForm = () => {
        let validFlag = true;
        let error = [];
        if (!editClientName) {
            validFlag = false;
            error.editClientName = "Please enter a client name";
        }
        // if (!editClientExternalId) {
        //     validFlag = false;
        //     error.editClientExternalId = "Please enter a client external id"
        // }
        setEditClientErrors(error);
        return validFlag
    }

    const [showClientModal, setShowClientModal] = useState({
        visible: false,
        clientUsername: null,
        clientExternalID: null,
        customerId: null,
        position: { x: 0, y: 0 }
    });

    const handleClientOpenModal = (e, client) => {
        setShowClientModal({
            visible: true,
            clientUsername: client.Username,
            clientExternalID: client.ExternalId,
            customerId: client.CustomerId,
            position: { x: e.clientX, y: e.clientY }
        });
        setEditClientName(client.FirstName + ' ' + client.LastName);
        setEditClientExternalId(client.ExternalId);
    };

    const handleClientCloseModal = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setShowClientModal({ ...showClientModal, visible: false });
            setEditClientStage(1);
            setEditClientName("");
            setEditClientExternalId("");
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClientCloseModal);
        return () => {
            document.removeEventListener('mousedown', handleClientCloseModal);
        };
    }, []);

    const handleCloseEditButton = () => {
        setShowClientModal({ ...showClientModal, visible: false });
        setEditClientStage(1);
        setEditClientName("");
        setEditClientExternalId("");
    }

    const handleEditClient = async (customer_id) => {
        setIsLoading(true);
        if (validEditClientForm()) {
            const response = await UpdateClient(customer_id, editClientName, editClientExternalId);
            if (response.status === 200) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'Update Client Details',
                        userType: sessionStorage.getItem("selectedAcType"),
                        user_id: sessionStorage.getItem("clientId")
                    },
                });
                dispatch(fetchPortfolio());
                handleCloseEditButton();
            } else {
                handleApiError(response, dispatch);
            }
        }
        setIsLoading(false);
    };

    const handleDeleteClient = (customer_id) => {
        setDeleteClientId(customer_id);
        dispatch(showConfirmNotification("Are you sure you want to delete this client?", () => confirmDeleteClient(customer_id)));
        handleCloseEditButton();
    };

    const confirmDeleteClient = async (customer_id) => {
        setIsLoading(true);
        const response = await DeleteClient(customer_id);
        if (response.status === 200) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Client Deleted',
                    userType: sessionStorage.getItem("selectedAcType"),
                    user_id: sessionStorage.getItem("clientId")
                },
            });
            dispatch(fetchPortfolio());
            handleCloseEditButton();
        } else {
            handleApiError(response, dispatch);
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            {isLoading ? (
                <div className="loading-overlay">
                    <Loader/>
                </div>) : (
                <div className="sidebar">
                    <div className='clientlist'>
                        {customers.map((customer) => (
                            <div className='clientcard' key={customer.CustomerId}>
                                <h3 className='clientdata'>
                                    <span className={activeClient === customer.CustomerId? 'name active': 'name'}>{customer.FirstName} {customer.LastName}</span>
                                    <button className='icon'
                                        onClick={(e) => handleOpenModal(e, customer)}
                                        disabled={customer.customer_has_schwab_master_account_no}>
                                        <img src="Assets/add_portfolio.svg" alt="Add new Portfolio" />
                                    </button>
                                    <button className='icon'
                                        onClick={(e) => handleClientOpenModal(e, customer)}
                                        disabled={customer.customer_has_schwab_master_account_no}>
                                        <img src="Assets/more.svg" alt="Add new Client" />
                                    </button>
                                    <button className='icon' onClick={() => toggleCollapsible(customer.Username,customer.CustomerId)}>
                                        {selectedClientIds.includes(customer.CustomerId) ? <img src="Assets/c_up.svg" alt="toggle" /> : <img src="Assets/c_down.svg" alt="toggle" />}
                                    </button>
                                </h3>
                                {customer.Portfolio.map(portfolio => (
                                    <>
                                        {selectedClientIds.includes(customer.CustomerId) &&
                                            <div className='portfoliodata' key={portfolio.PortfolioId} onClick={() => handleCheckboxChange(portfolio,customer)}>
                                                <span className={activePortfolio === portfolio.PortfolioId ? 'name active portfoliopadding' : 'name portfoliopadding'}>{portfolio.PortfolioName}</span>
                                                {/* <button className='icon'>
                                                    <img src="Assets/more.svg" alt="Add new Client" />
                                                </button> */}
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="sidebar-buttons">
                        <button className='ai-btn solid blue'
                            onClick={() => {
                                dispatch(openDrawer('ImportCSVPortfolioDrawer', { openedFromSidebar: true }));
                            }}>
                            <FileUploadIcon style={{ color: '#ffffff' }} />
                            Import CSV</button>
                        <button className='ai-btn solid blue'
                            onClick={() => dispatch(openDrawer('AddClientDrawer'))}>
                            <img src="Assets/add-user.svg" alt="Add new Client" />Add new Client</button>
                    </div>
                    {showModal.visible && (
                        <div
                            ref={modalRef}
                            className="portfolio-modal"
                            style={{ top: showModal.position.y, left: showModal.position.x }}
                        >
                            <button
                                onClick={() => {
                                    dispatch(openDrawer('AddNewPortfolio', { clientUsername: showModal.clientUsername }));
                                    closeModal();
                                }}
                            >
                                <span>Enter Manually</span> <img className="product_card_img" src='./Assets/Typing.svg'></img>
                            </button>
                            <button
                                onClick={() => {
                                    dispatch(openDrawer('ImportCSVPortfolioDrawer', { clientUsername: showModal.clientUsername, customerId: showModal.customerId }));
                                    closeModal();
                                }}
                            >
                                <span>Import CSV</span> <img className="product_card_img" src='./Assets/CSV.svg'></img>
                            </button>
                            <button
                                onClick={() => {
                                    dispatch(openDrawer('ModalPortfolio', { clientUsername: showModal.clientUsername }));
                                    closeModal();
                                }}
                            >
                                <span>Import Model Portfolio</span> <img className="product_card_img" src='./Assets/CSV.svg'></img>
                            </button>
                        </div>
                    )}
                    {showClientModal.visible && (
                        <div
                            ref={modalRef}
                            className="portfolio-modal"
                            style={{ top: showClientModal.position.y, left: showClientModal.position.x }}
                        >
                            {editClientStage === 1 && (
                                <>
                                    <button onClick={() => { setEditClientStage(2) }}> Edit Info </button>
                                    <button onClick={() => { handleDeleteClient(showClientModal.customerId) }}> Delete Client </button>
                                </>
                            )}
                            {editClientStage === 2 && (
                                <div className='clientinfo'>
                                    <div className='ai-form-group'>
                                        <label className="ai-form-label">Client Name</label>
                                        <input className="ai-form-value" onChange={(e) => setEditClientName(e.target.value)} type="text" name="clientname" value={editClientName}></input>
                                        {editClientErrors.editClientName && <p className='error-message'>{editClientErrors.editClientName}</p>}
                                    </div>
                                    <div className='ai-form-group'>
                                        <label className="ai-form-label">External Id</label>
                                        <input className="ai-form-value" onChange={(e) => setEditClientExternalId(e.target.value)} type="text" name="clientexternalid" value={editClientExternalId}></input>
                                        {editClientErrors.editClientExternalId && <p className='error-message'>{editClientErrors.editClientExternalId}</p>}
                                    </div>
                                    <div className="drawerbottom actions">
                                        <button className='ai-btn small solid blue' onClick={() => handleCloseEditButton()}>Cancel</button>
                                        <button className='ai-btn small solid primary' onClick={() => handleEditClient(showClientModal.customerId)}>Save</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {DrawerComponent && <DrawerComponent />}
                </div>
            )}
        </>
    );
}

export default Sidebar;
