import React from "react";
import AdaptiveManualImportWizardComponent from "../../Components/OnboardClientPortfolio/AdaptiveManualImportWizardComponent";
import './AdaptiveImportWizardScreen.css';
import Navbar from "../../Components/Navbar/Navbar";

function AdaptiveManualImportWizardScreen() {

    return (
        <>
            <div className="container">
                <div className="onboard_wrap">
                    <AdaptiveManualImportWizardComponent/>
                </div>
            </div>
        </>
    );
}

export default AdaptiveManualImportWizardScreen;
