import React, { useState } from 'react';
import { addZeroes } from "../../Utilities/AddZeros";
import "./BackTestV2.scss";
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid, Tooltip,
    ResponsiveContainer,
    Scatter
} from 'recharts';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p class="recharts-tooltip-label">{`${label}`}</p>
                {payload.map((pay, index) => {
                    const originalKey = `original${pay.name.replace(/\s/g, "")}`;
                    const valueToDisplay = pay.payload[originalKey] !== undefined ? pay.payload[originalKey] : pay.value;
                    if (pay.dataKey === 'payoff' || pay.dataKey === "compoundedShieldPayoff") {
                        return (
                            <p key={index} style={{ color: `${payload[index].color}` }}>
                                {`${pay.name}: ${Number(
                                    addZeroes(Number(parseFloat(valueToDisplay).toFixed(2)))
                                ).toLocaleString("en-US", currObj)}`}
                            </p>
                        );
                    } else {
                        return (
                            <p key={index} style={{ color: `${payload[index].color}` }}>
                                {`${pay.name}: ${(valueToDisplay * 100).toFixed(2)}%`}
                            </p>
                        );
                    }
                })}
            </div>
        );
    }

    return null;
};


var currObj = {
    style: "currency",
    currency: "USD",
};

function BacktestV2Graph({ rawData, showPortfolioShield,summary}) {
    const [showPortfolio, setShowPortfolio] = useState(true);
    const [showAssetAllocation, setShowAssetAllocation] = useState(true);
    const [showPutIndex, setShowPutIndex] = useState(true);
    const [showMarketIndex, setShowMarketIndex] = useState(true);
    // const [showNamePrice, setShowNamePrice] = useState(true);
    // const [showLimitRisk, setShowLimitRisk] = useState(true);

    const riskReturnData = {
        'portfolioShield': 'Data1',
        'sp500': 'Data2',
        'portfolioWithoutShield': 'Data3',
        'riskEquivalent': 'Data4'
    };

    let currentYear = null;

    let data = [{}, {}, {}, {}];

    if (rawData && Object.keys(rawData).length > 0) {
        const portfolioValues = rawData['port'] ? Object.values(rawData['port']) : [];
        const portfolioWithAssetAllocation = rawData['port_w_asset_allocation'] ? Object.values(rawData['port_w_asset_allocation']) : [];
        const portfolioWithPutIndex = rawData['port_w_put_index'] ? Object.values(rawData['port_w_put_index']):[];
        const marketIndex = rawData['idx'] ? Object.values(rawData['idx']):[];

        const allValues = [...portfolioValues, ...portfolioWithAssetAllocation, ...portfolioWithPutIndex, ...marketIndex];

        data = Object.keys(rawData['port']).map(date => {
            let item = {
                date: date,
                portfolio: rawData['port'][date],
                assetallocation: rawData['port_w_asset_allocation'][date],
                marketIndex: rawData['idx'][date],
                putIndex: rawData['port_w_put_index'][date],
            };
            return item;
        });
    }

    const formatXAxis = (tickItem) => {
        const year = new Date(tickItem).getFullYear();
        const month = new Date(tickItem).getMonth();
        if (year !== currentYear) {
            currentYear = year;
            return year;
        }
        else {
            return '';
        }
    }

    const formatYAxis = (tick) => {
        return `${(tick).toFixed(2)}%`;
    }

    return (
        <div id="backtestgraph">
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: Object.keys(rawData).length > 0 ? "100%" : "90%", marginRight: "0px" }}>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid stroke="#ccc" vertical={false} />
                            <XAxis dataKey="date" tickFormatter={formatXAxis} interval='preserveStartEnd' tickLine={false} />
                            <YAxis yAxisId="left" tickFormatter={{formatYAxis}} interval='preserveStartEnd' domain={[dataMin => Number(dataMin).toFixed(2),dataMax => Number(dataMax).toFixed(2)]} axisLine={false} scale={'linear'}/>
                            <Tooltip content={<CustomTooltip />} />
                            {showAssetAllocation && <Line yAxisId="left" dot={false} type="monotone" dataKey="assetallocation" name={`Asset Allocation(${rawData['asset-allocation-ratio']})`} stroke="#ED6663" />}
                            {showPortfolio && <Line yAxisId="left" dot={false} type="monotone" dataKey="portfolio" name="Portfolio Without Shied" stroke="#54C1FF" />}
                            {showPutIndex && <Line yAxisId="left" dot={false} type="monotone" dataKey="putIndex" name="Portfolio With Index Put" stroke="#800000" />}
                            {showMarketIndex && <Line yAxisId="left" dot={false} type="monotone" dataKey="marketIndex" name={`${rawData['index']} Index`} stroke="#FFA654" />}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
            {summary && <div id='backtestcheckbox' style={{ textAlign: "center", padding: "20px 0" }}>
                <label style={{ color: "#ED6663", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#ED6663" }} checked={showAssetAllocation} onChange={(e) => setShowAssetAllocation(e.target.checked)} />
                    Portfolio Asset Allocation
                </label>
                <label style={{ color: "#54C1FF", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#54C1FF" }} checked={showPortfolio} onChange={(e) => setShowPortfolio(e.target.checked)} />
                    Portfolio Without Shield
                </label>
                {showPortfolioShield && <label style={{ color: "#800000", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#800000" }} checked={showPutIndex} onChange={(e) => setShowPutIndex(e.target.checked)} />
                    Portfolio with Put Index
                </label>}
                <label style={{ color: "#FFA654", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#FFA654" }} checked={showMarketIndex} onChange={(e) => setShowMarketIndex(e.target.checked)} />
                    
                </label>
                {/* <label style={{ color: "#96BE25", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#96BE25" }} checked={showNamePrice} onChange={(e) => setShowNamePrice(e.target.checked)} />
                    Name Your Price Shield
                </label>
                <label style={{ color: "#EC4899", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#EC4899" }} checked={showLimitRisk} onChange={(e) => setShowLimitRisk(e.target.checked)} />
                    Dial Down Risk
                </label> */}
            </div>
            /* <div id='backtestriskreturnratio' style={{ textAlign: "center", padding: "20px 0" }}>
                <table className="risk-return-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Portfolio With Market Shield</th>

                            <th>Portfolio Without Shield</th>
                            {showPortfolioShield && <th>Portfolio with Portfolio Shield</th>}
                            <th>S&P 500</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td>Annual Returns</td>
                            <td>{parseFloat(parseFloat(rawData['returns']['market_shield']).toFixed(4) * 100).toFixed(2)}%</td>

                            <td>{parseFloat(parseFloat(rawData['returns']['unshielded']).toFixed(4) * 100).toFixed(2)}%</td>
                            {showPortfolioShield && <td>{parseFloat(parseFloat(rawData['returns']['shielded']).toFixed(4) * 100).toFixed(2)}%</td>}
                            <td>{parseFloat(parseFloat(rawData['returns']['sp500']).toFixed(4) * 100).toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Downside Risk</td>
                            <td>{parseFloat(parseFloat(rawData['downside_risk']['market_shield']).toFixed(4) * 100).toFixed(2)}%</td>

                            <td>{parseFloat(parseFloat(rawData['downside_risk']['unshielded']).toFixed(4) * 100).toFixed(2)}%</td>
                            {showPortfolioShield && <td>{parseFloat(parseFloat(rawData['downside_risk']['shielded']).toFixed(4) * 100).toFixed(2)}%</td>}
                            <td>{parseFloat(parseFloat(rawData['downside_risk']['sp500']).toFixed(4) * 100).toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Return/Risk Ratio<TooltipComponent id={'Risk Return Ratio'} /></td>
                            <td>{parseFloat(rawData['return_ratio']['market_shielded_ratio']).toFixed(2)}</td>

                            <td>{parseFloat(rawData['return_ratio']['unshielded_ratio']).toFixed(2)}</td>
                            {showPortfolioShield && <td>{parseFloat(rawData['return_ratio']['shielded_ratio']).toFixed(2)}</td>}
                            <td>{parseFloat(rawData['return_ratio']['sp500_ratio']).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
        </div>
    );
}

export default BacktestV2Graph;
