


import React, { useState } from 'react';
import './ModalPortfolio.css';
import { PostModalPortfolioApi } from '../../Apis/PostModalPortfolio';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import { portfolioAdded } from '../../actions/portfolioActions';
import Loader from '../../Components/Loader/Loader';
import { useHistory } from "react-router-dom";
import { addZeroes } from "../../Utilities/AddZeros";
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from "react-gtm-module";

const ModalPortfolio = () => {
    var currObj = {
        style: "currency",
        currency: "USD",
    };
    const dispatch = useDispatch();
    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioValue, setPortfolioValue] = useState('');
    const [formatPortfolioValue, setFormatPortfolioValue] = useState('');
    const [selectedPortfolios, setSelectedPortfolios] = useState({});
    
    const drawerData = useSelector(state => state.drawer.data);
    const clientUsername = drawerData.clientUsername;

    const history = useHistory();
    const clientPortfolios = () => history.push("/ClientPortfolios");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modalPortfolioErrors,setModalPortfolioErrors] = React.useState([]);
    var selectedModalPortfolio = ""

    const portfolios = [
        'The Magnificent Seven',
        'FAANG',
        'NASDAQ',
        'Bill Bernstein No Brainer',
        'Bogleheads Three Funds',
        'Bogleheads Four Funds',
        'David Swensen Lazy Portfolio',
        'Ray Dalio All Seasons',
        'Rick Ferri Core Four',
        'Scott Burns Couch Potato Portfolio',
        'Stocks Bonds 60 40',
        'Stocks Bonds 40 60',
        'Ray Dalio',
    ];

    const handleSelect = (portfolio) => {
        setSelectedPortfolios(prevState => ({
            [portfolio]: !prevState[portfolio]
        }));
    };

    function changeValue(event, value) {
        let errors = {};
        let trimmedValue = value.trim().replace(/[^0-9.]/g, '');
        if (event.key === "Enter") {
            let float_value = parseFloat(trimmedValue);
            if (isNaN(float_value) || trimmedValue === "") {
                errors.portfolioValue = "Please enter a valid value to proceed further.";
                return;
            }
            if (float_value === 0) {
                errors.portfolioValue = "Value cannot be zero. Please enter a valid value.";
                return;
            }
            
            setFormatPortfolioValue(float_value);
            setPortfolioValue((Number(
                addZeroes(
                    Number(float_value.toFixed(2))
                )
            ).toLocaleString("en-US", currObj)));
        }
        setModalPortfolioErrors(errors);
    }

    function handleChangeValue(event, value) {
        let text = event.target.value.replace(/[^\d\.]/gi, '');
        if ((text.split('.').length - 1) > 1) {
            return;
        }

        let lastCharIsAdot = text.substr(text.length - 1, 1) === ".";

        if (text === "0" || text === "." || isNaN(text)) {
            return;
        } else if (event.target.value === "") {
            setPortfolioValue("");
        } else {
            if (lastCharIsAdot) event.target.value += ".";
            setPortfolioValue(Number(text).toLocaleString("en-US"));
            let float_value = parseFloat(event.target.value.replace(/,/g, ''));
            setFormatPortfolioValue(float_value);
        }
    }
    const validationCheckModalPortfolio = () =>{
        let validFlag = true;
        let errors = {};
        
        if(!portfolioName){
            validFlag = false;
            errors.portfolioName = "Please enter a valid portfolio name";
        }
        if(!portfolioValue){
            validFlag = false;
            errors.portfolioValue = "Please enter a valid portfolio value";
        }

        if(selectedModalPortfolio === ""){
            validFlag = false;
            errors.selectedPortfolios = "Please select a portfolio";
        }
        setModalPortfolioErrors(errors);
        return validFlag;
    }

    const confirmModalPortfolio = async () =>{
        for (const [k, v] of Object.entries(selectedPortfolios)) {
            if (v === true) {
                selectedModalPortfolio = k;
            }
        }
        if(validationCheckModalPortfolio()){
            setIsLoading(true);
            const data = await PostModalPortfolioApi(portfolioName,formatPortfolioValue,selectedModalPortfolio,clientUsername);
            if (data.status === 200) {
                dispatch(portfolioAdded());
                dispatch(closeDrawer());
                TagManager.dataLayer({
                    dataLayer: {
                      event: 'Portfolio Modeled',
                      userType: sessionStorage.getItem("selectedAcType"),
                      user_id: sessionStorage.getItem("clientId"),
                      model_portfolio_name: selectedModalPortfolio,
                    },
                  });
            } else {
                handleApiError(data, dispatch);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className='drawer'>
                {isLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>) : (
                        <>
                            <div className="drawer-header">
                                <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                                <h1>Choose the model portfolio you'd like to replicate.</h1>
                                <div className="actions">
                                <button className="close-drawer" onClick={() => dispatch(closeDrawer())}>✕</button>
                                </div>
                            </div>
                            <div className='drawercontent'>
                                <div className="inputs">
                                    <div>
                                        <label>Portfolio Name</label>
                                        <input type="text" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} />
                                        <p className='ai-error-message'>{modalPortfolioErrors.portfolioName}</p>
                                    </div>
                                    <div>
                                        <label>Portfolio Value</label>
                                        <input type="text" value={portfolioValue}
                                            onChange={handleChangeValue} 
                                            onKeyUp={(event) => changeValue(event, event.target.value)}
                                        />
                                        <p className='ai-error-message'>{modalPortfolioErrors.portfolioValue}</p>
                                    </div>
                                </div>
                                <div className='inputs'>
                                    <div className='portfoliodata'>
                                        <ul className="portfolio-list">
                                            {portfolios.map((portfolio) => (
                                                <div>
                                                    <li key={portfolio}>
                                                        {portfolio}
                                                        <button
                                                            className={selectedPortfolios[portfolio] ? 'ai-btn solid blue' : 'ai-btn line blue'}
                                                            onClick={() => handleSelect(portfolio)}
                                                        >
                                                            {selectedPortfolios[portfolio] ? '✓ Selected' : 'Select'}
                                                        </button>
                                                    </li>
                                                </div>
                                            ))}
                                            <p className='ai-error-message'>{modalPortfolioErrors.selectedPortfolios}</p>
                                        </ul>
                                    </div>
                                </div>                                
                                <div className="drawerbottom actions">
                                    <button className='ai-btn solid red' onClick={() => dispatch(closeDrawer())}><img src="./Assets/close-icon.svg" alt="close" /> Close</button>
                                    <button className='ai-btn solid primary' onClick={confirmModalPortfolio}>Import Model Portfolio</button>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </>
    );
};

export default ModalPortfolio;
