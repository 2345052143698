import {
    FETCH_PORTFOLIO_START,
    FETCH_PORTFOLIO_SUCCESS,
    FETCH_PORTFOLIO_FAILURE,
    UPDATE_CUSTOMERS,
    SELECT_PORTFOLIO,
    FETCH_PORTFOLIO_EMPTY,
    TOGGLE_PORTFOLIO_SELECTION,
    TOGGLE_CLIENT_SELECTION,
    SELECT_CLIENT,
    PORTFOLIO_ADDED,
    TOGGLE_PORTFOLIO_HEDGEABLE_FLAG

} from '../actions/portfolioActions';

const initialState = {
    customers: [],
    isLoading: true,
    error: null,
    selectedPortfolioIds: [],
    selectedClientIds:[],
    portfolioAdded: false,
    selectedScrollId:null,
    activePortfolio:null,
    activeClient:null
};

const portfolioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PORTFOLIO_START:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_PORTFOLIO_SUCCESS:
            const updatedCustomers = action.payload.map(customer => ({
                ...customer,
                Portfolio: customer.Portfolio.map(portfolio => ({
                    ...portfolio,
                    hFlag: false
                }))
            }));

            return {
                ...state,
                isLoading: false,
                customers: updatedCustomers.map(customer => ({ ...customer, expanded: false})),
                error: null,
                isEmpty: false
            };
        case UPDATE_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            };
        case FETCH_PORTFOLIO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case TOGGLE_PORTFOLIO_SELECTION:
            const isAlreadySelected = state.selectedPortfolioIds.includes(action.payload.portfolioId);
            if(action.payload.actionType === 'drawer'){
                return {
                    ...state,
                    selectedPortfolioIds: isAlreadySelected ?
                        state.selectedPortfolioIds :
                        [...state.selectedPortfolioIds, action.payload.portfolioId],
                        selectedScrollId : state.selectedScrollId === action.payload.portfolioId? null: action.payload.portfolioId,
                    activePortfolio: action.payload.portfolioId
                };
            }
            else{
                return {
                    ...state,
                    selectedPortfolioIds: isAlreadySelected ?
                        state.selectedPortfolioIds.filter(id => id !== action.payload.portfolioId) :
                        [...state.selectedPortfolioIds, action.payload.portfolioId],
                    selectedScrollId : state.selectedScrollId === action.payload.portfolioId? null: action.payload.portfolioId, 
                    activePortfolio: state.activePortfolio === action.payload.portfolioId? null: action.payload.portfolioId
                };
            }
        case FETCH_PORTFOLIO_EMPTY:
            return {
                ...state,
                isLoading: false,
                isEmpty: true,
            };
        case PORTFOLIO_ADDED:
            return {
                ...state,
                portfolioAdded: !state.portfolioAdded
            };
        case TOGGLE_CLIENT_SELECTION:
            const isClientSelected = state.selectedClientIds.includes(action.payload.CustomerId);
            const custPortfolios = state.customers.find(customer => customer.CustomerId === action.payload.CustomerId).Portfolio.map(portfolio => portfolio.PortfolioId)
            if(action.payload.clickType === 'toggle'){
                return {
                    ...state,
                    selectedClientIds: isClientSelected ?
                        state.selectedClientIds.filter(id => id !== action.payload.CustomerId):
                    [...state.selectedClientIds, action.payload.CustomerId],
                    selectedScrollId : state.selectedScrollId === action.payload.CustomerId? null: action.payload.CustomerId 
                };
            }else{
                return {
                    ...state,
                    selectedClientIds: isClientSelected ?
                        state.selectedPortfolioIds.some(portfolioId => custPortfolios.includes(portfolioId)) ? state.selectedClientIds : state.selectedClientIds.filter(id => id !== action.payload.CustomerId) :
                    [...state.selectedClientIds, action.payload.CustomerId],
                    selectedScrollId : state.selectedScrollId === action.payload.CustomerId? null: action.payload.CustomerId 
                };
            }
        case TOGGLE_PORTFOLIO_HEDGEABLE_FLAG:
            return {
                ...state,
                customers: state.customers.map(customer => {
                    if (customer.CustomerId === action.payload.CustomerId) {
                        return {
                            ...customer,
                            Portfolio: customer.Portfolio.map(portfolio => {
                                if (portfolio.PortfolioId === action.payload.portfolioId) {
                                    return {
                                        ...portfolio,
                                        hFlag: !portfolio.hFlag
                                    };
                                }
                                return portfolio;
                            })
                        };
                    }
                    return customer;
                })
            };

        case SELECT_CLIENT:
            return{
                ...state,
                activeClient:action.payload.CustomerId
            }
        default:
            return state;
    }
};

export default portfolioReducer;
