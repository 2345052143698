import {
    FETCH_LINK_TOKEN_START,
    FETCH_LINK_TOKEN_SUCCESS,
    FETCH_LINK_TOKEN_FAILURE,
    SEND_PLAID_ACCESS_TOKEN_SUCCESS,
    SEND_PLAID_ACCESS_TOKEN_FAILURE,
    SEND_PLAID_ACCESS_TOKEN_START,
    SELECT_PLAID_CUSTOMER,
    GET_PLAID_ACCOUNTS_START,
    GET_PLAID_ACCOUNTS_SUCCESS,
    GET_PLAID_ACCOUNTS_FAILURE,
    SELECT_PLAID_ACCOUNT,
    GET_PLAID_HOLDINGS_START,
    GET_PLAID_HOLDINGS_SUCCESS,
    GET_PLAID_HOLDINGS_FAILURE,
    IMPORT_PLAID_PORTFOLIO_SUCCESS,
    IMPORT_PLAID_PORTFOLIO_FAILURE,
    IMPORT_PLAID_PORTFOLIO_START,

} from '../actions/plaidActions';

const initialState = {
    linkToken: null,
    isLoading: false,
    error: null,
    selectedAccountId: null,
    accountData: {},
    clientUsername: null,
    plaidAccounts: {}
};



const plaidReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LINK_TOKEN_START:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_LINK_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                linkToken: action.payload,
                error: null,
            };

        case FETCH_LINK_TOKEN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case SEND_PLAID_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case SEND_PLAID_ACCESS_TOKEN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SEND_PLAID_ACCESS_TOKEN_START:
            return {
                ...state,
                isLoading: true,
            };
        case GET_PLAID_ACCOUNTS_START:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case GET_PLAID_ACCOUNTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                plaidAccounts: action.payload,
            };
        case GET_PLAID_ACCOUNTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case GET_PLAID_HOLDINGS_START:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case GET_PLAID_HOLDINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                accountData: action.payload,
            };
        case IMPORT_PLAID_PORTFOLIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case IMPORT_PLAID_PORTFOLIO_FAILURE:
                return {
                    ...state,
                    isLoading: false,
                    error:action.payload,
                };
        case IMPORT_PLAID_PORTFOLIO_START:
            return{
                ...state,
                isLoading:true,
            }
        case GET_PLAID_HOLDINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SELECT_PLAID_CUSTOMER:
            return {
                ...state,
                clientUsername: action.payload
            }
        case SELECT_PLAID_ACCOUNT:
            return {
                ...state,
                selectedAccountId: action.payload
            }
        default:
            return state;
    }
};

export default plaidReducer;
