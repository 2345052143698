import { UPDATE_PDF_DATA } from "../actions/pdfDataActions";

const initialState = {};

const pdfDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PDF_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    default:
      return state;
  }
};

export default pdfDataReducer;
