import { GetADPortfolioLineItems } from '../Apis/GetADPortfolioLineItems';
import { AddPortfolioLineItem } from '../Apis/AddPortfolioLineItem';
import { DeletePortfolioLineItem } from '../Apis/DeletePortfolioLineItem';
import { handleApiError } from './notificationActions';

export const FETCH_PORTFOLIO_DETAILS_START = 'FETCH_PORTFOLIO_DETAILS_START';
export const FETCH_PORTFOLIO_DETAILS_SUCCESS = 'FETCH_PORTFOLIO_DETAILS_SUCCESS';
export const FETCH_PORTFOLIO_DETAILS_FAILURE = 'FETCH_PORTFOLIO_DETAILS_FAILURE';
export const FETCH_PORTFOLIO_DETAILS_LOADING = 'FETCH_PORTFOLIO_DETAILS_LOADING';
export const UPDATE_PORTFOLIO_NEEDS_UPDATE = 'UPDATE_PORTFOLIO_NEEDS_UPDATE';

export const fetchPortfolioDetailsIfNeeded = (portfolioId) => (dispatch, getState) => {
    
    const portfolioDetails = getState().portfolioDetails[portfolioId];
    if (!portfolioDetails || portfolioDetails.needsUpdate) {
        dispatch({ type: FETCH_PORTFOLIO_DETAILS_LOADING, payload: { portfolioId, isLoading: true } });
        
        // Ensure that fetchPortfolioDetails is also a thunk that returns a promise.
        return dispatch(fetchPortfolioDetails(portfolioId))
            .finally(() => {
                dispatch({ type: FETCH_PORTFOLIO_DETAILS_LOADING, payload: { portfolioId, isLoading: false } });
            });
    }
    // If the details don't need an update, return a resolved promise.
    return Promise.resolve();
};


export const fetchPortfolioDetails = (portfolioId) => async (dispatch) => {
    dispatch({ type: FETCH_PORTFOLIO_DETAILS_START, payload: { portfolioId } });

    try {
        const response = await GetADPortfolioLineItems(portfolioId);
        console.log("in action resp new", response)
        const isEmpty = !(response?.PortfolioLineItem?.Hedgeable?.length > 0);

        console.log("isEmpty",isEmpty)
        if(response.status === 200){
            dispatch({ 
                type: FETCH_PORTFOLIO_DETAILS_SUCCESS, 
                payload: { 
                    portfolioId, 
                    details: response.result || {},
                    isEmpty 
                } 
            });
        }else{
            handleApiError(response, dispatch);
        }
    } catch (error) {
        dispatch({ 
            type: FETCH_PORTFOLIO_DETAILS_FAILURE, 
            payload: { portfolioId, error: error.toString() } 
        });
    }
    return Promise.resolve();
};

export const addTicker = (portfolioId, ticker, quantity) => async (dispatch) => {
    try {
        const response = await AddPortfolioLineItem(portfolioId, ticker, quantity);
        if (response.status === 200) {
            dispatch(fetchPortfolioDetails(portfolioId));
        } else {
            throw new Error({ status: response.status, error: response.error });
        }
    } catch (error) {
        handleApiError(error, dispatch);
    }
};

export const deleteTicker = (portfolioId, ticker) => async (dispatch) => {
    console.log("in delete li", portfolioId)
    try {
        const response = await DeletePortfolioLineItem(portfolioId, ticker);
        if (response.status === 200) {
            dispatch(fetchPortfolioDetails(portfolioId));
        } else {
            throw new Error({ status: response.status, error: response.error });
        }
    } catch (error) {
        handleApiError(error, dispatch);
    }
};

export const setPortfolioNeedsUpdate = (portfolioId) => ({
    type: UPDATE_PORTFOLIO_NEEDS_UPDATE,
    payload: { portfolioId }
});
