export const UPDATE_PDF_SUMMARY_DATA = 'UPDATE_PDF_SUMMARY_DATA';
export const UPDATE_DISPLAY_ELEMENT = 'UPDATE_DISPLAY_ELEMENT';
export const UPDATE_CUSTOM_NOTE = 'UPDATE_CUSTOM_NOTE';
export const DELETE_PDF_SUMMARY_DATA_ELEMENT = 'DELETE_PDF_SUMMARY_DATA_ELEMENT';
export const CLEAR_PDF_SUMMARY_DATA = 'CLEAR_PDF_SUMMARY_DATA';
export const UPDATE_CUSTOM_TITLE = 'UPDATE_CUSTOM_TITLE';

export const updatePdfSummaryData = (chartName,data) => ({
    type:UPDATE_PDF_SUMMARY_DATA,
    payload:{chartName, data}
})

export const updateDisplayElement = (chartName, displayValue) => ({
    type: UPDATE_DISPLAY_ELEMENT,
    payload: {
        chartName,
        displayValue
    }
});

export const updateCustomNote = (chartName, customNote) => ({
    type: UPDATE_CUSTOM_NOTE,
    payload: {
        chartName,
        customNote
    }
});

export const deletePdfSummaryDataElement = (chartName) => ({
    type: DELETE_PDF_SUMMARY_DATA_ELEMENT,
    payload:{
        chartName
    }
})

export const clearPdfSummaryData = (portfolioId) => ({
    type: CLEAR_PDF_SUMMARY_DATA,
    payload:{
        portfolioId
    }
})

export const updateCustomTitle = (chartName, title) => ({
    type: UPDATE_CUSTOM_TITLE,
    payload: {
        chartName,
        title
    }
});