import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CollarView.css';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import DashboardSection from '../ChainDashboard/ChainDashboard';
import { ConstructionOutlined } from '@mui/icons-material';
import {
    toggleItemSelection,
    setAllSelected,
    initializeSelections,
    setLocalFilters
} from '../../actions/selectionActions';

const CollarView = ({ portfolioDetails }) => {
    const dispatch = useDispatch();
    const optionsData = useSelector(state => state.options.data);
    const hedgeable = portfolioDetails?.PortfolioLineItem?.Hedgeable || [];
    const [totalPortfolioValue, setTotalPortfolioValue] = useState(() =>
        (hedgeable.reduce((acc, item) => acc + (item.ClosePrice * item.Quantity), 0))
    );

    const { selectedItems: callSelectedItems } = useSelector(state => state.selection.CallWriting);
    const { selectedItems: putSelectedItems } = useSelector(state => state.selection.PutBuying);

    const allCallSelected = useSelector(state => state.selection.CallWriting.allSelected);
    const allPutSelected = useSelector(state => state.selection.PutBuying.allSelected);

    const totalTickersHeld = hedgeable.length;
    const calls = optionsData?.C;
    const puts = optionsData?.P;
    const uniqueSymbols = Object.keys(calls);
    const [globalStdDevCap, setGlobalStdDevCap] = useState(1);
    const [globalStdDevProtection, setGlobalStdDevProtection] = useState(1);
    const [useGlobalStdDev, setUseGlobalStdDev] = useState(false);



    function extractAndAlignOptionContracts(hedgeable) {
        const alignedContracts = [];

        Object.keys(hedgeable).forEach(key => {

            const item = hedgeable[key];
            // Check if the ticker is greater than 10 characters
            if (item.Ticker && item.Ticker.length > 10) {
                const ticker = item.Ticker;

                // Extracting information from the ticker
                const regex = /^([A-Z]+)(\d{4})-(\d{2})-(\d{2})([CP])(\d+(\.\d{1,2})?)$/

                const match = ticker.match(regex);

                if (match) {
                    const [, stockTicker, year, month, day, type, strikePrice] = match;

                    const optionInfo = {
                        symbol: stockTicker,
                        expiry: `${month}/${day}/${year}`,
                        right: type === 'C' ? 'CALL' : 'PUT',
                        cap: parseFloat(strikePrice).toFixed(2),
                        bidPrice: item.ClosePrice ? parseFloat(item.ClosePrice) : null,
                        contracts: item.Quantity ? parseInt(item.Quantity, 10) : null,
                        premium: item.TotalValue ? parseFloat(item.TotalValue) : null,
                    };

                    // Push the option info directly to the alignedContracts array
                    alignedContracts.push(optionInfo);
                }
            }
        });

        return alignedContracts;
    }

    const [data, setData] = useState(extractAndAlignOptionContracts(hedgeable));


    const exisitngHoldings = [...new Set(data.map(item => item.symbol))];
    const [globalCap, setGlobalCap] = useState(110);
    const [globalProtection, setGlobalProtection] = useState(90);
    const [globalExpiry, setGlobalExpiry] = useState(() => {
        const now = new Date();
        const oneMonthFromNow = new Date(now.setMonth(now.getMonth() + 1));
        return oneMonthFromNow.toISOString().slice(0, 10);
    });

    const formatExpirationDate = (expirationDate) => {
        const date = new Date(expirationDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [bestCallContracts, setBestCallContracts] = useState({});
    const [bestPutContracts, setBestPutContracts] = useState({});

    const [totalCost, setTotalCost] = useState(0);
    const [totalPremium, setTotalPremium] = useState(0);
    const [showDashboardSection, setShowDashboardSection] = useState(false)
    const [selectedTicker, setSelectedTicker] = useState('')
    const [currentSelections, setCurrentSelections] = useState(() => {
        const savedSelections = sessionStorage.getItem('currentSelections');
        return savedSelections ? JSON.parse(savedSelections) : {};
    });
    const [currentPutSelections, setCurrentPutSelections] = useState(() => {
        const savedPutSelections = sessionStorage.getItem('currentPutSelections');
        return savedPutSelections ? JSON.parse(savedPutSelections) : {};
    });


    const changeGlobalCap = (e) => {
        setCurrentSelections({})
        sessionStorage.removeItem('currentSelections');
        const newCap = e.target.value;
        setGlobalCap(newCap);

        // Update all local call filters with the new global cap
        setCallsLocalFilters(prevFilters => {
            const updatedFilters = {};
            Object.keys(prevFilters).forEach(symbol => {
                updatedFilters[symbol] = {
                    ...prevFilters[symbol],
                    cap: newCap
                };
            });
            return updatedFilters;
        });

        // Recalculate the best contracts after global change
        uniqueSymbols.forEach(symbol => updateBestContracts(symbol, 'CALL'));
    };

    const changeGlobalStdDevCap = (e) => {
        setCurrentSelections({});
        sessionStorage.removeItem('currentSelections');
        const newStdDevCap = parseFloat(e.target.value);
        setGlobalStdDevCap(newStdDevCap);

        // Update all local call filters with the new global std dev cap
        setCallsLocalFilters(prevFilters => {
            const updatedFilters = {};
            Object.keys(prevFilters).forEach(symbol => {
                updatedFilters[symbol] = {
                    ...prevFilters[symbol],
                    stdDev: newStdDevCap // Updating stdDev instead of cap
                };
            });
            return updatedFilters;
        });

        // Recalculate the best contracts after global change
        uniqueSymbols.forEach(symbol => updateBestContracts(symbol, 'CALL'));
    };


    const changeGlobalProtection = (e) => {
        setCurrentPutSelections({})
        sessionStorage.removeItem('currentPutSelections');
        const newProtection = e.target.value;
        setGlobalProtection(newProtection);

        // Update all local put filters with the new global protection
        setPutsLocalFilters(prevFilters => {
            const updatedFilters = {};
            Object.keys(prevFilters).forEach(symbol => {
                updatedFilters[symbol] = {
                    ...prevFilters[symbol],
                    protection: newProtection
                };
            });
            return updatedFilters;
        });

        // Recalculate the best contracts after global change
        uniqueSymbols.forEach(symbol => updateBestContracts(symbol, 'PUT'));
    };

    const changeGlobalStdDevProtection = (e) => {
        setCurrentPutSelections({});
        sessionStorage.removeItem('currentPutSelections');
        const newStdDevProtection = parseFloat(e.target.value);
        setGlobalStdDevProtection(newStdDevProtection);

        // Update all local put filters with the new global std dev protection
        setPutsLocalFilters(prevFilters => {
            const updatedFilters = {};
            Object.keys(prevFilters).forEach(symbol => {
                updatedFilters[symbol] = {
                    ...prevFilters[symbol],
                    stdDev: newStdDevProtection // Updating stdDev instead of protection
                };
            });
            return updatedFilters;
        });

        // Recalculate the best contracts after global change
        uniqueSymbols.forEach(symbol => updateBestContracts(symbol, 'PUT'));
    };

    const changeGlobalExpiry = (e) => {
        setCurrentSelections({});
        sessionStorage.removeItem('currentSelections');
        setCurrentPutSelections({});
        sessionStorage.removeItem('currentPutSelections');
        const newExpiry = e.target.value;
        setGlobalExpiry(newExpiry);

        // Update all local filters (calls and puts) with the new global expiry
        setCallsLocalFilters(prevFilters => {
            const updatedFilters = {};
            Object.keys(prevFilters).forEach(symbol => {
                updatedFilters[symbol] = {
                    ...prevFilters[symbol],
                    expiry: newExpiry // Ensure expiry is updated correctly
                };
            });
            return updatedFilters;
        });

        setPutsLocalFilters(prevFilters => {
            const updatedFilters = {};
            Object.keys(prevFilters).forEach(symbol => {
                updatedFilters[symbol] = {
                    ...prevFilters[symbol],
                    expiry: newExpiry // Ensure expiry is updated correctly
                };
            });
            return updatedFilters;
        });

        // Recalculate the best contracts after the global expiry change
        uniqueSymbols.forEach(symbol => {
            updateBestContracts(symbol, 'CALL');
            updateBestContracts(symbol, 'PUT');
        });
    };


    const calculateCapOrProtection = (closePrice, strikePrice) => {
        return ((strikePrice / closePrice) * 100).toFixed(2);
    };

    const createLocalFilters = (type) => {
        const localFiltersFromRedux = type === 'CALL' ? callLocalFiltersFromRedux : putLocalFiltersFromRedux;
    
        return Object.keys(type === 'CALL' ? calls : puts).reduce((acc, symbol) => {
            const hedgeableItem = hedgeable.find(item => item.Ticker === symbol);
            const selection = type === 'CALL' ? currentSelections[symbol] : currentPutSelections[symbol];
    
            let capLevel, expiry, stdDev;
    
            if (selection && hedgeableItem) {
                // For current selections
                const strikePrice = selection.strike;
                capLevel = calculateCapOrProtection(hedgeableItem.ClosePrice, strikePrice);
                expiry = new Date(selection.expiry).toISOString().slice(0, 10);
            } else if (localFiltersFromRedux[symbol]) {
                // For redux-stored filters
                capLevel = type === 'CALL'
                    ? localFiltersFromRedux[symbol].capLevel || globalCap
                    : localFiltersFromRedux[symbol].capLevel || globalProtection; // Adjust for protection in PUTs
                expiry = localFiltersFromRedux[symbol].expiry || globalExpiry;
                stdDev = localFiltersFromRedux[symbol].stdDev || (type === 'CALL' ? globalStdDevCap : globalStdDevProtection);
            } else {
                // Fallback to global settings
                capLevel = type === 'CALL' ? globalCap : globalProtection; // Calls use cap, puts use protection
                expiry = globalExpiry;
                stdDev = type === 'CALL' ? globalStdDevCap : globalStdDevProtection;
            }
    
            acc[symbol] = {
                contracts: Math.floor((hedgeableItem?.Quantity) / 100) || 0,
                capLevel, // Unified for both calls and puts, but using globalCap for calls and globalProtection for puts
                expiry,
                stdDev,
            };
            return acc;
        }, {});
    };
    



    const callLocalFiltersFromRedux = useSelector(state => state.selection.CallWriting.localFilters);
    const putLocalFiltersFromRedux = useSelector(state => state.selection.PutBuying.localFilters);
    const [CallsLocalFilters, setCallsLocalFilters] = useState(() =>
        Object.keys(callLocalFiltersFromRedux).length ? callLocalFiltersFromRedux : createLocalFilters('CALL')
    );

    const [PutsLocalFilters, setPutsLocalFilters] = useState(() =>
        Object.keys(putLocalFiltersFromRedux).length ? putLocalFiltersFromRedux : createLocalFilters('PUT')
    );


    const [tabType, setTabType] = useState('CallWriting');


    const parseDate = (dateStr) => new Date(dateStr);

    const findBestContract = (options, targetPrice, expiry) => {
        const targetDate = parseDate(expiry);
    
        // Filter contracts that expire after the targetDate
        const filteredOptions = options.filter(option => {
            const expirationDate = parseDate(option.ExpirationDate);
            return expirationDate && expirationDate.getTime() > targetDate.getTime();
        });
    
        // Sort the filtered options by strike price first, then by expiration date
        const sortedOptions = filteredOptions.sort((a, b) => {
            // Handle undefined/null strike prices
            if (a.Strike == null || b.Strike == null) {
                return a.Strike == null ? 1 : -1; // Sort undefined/null strikes to the end
            }
    
            const strikeDiffA = Math.abs(a.Strike - targetPrice);
            const strikeDiffB = Math.abs(b.Strike - targetPrice);
    
            if (strikeDiffA !== strikeDiffB) {
                return strikeDiffA - strikeDiffB;
            } else {
                // Safely parse dates and handle null/undefined cases
                const dateA = parseDate(a.ExpirationDate);
                const dateB = parseDate(b.ExpirationDate);
    
                if (!dateA || !dateB) {
                    return !dateA ? 1 : -1; // Sort undefined/null dates to the end
                }
    
                const dateDiffA = Math.abs(dateA.getTime() - targetDate.getTime());
                const dateDiffB = Math.abs(dateB.getTime() - targetDate.getTime());
    
                if (dateDiffA !== dateDiffB) {
                    return dateDiffA - dateDiffB;
                } else {
                    // Fallback: Maintain stable sort order based on a unique property (e.g., ID)
                    return a.ID - b.ID;
                }
            }
        });
    
        // Return the best option (first element in sorted array)
        if (sortedOptions.length > 0) {
            const bestOption = sortedOptions[0];
            bestOption.ExpirationDate = formatExpirationDate(bestOption.ExpirationDate);
            return bestOption;
        }
    
        return null;
    };
    


    const filterOutContracts = (filters) => {
        return Object.keys(filters).reduce((acc, symbol) => {
            const { contracts, ...rest } = filters[symbol];
            acc[symbol] = rest;
            return acc;
        }, {});
    };


    const filteredCallsLocalFilters = useMemo(() => filterOutContracts(CallsLocalFilters), [CallsLocalFilters]);
    const filteredPutsLocalFilters = useMemo(() => filterOutContracts(PutsLocalFilters), [PutsLocalFilters]);


    const updateBestContracts = (symbol, type) => {
        const localFilters = type === 'CALL' ? CallsLocalFilters : PutsLocalFilters;
        const bestContractsSetter = type === 'CALL' ? setBestCallContracts : setBestPutContracts;
        const hedgeableItem = hedgeable.find(item => item.Ticker === symbol);
        if (!hedgeableItem) return;

        const currentPrice = hedgeableItem.ClosePrice;
        const options = type === 'CALL' ? calls[symbol] : puts[symbol];
        if (!options) return;

        let bestOption = null;

        if (type === 'CALL') {
            console.log("localFilters[symbol]", localFilters[symbol])
            const { capLevel, expiry, expDate, stdDev } = localFilters[symbol];
            if (useGlobalStdDev) {
                bestOption = selectOptionContractWithinStdDev(options, symbol, currentPrice, stdDev, expiry, type);
            } else {
                const targetPrice = currentPrice * capLevel / 100;
                bestOption = findBestContract(options, targetPrice, expiry);
            }
        } else if (type === 'PUT') {
            console.log("localFilters[symbol]", localFilters[symbol])
            const { capLevel, expiry, expDate, stdDev } = localFilters[symbol];
            if (useGlobalStdDev) {
                bestOption = selectOptionContractWithinStdDev(options, symbol, currentPrice, stdDev, expiry, type);
            } else {
                const targetPrice = currentPrice * capLevel / 100;
                bestOption = findBestContract(options, targetPrice, expiry);
            }
        }

        if (bestOption) {
            bestContractsSetter(prev => ({
                ...prev,
                [symbol]: bestOption,
            }));
        }
    };



    useEffect(() => {
        uniqueSymbols.forEach(symbol => {
            updateBestContracts(symbol, 'CALL');
        });
    }, [globalCap, globalExpiry, optionsData, filteredCallsLocalFilters]);

    useEffect(() => {
        uniqueSymbols.forEach(symbol => {
            updateBestContracts(symbol, 'PUT');
        });
    }, [globalProtection, globalExpiry, optionsData, filteredPutsLocalFilters]);



    useEffect(() => {
        setCallsLocalFilters(createLocalFilters('CALL'));
    }, [globalCap, globalExpiry, hedgeable]);

    useEffect(() => {
        setPutsLocalFilters(createLocalFilters('PUT'));
    }, [globalProtection, globalExpiry, hedgeable]);

    useEffect(() => {
        if (!Object.keys(callLocalFiltersFromRedux).length) {
            dispatch(setLocalFilters(CallsLocalFilters, 'CallWriting'));
        }
        if (!Object.keys(putLocalFiltersFromRedux).length) {
            dispatch(setLocalFilters(PutsLocalFilters, 'PutBuying'));
        }
    }, [CallsLocalFilters, PutsLocalFilters, dispatch]);



    useEffect(() => {
        let totalPremium = 0;
        let totalCost = 0;

        Object.keys(bestCallContracts).forEach(symbol => {
            const bestOption = bestCallContracts[symbol];
            const localFilters = CallsLocalFilters[symbol];
            const premium = ((bestOption.CloseBidPrice + bestOption.CloseAskPrice) / 2) * localFilters.contracts * 100;
            totalPremium += premium;
        });

        Object.keys(bestPutContracts).forEach(symbol => {
            const bestOption = bestPutContracts[symbol];
            const localFilters = PutsLocalFilters[symbol];
            const cost = ((bestOption.CloseBidPrice + bestOption.CloseAskPrice) / 2) * localFilters.contracts * 100;
            totalCost += cost;
        });

        setTotalPremium(totalPremium);
        setTotalCost(totalCost);
    }, [bestCallContracts, bestPutContracts]);  // Removed unnecessary dependencies


    // Functions to change local filters and update the best contracts
    const changeLocalCallFilter = (symbol, key, value) => {
        setCallsLocalFilters(prev => {
            const updatedFilters = { ...prev };

            if (key === 'cap' || key === 'protection') {
                // Map the cap or protection value to capLevel in the reducer
                updatedFilters[symbol].capLevel = value;
            } else if (key === 'expiry') {
                // Map the expiry value to expDate in the reducer
                updatedFilters[symbol].expDate = value;
                updatedFilters[symbol].expiry = value;
            } else {
                // Handle other keys if necessary
                updatedFilters[symbol][key] = value;
            }
            //keep all the other values same and update the key value
            // Dispatch the updated filters to Redux
            dispatch(setLocalFilters(updatedFilters, 'CallWriting'));

            return updatedFilters;
        });

        if (key !== 'contracts') {
            updateBestContracts(symbol, 'CALL');
        }
    };

    const changeLocalPutFilter = (symbol, key, value) => {
        setPutsLocalFilters(prev => {
            const updatedFilters = { ...prev };

            if (key === 'cap' || key === 'protection') {
                // Map the cap or protection value to capLevel in the reducer
                updatedFilters[symbol].capLevel = value;
            } else if (key === 'expiry') {
                // Map the expiry value to expDate in the reducer
                updatedFilters[symbol].expDate = value;
                updatedFilters[symbol].expiry = value;
            } else {
                // Handle other keys if necessary
                updatedFilters[symbol][key] = value;
            }
            // Dispatch the updated filters to Redux
            dispatch(setLocalFilters(updatedFilters, 'PutBuying'));
            return updatedFilters;
        });

        if (key !== 'contracts') {
            updateBestContracts(symbol, 'PUT');
        }
    };



    const GoToOptionChainScreen = (ticker, type) => {
        setSelectedTicker(ticker);
        setTabType(type === 'CALL' ? 'CallWriting' : 'PutBuying');
        setShowDashboardSection(true);
    };

    const selectOptionContractWithinStdDev = (options, ticker, currentPrice, nStdDev, expiryDate, type) => {
        const optionsForTicker = options;

        if (!optionsForTicker || !Array.isArray(optionsForTicker)) {
            console.error('Invalid options for ticker:', ticker);
            return null;
        }
        console.log("expiryDate for ticker", expiryDate, ticker)
        const formattedExpiryDate = new Date(expiryDate).toISOString().split('T')[0];

        // Filter options by the specified expiry date
        let optionsForExpiry = optionsForTicker.filter(option => {
            const optionExpiryDate = new Date(option.ExpirationDate).toISOString().split('T')[0];
            return optionExpiryDate === formattedExpiryDate;
        });

        // If no exact match for expiryDate is found, find the closest expiry date
        if (optionsForExpiry.length === 0) {
            let closestExpiry = null;
            let minDateDifference = Infinity;

            optionsForTicker.forEach(option => {
                const optionExpiryDate = new Date(option.ExpirationDate);
                const dateDifference = Math.abs(optionExpiryDate - new Date(expiryDate));
                if (dateDifference < minDateDifference) {
                    minDateDifference = dateDifference;
                    closestExpiry = option.ExpirationDate;
                }
            });

            // Filter again using the closest expiry date found
            if (closestExpiry) {
                const formattedClosestExpiry = new Date(closestExpiry).toISOString().split('T')[0];
                optionsForExpiry = optionsForTicker.filter(option => {
                    const optionExpiryDate = new Date(option.ExpirationDate).toISOString().split('T')[0];
                    return optionExpiryDate === formattedClosestExpiry;
                });
            }
        }

        let selectedOption = null;
        let minDifference = Infinity;

        optionsForExpiry.forEach(option => {
            const T = (new Date(option.ExpirationDate) - new Date()) / (365 * 24 * 60 * 60 * 1000); // Time to expiry in years
            const stdMove = currentPrice * option.HistoricalVolatility * Math.sqrt(T);

            // Apply nStdDev consistently for CALLs and PUTs
            const targetStrike = type === 'CALL'
                ? currentPrice + nStdDev * stdMove // Upwards for CALLs
                : currentPrice - nStdDev * stdMove; // Downwards for PUTs

            const difference = Math.abs(option.Strike - targetStrike);

            if (difference < minDifference) {
                minDifference = difference;
                selectedOption = option;
            }
        });

        if (selectedOption) {
            selectedOption.ExpirationDate = formatExpirationDate(selectedOption.ExpirationDate);  // Format date before returning
        }

        return selectedOption;
    };


    const handleToggleChange = () => {
        setUseGlobalStdDev(prevUseGlobalStdDev => {
            const newUseGlobalStdDev = !prevUseGlobalStdDev;

            // Recalculate best contracts based on the new state of useGlobalStdDev
            uniqueSymbols.forEach(symbol => {
                updateBestContracts(symbol, 'CALL');
                updateBestContracts(symbol, 'PUT');
            });

            return newUseGlobalStdDev;
        });
    };

    const handleSelectAllChange = (type) => {
        if (type === 'CALL') {
            dispatch(setAllSelected(!allCallSelected, 'CallWriting'));
        } else if (type === 'PUT') {
            dispatch(setAllSelected(!allPutSelected, 'PutBuying'));
        }
    };

    const handleToggleItemSelection = (symbol, isSelected) => {
        // Dispatch both call and put selections together
        dispatch(toggleItemSelection(symbol, isSelected, 'CallWriting'));
        dispatch(toggleItemSelection(symbol, isSelected, 'PutBuying'));
    };




    const RenderOptions = (symbol, type) => {
        const bestContracts = type === 'CALL' ? bestCallContracts : bestPutContracts;
        const localFilters = type === 'CALL' ? CallsLocalFilters : PutsLocalFilters;
        const optionData = data.filter(item => item.symbol === symbol && item.right === type);
        const midPrice = ((bestContracts[symbol]?.CloseBidPrice + bestContracts[symbol]?.CloseAskPrice) / 2).toFixed(2);
        const bidPrice = bestContracts[symbol]?.CloseBidPrice;
        const askPrice = bestContracts[symbol]?.CloseAskPrice;
        const isSelected = callSelectedItems[symbol] && putSelectedItems[symbol];

        return (
            <React.Fragment key={symbol}>
                {optionData.map((item, index) => (
                    <tr key={index}>
                        <td></td>
                        {type === 'PUT' && index === 0 ? (
                            <td>{symbol}</td>
                        ) : (
                            <></>
                        )}
                        <td>{item.right}</td>
                        <td>{item.contracts}</td>
                        <td>{item.cap}</td>
                        <td>{item.expiry}</td>
                        <td>{item.bidPrice}</td>
                        <td>{item.premium}</td>
                    </tr>
                ))}
                <tr className='last-row'>
                    <td className="checkbox-column">
                        <input
                            type="checkbox"
                            checked={!!isSelected}
                            onChange={() => handleToggleItemSelection(symbol, !isSelected)}
                        />
                    </td>
                    {type === 'PUT' && (
                        <td>{exisitngHoldings.includes(symbol) ? '' : symbol}</td>
                    )}
                    <td>
                        <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => GoToOptionChainScreen(symbol, type)}
                        >
                            <SearchIcon fontSize="small" />
                        </IconButton>
                    </td>
                    <td className='con-input'>
                        <input
                            type='number'
                            placeholder='Num Con'
                            value={localFilters[symbol]?.contracts}
                            onChange={(e) => {
                                const maxContracts = Math.floor((hedgeable.find(item => item.Ticker === symbol)?.Quantity) / 100);
                                const value = Math.max(0, Math.min(maxContracts, Number(e.target.value))); // Clamps the value between min and max

                                if (type === 'CALL') {
                                    changeLocalCallFilter(symbol, 'contracts', value);
                                } else {
                                    changeLocalPutFilter(symbol, 'contracts', value);
                                }
                            }}
                            max={Math.floor((hedgeable.find(item => item.Ticker === symbol)?.Quantity) / 100)}
                            min={0}
                        />
                    </td>
                    <td className='cap-input'>
                        {/* Toggle between Cap/Protection and Std Dev based on global control */}
                        {useGlobalStdDev ? (
                            <select
                                className="stddev-select"
                                value={localFilters[symbol]?.stdDev}
                                onChange={(e) =>
                                    type === 'CALL'
                                        ? changeLocalCallFilter(symbol, 'stdDev', e.target.value)
                                        : changeLocalPutFilter(symbol, 'stdDev', e.target.value)
                                }
                            >
                                {Array.from({ length: 31 }, (_, i) => (i) / 10).map(value => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                type='number'
                                placeholder={type === 'CALL' ? 'Cap' : 'Protection'}
                                value={localFilters[symbol]?.capLevel}
                                onChange={(e) =>
                                    type === 'CALL'
                                        ? changeLocalCallFilter(symbol, 'cap', e.target.value)
                                        : changeLocalPutFilter(symbol, 'protection', e.target.value)
                                }
                            />
                        )}
                    </td>
                    <td>{bestContracts[symbol]?.Strike !== undefined ? bestContracts[symbol].Strike.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}</td>
                    <td className='cal-input'>
                        <input
                            type='date'
                            value={bestContracts[symbol]?.ExpirationDate}
                            onChange={(e) =>
                                type === 'CALL'
                                    ? changeLocalCallFilter(symbol, 'expiry', e.target.value)
                                    : changeLocalPutFilter(symbol, 'expiry', e.target.value)
                            }
                            min={new Date().toISOString().split('T')[0]}
                        />
                    </td>
                    <td>{midPrice}</td>
                    <td>${((localFilters[symbol]?.contracts) * 100 * midPrice).toFixed(2)}</td>
                    {/* <td>
                        <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => console.log('Add clicked')}
                        >
                            <AddCircleOutlineIcon fontSize="small" />
                        </IconButton>
                    </td> */}
                </tr>
            </React.Fragment>
        );
    };

    return (
        <div>
            {showDashboardSection ? (
                <DashboardSection
                    portfolioDetails={portfolioDetails}
                    tabType={tabType} // Replace with your appropriate tabType
                    ticker={selectedTicker}
                    isCollar={true}
                />
            ) : (
                <div>
                    <div className="global-control-container">
                        <div className="status-item">
                            <label htmlFor="global-expiry">Global Expiry</label>
                            <input type="date" id="global-expiry" defaultValue={globalExpiry} onChange={(e) => changeGlobalExpiry(e)} />
                        </div>
                        <div className="status-item">
                            <span>Portfolio Holdings: </span>
                            <span className="portfolio-holdings">{totalTickersHeld}</span>
                        </div>
                        <div className="status-item">
                            <span>Portfolio Value: </span>
                            <span className="portfolio-value">${totalPortfolioValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </div>
                        <div className="status-item">
                            <span>
                                {'Protection'}
                            </span>
                            <div className="toggle-switch">
                                <input
                                    type="checkbox"
                                    id="mode-toggle"
                                    checked={useGlobalStdDev}
                                    onChange={handleToggleChange}
                                />
                                <label htmlFor="mode-toggle" className="toggle-label"></label>
                            </div>
                            <span style={{ marginLeft: '10px' }}>
                                {'Std Dev'}
                            </span>
                        </div>
                    </div>
                    <div className="table-container">
                        {/* {RenderSymbolTable()} */}
                        <div className="options-table">
                            <div className='tablescroll'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>
                                                <div className='selector'>
                                                <label htmlFor="global-protection">
                                                    {useGlobalStdDev ? 'Std Dev Prot' : 'Prot'}
                                                </label>
                                                {useGlobalStdDev ? (
                                                    <select
                                                        id="global-expiry"
                                                        className="stddev-select"
                                                        value={globalStdDevProtection}
                                                        onChange={(e) => changeGlobalStdDevProtection(e)}
                                                    >
                                                        {Array.from({ length: 31 }, (_, i) => i / 10).map(value => (
                                                            <option key={value} value={value}>
                                                                {value.toFixed(1)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="number"
                                                        id="global-expiry"
                                                        placeholder='Protection'
                                                        defaultValue={globalProtection}
                                                        onChange={(e) => changeGlobalProtection(e)}
                                                    />
                                                )}
                                                </div>
                                            </th>
                                            <th colSpan={2}>
                                                Holdings: {totalTickersHeld}
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th className="checkbox-column">
                                                <input
                                                    type="checkbox"
                                                    checked={allPutSelected}
                                                    onChange={() => handleSelectAllChange('PUT')}
                                                />
                                            </th>
                                            <th>SYMBOL</th>
                                            <th>RIGHT</th>
                                            <th>Quantity</th>
                                            <th>PROTECTION</th>
                                            <th>STRIKE</th>
                                            <th>EXPIRY</th>
                                            <th>MID</th>
                                            <th>COST</th>
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uniqueSymbols.map(symbol => RenderOptions(symbol, 'PUT'))}
                                        <tr className='EmptyRow'><td></td></tr>                                    
                                    </tbody>
                                </table>
                            </div>
                            <div className='tabletotal'>
                            <div>TOTAL</div>
                            <div>${totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                            </div>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="options-table">
                            <div className='tablescroll'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan={3}>
                                            <div className='selector'>
                                                <label htmlFor="global-control">
                                                    {useGlobalStdDev ? 'Std Dev Level' : 'Cap'}
                                                </label>
                                                {useGlobalStdDev ? (
                                                    <select
                                                        id="global-expiry"
                                                        className="stddev-select"
                                                        value={globalStdDevCap}
                                                        onChange={(e) => changeGlobalStdDevCap(e)}
                                                    >
                                                        {Array.from({ length: 31 }, (_, i) => i / 10).map(value => (
                                                            <option key={value} value={value}>
                                                                {value.toFixed(1)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="number"
                                                        id="global-expiry"
                                                        placeholder='Cap'
                                                        className='ai-form-value small'
                                                        defaultValue={globalCap}
                                                        onChange={(e) => changeGlobalCap(e)}
                                                    />
                                                )}
                                            </div>
                                            </th>

                                            <th colSpan={2}>
                                                Holdings: {totalTickersHeld}
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th className="checkbox-column">
                                                <input
                                                    type="checkbox"
                                                    checked={allCallSelected}
                                                    onChange={() => handleSelectAllChange('CALL')}
                                                />
                                            </th>
                                            <th>RIGHT</th>
                                            <th>Quantity</th>
                                            <th>CAPLevel</th>
                                            <th>STRIKE</th>
                                            <th>EXPIRY</th>
                                            <th>MID</th>
                                            <th>PREMIUM</th>
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uniqueSymbols.map(symbol => RenderOptions(symbol, 'CALL'))}
                                        <tr className='EmptyRow'><td></td></tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div className='tabletotal'>
                                <div colSpan={6} >TOTAL</div>
                                <div colSpan={2}>${totalPremium.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>

    );
}

export default CollarView;
