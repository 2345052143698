// actions/drawerActions.js

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const openDrawer = (drawerName, data = {}) => ({
    type: OPEN_DRAWER,
    payload: {
        drawerName,
        data
    }
});

export const closeDrawer = () => ({
    type: CLOSE_DRAWER
});
