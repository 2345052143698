import React from "react";
import { Tooltip } from 'react-tooltip';
import tooltipContent from './TooltipText';

export default function TooltipComponent(props) {
    const tooltipValue = tooltipContent[props.id];
    if(tooltipValue){
        const tooltipId = `tooltip-${props.id.replace(/[\s()%]/g, "")}`;
        return (
            <>
                {props.id ==='Stock Volatility' || props.id === 'Portfolio Implied Downside Volatility (without Shield)' || props.id ==='Stock Volatility' || props.id === 'Factor' || props.id === 'Benchmark'? (
                    <>
                        &nbsp;<img id={tooltipId} src="Assets/risk-info.png" title={tooltipValue} alt={{tooltipValue}}></img>
                    </>
                ):(
                    <>  
                        &nbsp;<img id={tooltipId} src="Assets/risk-info.png" alt={{tooltipValue}}></img>
                        <Tooltip anchorSelect={`#${tooltipId}`} place="top" variant="info" html={tooltipValue}>
                            {tooltipValue}
                        </Tooltip>  
                    </>
                )}
            </>
        );
    }
}
