import React, { useState } from "react";
import './OnboardClient.scss';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from "react-router-dom";
import Loader from '../../Components/Loader/Loader';
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { addZeroes } from "../../Utilities/AddZeros";
import {PostOnboardImportModelPortfolio} from '../../Apis/PostOnboardImportModelPortfolio';
import { showNotification } from '../../actions/notificationActions';
import TagManager from "react-gtm-module";

function AdaptiveModelPortfolioWizardComponent() {

    var currObj = {
        style: "currency",
        currency: "USD",
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const clientPortfolios = () => history.push("/ClientPortfolios");
    const onBoardScreen = () => history.push("/OnboardScreen");
    const [bdopen, setBdOpen] = React.useState(false);
    const [importStage, setImportStage] = React.useState(1);

    const portfolios = [
        { name: "The Magnificent Seven", value: "The Magnificent Seven" },
        { name: "FAANG", value: "FAANG" },
        { name: "NASDAQ", value: "NASDAQ" },
        { name: "Bill Bernstein No Brainer", value: "Bill Bernstein No Brainer" },
        { name: "Bogleheads Three Funds", value: "Bogleheads Three Funds" },
        { name: "Bogleheads Four Funds", value: "Bogleheads Four Funds" },
        { name: "David Swensen Lazy Portfolio", value: "David Swensen Lazy Portfolio" },
        { name: "Ray Dalio All Seasons", value: "Ray Dalio All Seasons" },
        { name: "Rick Ferri Core Four", value: "Rick Ferri Core Four" },
        { name: "Scott Burns Couch Potato Portfolio", value: "Scott Burns Couch Potato Portfolio" },
        { name: "Stocks Bonds 60 40", value: "Stocks/Bonds (60/40)" },
        { name: "Stocks Bonds 40 60", value: "Stocks/Bonds (40/60)" },
        { name: "Ray Dalio", value: "Ray Dalio"}
    ];

    const [importClients,setImportClients] = useState([{
        external_id:'AddModelClient'+1,
        full_name:'Client '+ 1,
        portfolios:[{
            model_portfolio_name: portfolios[0]['value'],
            portfolio_name: portfolios[0]['value'],
            account_id:`${Math.floor(Date.now() / 1000)}_${0}`,
            total_portfolio_value:1000000.00,
            format_portfolio_value:Number(addZeroes(Number(parseFloat("100000.00").toFixed(2)))).toLocaleString("en-US", currObj)
        }]
      }]);

    const [importClientsErrors,setImportClientsErrors] = useState([{
        external_id:'',
        full_name:'',
        portfolios:[{
            model_portfolio_name:'',
            portfolio_name:'',
            account_id:'',
            total_portfolio_value:''
        }]
    }]);

    const handleAddClient = () => {
        setImportClients([...importClients,{
            external_id:'AddModelClient'+Number(importClients.length + 1),
            full_name:'Client '+ Number(importClients.length + 1),
            portfolios:[{
                model_portfolio_name: portfolios[0]['value'],
                portfolio_name: portfolios[0]['name'],
                account_id:`${Math.floor(Date.now() / 1000)}_${0}`,
                total_portfolio_value:1000000.00,
                format_portfolio_value:Number(addZeroes(Number(parseFloat("100000.00").toFixed(2)))).toLocaleString("en-US", currObj)
            }]
        }]);

        setImportClientsErrors([...importClientsErrors,{
            external_id:'',
            full_name:'',
            portfolios:[{
                model_portfolio_name:'',
                portfolio_name:'',
                account_id:'',
                total_portfolio_value:''
            }]
        }]);
    };

    const handleDeleteClient = (clientIndex) => {
        const deletedClient = [...importClients];
        deletedClient.splice(clientIndex, 1);
        setImportClients([...deletedClient]);

        const deletedClientErrors = [...importClientsErrors];
        deletedClientErrors.splice(clientIndex, 1);
        setImportClientsErrors([...deletedClientErrors]);
    };

    function changeValue(event,value,clientIndex,portfolioIndex) {
        const updatedImportedModelClients = [...importClients];
        let trimmedValue = value.trim().replace(/[^0-9.]/g, '');

        if (event.key === "Enter") {
            let float_value = parseFloat(trimmedValue);
            if (isNaN(float_value) || trimmedValue === "") {
                importClientsErrors[clientIndex].portfolios[portfolioIndex].total_portfolio_value = "Please enter a valid value to proceed further."
                return;
            }
            if (float_value === 0) {
                importClientsErrors[clientIndex].portfolios[portfolioIndex].total_portfolio_value = "Value cannot be zero. Please enter a valid value."
                return;
            }
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].total_portfolio_value = float_value;
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].format_portfolio_value = (Number(addZeroes(Number(float_value.toFixed(2)))).toLocaleString("en-US", currObj));
        }
        setImportClients([...updatedImportedModelClients])
    }

    function handleChangeValue(event, value) {
        let text = event.target.value.replace(/[^\d\.]/gi, '');
        let lastCharIsAdot = text.substr(text.length - 1, 1) === ".";
        event.target.value = Number(text).toLocaleString("en-US");
        if (lastCharIsAdot) event.target.value += ".";
        let float_value = parseFloat(event.target.value.replace(/,/g, ''));
        
        return {format_value:float_value ,portfolio_value:event.target.value}
    }

    const validatePortfolioName = (portfolio_name,clientIndex,portfolioIndex) => {
        let error = [...importClientsErrors];
        let validFlag = true;
        if(!portfolio_name){
          validFlag = false;
          error[clientIndex].portfolios[portfolioIndex].portfolio_name = "Please enter portfolio name";
        }
        else{
          if(portfolio_name && !(/^[A-Za-z]+[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-\s]*$/.test(portfolio_name))) {
            validFlag = false;
            error[clientIndex].portfolios[portfolioIndex].portfolio_name = "Invalid Portfolio Name";
          }
          else{
            error[clientIndex].portfolios[portfolioIndex].portfolio_name = ""; 
            validFlag = true;
          }
        }
        setImportClientsErrors([...error]);
        return validFlag;
    }

    const validateClientName = (client_name,clientIndex) => {
        let error = [...importClientsErrors];
        let validFlag = true;
        if(!client_name){
          validFlag = false;
          error[clientIndex].full_name = "Please Enter Client Name";
        }
        else{
          if(client_name && !(/^[A-Za-z0-9. ]+$/.test(client_name))) {
            validFlag = false;
            error[clientIndex].full_name = "Invalid Client Name";
          }
          else{
            error[clientIndex].full_name = ""; 
            validFlag = true;
          }
        }
        setImportClientsErrors([...error]);
        return validFlag;
    }

    const validatePortfolioValue = (portfolio_value,clientIndex,portfolioIndex) =>{
        let error = [...importClientsErrors];
        let validFlag = true;
        if(!portfolio_value){
            validFlag = false;
            error[clientIndex].portfolios[portfolioIndex].total_portfolio_value = "Please Enter a Portfolio Value";
        } else{
            if(portfolio_value && !(/^-?[0-9]\d*\.?\d*$/.test(portfolio_value))) {
                validFlag = false;
                error[clientIndex].portfolios[portfolioIndex].total_portfolio_value = "Please Enter a Portfolio Value";
              } else{
                error[clientIndex].portfolios[portfolioIndex].total_portfolio_value = ""; 
                validFlag = true;
            }
        }
        setImportClientsErrors([...error]);
        return validFlag;
    }

    const handleImportModelClients = (value, clientIndex,portfolioIndex, property,event) => {
        const updatedImportedModelClients = [...importClients];
        if (property === 'ClientName') {
            validateClientName(value,clientIndex)
            updatedImportedModelClients[clientIndex]['full_name'] = value;
        } else if (property === 'externalId') {
            updatedImportedModelClients[clientIndex]['external_id'] = value;
        } else if (property === 'selectModelPortfolio') {
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].model_portfolio_name = value;
            var port_name = portfolios.filter((port) => port.value === value).map((port) =>port.name)[0]
            validatePortfolioName(port_name,clientIndex,portfolioIndex)
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].portfolio_name = port_name;
        } else if (property === 'portfolioName') {
            validatePortfolioName(value,clientIndex,portfolioIndex)
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].portfolio_name = value;
        } else if (property === 'portfolioTotalValue') {
            var result = handleChangeValue(event,value)
            validatePortfolioValue(result.portfolio_value,clientIndex,portfolioIndex)
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].format_portfolio_value = result.portfolio_value;
            updatedImportedModelClients[clientIndex].portfolios[portfolioIndex].total_portfolio_value = result.format_value;
        }
        setImportClients([...updatedImportedModelClients])
    };

    const validateOnSubmit = () =>{
        let validFlag = true;
        if(importClients.length !== 0){
          importClients.map((client,clientIndex) => {
            if(!validateClientName(client.full_name,clientIndex)){
              validFlag = false
            }
            client.portfolios.map((portfolio,portfolioIndex) => {
              if(!validatePortfolioName(portfolio.portfolio_name,clientIndex,portfolioIndex)){
                validFlag = false
              }
              if(!validatePortfolioValue(portfolio.total_portfolio_value,clientIndex,portfolioIndex)){
                validFlag = false
              }
              delete portfolio.format_portfolio_value;
            })
          })
        }
        return validFlag;
    }

    const handleOnSubmit = async () => {
        if(validateOnSubmit()){
            setBdOpen(true);
            const response = await PostOnboardImportModelPortfolio(importClients);
            if (response.status === 200) {
                TagManager.dataLayer({
                    dataLayer: {
                      event: 'Onboard Client Portfolio',
                      userType: sessionStorage.getItem("selectedAcType"),
                      user_id: sessionStorage.getItem("clientId"),
                      onboarding_type: 'Model Portfolio'
                    },
                  });
                dispatch(showNotification("Import Model Portfolio Successful", "success", "toaster", null, false));
                setImportStage(2);
          } else {
            handleApiError(response, dispatch);
          }
          setBdOpen(false);
        }
      };
    

    return(
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            {bdopen ? (
                <div className="loading-overlay">
                    <Loader/>
                </div>) : 
                (
                    <>
                        <h1 className="title">Adaptive Import Wizard</h1>
                        <div className="onboarding">
                            <div className={importStage === 1 ? 'onboardstep active' : 'onboardstep'}>
                                <div className="icon">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6797 22.6667H21.3197" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.6797 17.3334H21.3197" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3333 12H16.7133C16.268 12 15.8507 11.7773 15.604 11.4067L13.7293 8.59467C13.4813 8.22267 13.0653 8 12.62 8H6.66667C5.19333 8 4 9.19333 4 10.6667V25.3333C4 26.8067 5.19333 28 6.66667 28H25.3333C26.8067 28 28 26.8067 28 25.3333V14.6667C28 13.1933 26.8067 12 25.3333 12Z" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.66699 8V6.66667C6.66699 5.19333 7.86033 4 9.33366 4H22.667C24.1403 4 25.3337 5.19333 25.3337 6.66667V12" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p className="round_title_text">Select Model Portfolio</p>
                            </div>
                            <div className={importStage === 2 ? 'onboardstep active' : 'onboardstep'}>
                                <div className="icon">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6667 28.0001H6.66667C5.19333 28.0001 4 26.8067 4 25.3334V13.3334C4 11.8601 5.19333 10.6667 6.66667 10.6667H18.6667C20.14 10.6667 21.3333 11.8601 21.3333 13.3334V25.3334C21.3333 26.8067 20.14 28.0001 18.6667 28.0001Z" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9.5 19.8785L11.612 21.9905L16.2213 17.3799" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9.33301 10.6667V6.66667C9.33301 5.19333 10.5263 4 11.9997 4H25.333C26.8063 4 27.9997 5.19333 27.9997 6.66667V20C27.9997 21.4733 26.8063 22.6667 25.333 22.6667H21.333" stroke="#8A92A6" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p className="round_title_text">Complete</p>
                            </div>
                        </div>
                        {importStage === 1 && (
                            <div className="client-portfolio center">
                                <div className="modalcon">
                                {importClients.map((client, index) => (
                                    <div key={index} className="ai-row">
                                        <div className="ai-form-group">
                                            <label className="ai-form-label">Client Name:</label>
                                            <input
                                                id='ClientName'
                                                type="text"
                                                className="ai-form-value"
                                                defaultValue={client.full_name}
                                                onChange={(e) => handleImportModelClients(e.target.value, index, 'ClientName')}
                                            />
                                            <p className='ai-error-message'>{importClientsErrors[index].full_name}</p>
                                        </div>
                                        <div className="ai-form-group">
                                            <label className="ai-form-label">External ID (Optional):</label>
                                            <input
                                                id='externalId'
                                                type="text"
                                                className="ai-form-value"
                                                onChange={(e) => handleImportModelClients(e.target.value, index, 'externalId')}
                                            />
                                        </div>
                                        {client.portfolios.map((portfolio,portfolioIndex) => (
                                            <>
                                                <div className="ai-form-group">
                                                    <label className="ai-form-label">Select Model Portfolio:</label>
                                                    <Select
                                                        id='selectModelPortfolio'
                                                        className="ai-form-value-bt"
                                                        value = {portfolio.model_portfolio_name}
                                                        onChange={(e) => handleImportModelClients(e.target.value, index,portfolioIndex, 'selectModelPortfolio')}
                                                    >
                                                        {portfolios.map((value, i) => (
                                                            <MenuItem key={i} value={value.value}>{value.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="ai-form-group">
                                                    <label className="ai-form-label">Portfolio Name:</label>
                                                    <input
                                                        id='portfolioName'
                                                        type="text"
                                                        className="ai-form-value"
                                                        value={portfolio.portfolio_name}
                                                        onChange={(e) => handleImportModelClients(e.target.value, index,portfolioIndex, 'portfolioName')}
                                                    />
                                                    <p className='ai-error-message'>{importClientsErrors[index].portfolios[portfolioIndex].portfolio_name}</p>
                                                </div>
                                                <div className="ai-form-group">
                                                    <label className="ai-form-label">Portfolio Value:</label>
                                                    <input
                                                        id='portfolioTotalValue'
                                                        type="text"
                                                        className="ai-form-value"
                                                        onChange={(e) => handleImportModelClients(e.target.value, index,portfolioIndex, 'portfolioTotalValue',e)}
                                                        value={portfolio.format_portfolio_value}
                                                        onKeyUp={(e) =>changeValue(e,e.target.value,index,portfolioIndex)}
                                                    />
                                                    <p className='ai-error-message'>{importClientsErrors[index].portfolios[portfolioIndex].total_portfolio_value}</p>
                                                </div>
                                                <button className="ai-btn round" onClick={() => handleDeleteClient(index)}>
                                                    <img src="./Assets/trash.svg" alt="toggle" />
                                                </button>
                                            </>
                                        ))}
                                    </div>
                                ))}
                                <div className="button-div">
                                    <button className="ai-btn line solid primary" onClick={handleAddClient}>
                                        Add New
                                    </button>
                                </div>
                                </div>
                                <div className="button-div">
                                    <button className="ai-btn line secondary" onClick={() => onBoardScreen()}>
                                        Skip
                                    </button>
                                    <button className="ai-btn line primary" onClick={handleOnSubmit}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}
                        {importStage === 2 &&
                            (
                                <div className="welcome">
                                    <img src="./Assets/Single.png"></img>
                                    <h2>
                                        Portfolio successfully imported.
                                    </h2>
                                    <div className="button-div">
                                        {/* <button className="ai-btn line primary" onClick={() => setImportStage(1)}>
                                            Upload Another
                                        </button> */}
                                        <button className="ai-btn solid secondary" onClick={() => clientPortfolios()}>
                                            Go to  Dashboard
                                        </button>
                                    </div>
                                </div>
                            )}
                    </>
                )}
        </>
    )

}

export default AdaptiveModelPortfolioWizardComponent;