import { GetMarketShieldPlus } from '../Apis/GetMarketShieldPlus';
import { handleApiError } from './notificationActions';

export const FETCH_SHIELD_DATA_REQUEST = 'FETCH_SHIELD_DATA_REQUEST';
export const FETCH_SHIELD_DATA_SUCCESS = 'FETCH_SHIELD_DATA_SUCCESS';
export const FETCH_SHIELD_DATA_FAILURE = 'FETCH_SHIELD_DATA_FAILURE';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';
export const RESET_SELECTED_INDEX = 'RESET_SELECTED_INDEX';

export const fetchShieldDataRequest = (key) => ({
    type: FETCH_SHIELD_DATA_REQUEST,
    payload: key
});

export const fetchShieldDataSuccess = (key, data) => ({
    type: FETCH_SHIELD_DATA_SUCCESS,
    payload: { key, data }
});

export const fetchShieldDataFailure = (key, error) => ({
    type: FETCH_SHIELD_DATA_FAILURE,
    payload: { key, error }
});

export const setSelectedIndex = (index) => ({
    type: SET_SELECTED_INDEX,
    payload: index
});

export const resetSelectedIndex = () => ({
    type: RESET_SELECTED_INDEX,
});

const sanitizeApiResponse = (data) => {
    if (Array.isArray(data)) {
        return data.map((item) => sanitizeApiResponse(item));
    } else if (data !== null && typeof data === 'object') {
        return Object.keys(data).reduce((acc, key) => {
            const value = data[key];
            acc[key] = isNaN(value) && typeof value === "number" ? null : sanitizeApiResponse(value);
            return acc;
        }, {});
    }
    return data;
};

export const fetchShieldDataIfNeeded = (portfolioId, formattedDate, index = null) => async (dispatch, getState) => {
    const key = `${portfolioId}_${formattedDate}_${index}`;
    const state = getState();
    if (!state.shieldData.data[key] && !state.shieldData.loading[key]) {
        sessionStorage.removeItem('filteredTickers');
        sessionStorage.removeItem('stockOptions');
        sessionStorage.removeItem('selectedRecommendations');
        const existingHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};

        if (existingHoldings['ShieldRecommendation']) {
            delete existingHoldings['ShieldRecommendation'];
            sessionStorage.setItem('existingOptionHoldings', JSON.stringify(existingHoldings));
            console.log("Removed ShieldRecommendation from existingOptionHoldings");
        }
        dispatch(fetchShieldDataRequest(key));
        try {
            const shieldDataResponse = await GetMarketShieldPlus(portfolioId, formattedDate, index);
            console.log("shieldDataResponse", shieldDataResponse)
            if (shieldDataResponse.status === 200) {
                const shieldData = shieldDataResponse.result;
                dispatch(fetchShieldDataSuccess(key, { shieldData }));
            } else {
                handleApiError(shieldDataResponse, dispatch);
                dispatch(fetchShieldDataFailure(key, 'Error fetching data'));
            }
        } catch (error) {
            dispatch(fetchShieldDataFailure(key, error.toString()));
            handleApiError(error, dispatch);
        }
    }
};
