import { handleApiError } from './notificationActions';
import { FetchAvailableTickers } from "../Apis/FetchAvailableTickers";

export const FETCH_AVAILABLE_TICKERS_START = 'FETCH_AVAILABLE_TICKERS_START';
export const FETCH_AVAILABLE_TICKERS_SUCCESS = 'FETCH_AVAILABLE_TICKERS_SUCCESS';
export const FETCH_AVAILABLE_TICKERS_FAILURE = 'FETCH_AVAILABLE_TICKERS_FAILURE';

export const fetchAvailableTickers = () => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch({ type: FETCH_AVAILABLE_TICKERS_START });

        try {
            const response = await FetchAvailableTickers();
            if(response.status === 200){
                dispatch({ 
                    type: FETCH_AVAILABLE_TICKERS_SUCCESS, 
                    payload: {  
                        details: response.result, 
                    } 
                });
                resolve(response.result);
            } else {
                handleApiError(response, dispatch);
                reject(response);
            }
        } catch (error) {
            dispatch({ 
                type: FETCH_AVAILABLE_TICKERS_FAILURE, 
                payload: {error: error.toString() } 
            });
            reject(error);
        }
    });
};


// export const fetchAvailableTickers = () => async (dispatch) => {
//     dispatch({ type: FETCH_AVAILABLE_TICKERS_START });

//     try {
//         const response = await FetchAvailableTickers();
//         if(response.status === 200){
//             dispatch({ 
//                 type: FETCH_AVAILABLE_TICKERS_SUCCESS, 
//                 payload: {  
//                     details: response.result, 
//                 } 
//             });
//         }else{
//             handleApiError(response, dispatch);
//         }
//     } catch (error) {
//         dispatch({ 
//             type: FETCH_AVAILABLE_TICKERS_FAILURE, 
//             payload: {error: error.toString() } 
//         });
//     }
//     return Promise.resolve();
// };