import React from 'react';
import "./BackTestV2.scss";
import TooltipComponent from '../TooltipComponent/TooltipComponent';

function BacktestV2RiskTable({ rawData, showPortfolioShield }) {
    return (
        <div id='backtestriskreturnratio' style={{ textAlign: "center", padding: "20px 0" }}>
            <table className="risk-return-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Portfolio with Index Put</th>
                        <th>Portfolio Without Shield</th>
                        <th>{`Asset Allocation (${rawData['asset-allocation-ratio']})`}</th>
                        <th>{`${rawData['index']} Index`}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Annual Returns</td>
                        <td>{parseFloat(parseFloat(rawData['returns']['port_w_put_index']).toFixed(4) * 100).toFixed(2)}%</td>
                        <td>{parseFloat(parseFloat(rawData['returns']['port']).toFixed(4) * 100).toFixed(2)}%</td>
                        <td>{parseFloat(parseFloat(rawData['returns']['port_w_asset_allocation']).toFixed(4) * 100).toFixed(2)}%</td>

                        <td>{parseFloat(parseFloat(rawData['returns']['idx']).toFixed(4) * 100).toFixed(2)}%</td>
                    </tr>

                    <tr>
                        <td>Downside Risk</td>
                        <td>{parseFloat(parseFloat(rawData['downside-risk']['port_w_put_index']).toFixed(4) * 100).toFixed(2)}%</td>
                        <td>{parseFloat(parseFloat(rawData['downside-risk']['port']).toFixed(4) * 100).toFixed(2)}%</td>
                        <td>{parseFloat(parseFloat(rawData['downside-risk']['port_w_asset_allocation']).toFixed(4) * 100).toFixed(2)}%</td>

                        <td>{parseFloat(parseFloat(rawData['downside-risk']['idx']).toFixed(4) * 100).toFixed(2)}%</td>
                    </tr>
                    <tr>
                        <td>Return/Risk Ratio<TooltipComponent id={'Risk Return Ratio'} /></td>
                        <td>{parseFloat(rawData['return-ratio']['port_w_put_index']).toFixed(2)}</td>
                        <td>{parseFloat(rawData['return-ratio']['port']).toFixed(2)}</td>
                        <td>{parseFloat(rawData['return-ratio']['port_w_asset_allocation']).toFixed(2)}</td>

                        <td>{parseFloat(rawData['return-ratio']['idx']).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default BacktestV2RiskTable;
