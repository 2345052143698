import React from "react";
import TagManager from "react-gtm-module";
import { addZeroes } from "../../Utilities/AddZeros";
import MediumFont from "../../fonts/Inter-Medium.woff";
import SemiBoldFont from "../../fonts/Inter-SemiBold.woff";
import BoldFont from "../../fonts/Inter-Bold.woff";
import RegularFont from "../../fonts/Inter-V.ttf";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Font,
  View,
  Image as PDFImage,
  Svg,
  Link
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import * as elements from "./PdfElements";

export default function PdfComponent(props) {
    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      image: {
        marginVertical: 10,
        textAlign: "left",
        height: 35,
        width: 120,
      },
      footer: {
        position: "absolute",
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "left",
        color: "#3E4968",
        paddingLeft: 30,
        paddingRight: 30,
      },
      note: {
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "left",
        color: "#3E4968",
        paddingLeft: 30,
        paddingRight: 30,
      },
      pagetitle:{
        padding:20,
        color: "#e58b76",
        fontSize: 28,
        fontFamily: "Inter-Bold",
        display: 'flex',
        textAlign:"center",
        flexDirection: 'row',
        columnGap: 32,
        marginTop: 100,
        marginBottom: 50,   
        margin:0,
        width:"100%"
    },
      title:{
        textAlign: "center",
        color: "#e58b76",
        paddingTop: 20,
        paddingLeft: 30,
        paddingRight: 30,
        bottom:20,
      },
      indexlink:{
        color:"#434e6c",
        fontSize:12,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom:"1px #bdc2c7 dotted",
      },
    });

    const renderDynamicComponent = (componentData) => {
      const { chartName, ImgData, tableHeader, tableData, totalSums, customNote, displayElement, userSelectedData,activeTab } = componentData;
      if(chartName.includes('Table')){
          if(['Factor_Analysis_Table_1','Factor_Analysis_Table_2'].indexOf(chartName) !== -1){
              return (elements.FactorTables(tableHeader, tableData,displayElement,props.portfolioDetails.PortfolioName));
          }else if (['Summary_Analytics_Table_1'].indexOf(chartName) !== -1){
              return (elements.SummaryTable(tableHeader,tableData,displayElement));
          } else{
              return (elements.GenerateTable(tableHeader,tableData,totalSums,displayElement,props.portfolioDetails.TotalPortfolioValue,userSelectedData,activeTab,chartName));
          }
      } else{
          return (elements.GenerateChart(ImgData,displayElement,userSelectedData,activeTab));
      }
    };

    const DownloadPdf = async () => {
        await new Promise(resolve => {
          const intervalId = setInterval(() => {
            if (props !== undefined) {
              clearInterval(intervalId);
              resolve();
            }
          }, 1000);  
        });

        const header = ReactDOMServer.renderToStaticMarkup(elements.headerElement(props.portfolioDetails.PortfolioName,props.portfolioDetails.PortfolioLineItem.Hedgeable.length, props.portfolioDetails.TotalPortfolioValue))
        const MyDocument = () => (
            <Document>
              <Page size='A3' style={styles.body} wrap>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.coverPageElement(props['advisorProfile'], props.portfolioDetails.PortfolioName))}</Html>
                <Text style={styles.pagetitle}>Index</Text>
                {Object.keys(props.pdfSummaryData).map((sectionKey, index) => {
                  const sectionData = props.pdfSummaryData[sectionKey];
                  if (sectionData.displayElement) {
                    return (
                      <Link key={sectionKey} src={`#${sectionKey}`} style={styles.indexlink}>
                         <Text>{`${sectionData.elementTitle}`}</Text>
                      </Link>
                    );
                  }
                  return null;
                })}
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              {Object.keys(props.pdfSummaryData).map((sectionKey,index) => {
                const sectionData = props.pdfSummaryData[sectionKey];
                if(sectionData.displayElement){
                  return (
                    <Page size="A3" style={styles.body} wrap key={sectionKey} id={sectionKey}>
                      <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                      <Html resetStyles>{header}</Html>
                      {sectionData.elementTitle ? <Text style={styles.title}>{sectionData.elementTitle}</Text> : null}
                      <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(renderDynamicComponent(sectionData))}</Html>
                      <Html>{ReactDOMServer.renderToStaticMarkup(elements.customNote(sectionData.customNote))}</Html>
                      <Text style={styles.footer} fixed>{elements.footerText}</Text>
                    </Page>
                  )
                }}
              )}
            </Document>
        );
        const blob = await pdf(<MyDocument />).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Portfolio-Report.pdf';
        link.click();
        TagManager.dataLayer({
            dataLayer: {
            event: 'Analytics Report Downloaded',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
            },
        });
    }

    return DownloadPdf;

}