import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OnboardClient from '../../Components/OnboardClientPortfolio/OnboardClient';
import './OnBoardClientPortfolioScreen.css';

function OnBoardClientPortfolioScreen() {
    const history = useHistory();

    useEffect(() => {
        const subscriptionStatus = sessionStorage.getItem('subscriptionStatus');
        if (subscriptionStatus === 'active' && history.location.state?.stage !== 3) {
            history.push('/ClientPortfolios');
        }
    }, [history]);

    return (
        <>
            <main className="onboard_wrap">
                <OnboardClient />
            </main>
        </>
    );
}

export default OnBoardClientPortfolioScreen;
