export function formatContract(contractString) {
    if (!contractString || contractString === '-') return null;

    // Adjusted regex pattern to match your contract string format
    const regex = /^([A-Z]+)(20[0-9]{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(P|C)([0-9]+(?:\.[0-9]+)?)$/;

    const match = contractString.match(regex);
    if (!match) return null;

    const [_, ticker, year, month, day, optionType, price] = match;

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const formattedMonth = monthNames[parseInt(month, 10) - 1];
    const formattedDay = parseInt(day, 10);
    const formattedDate = `${formattedMonth} ${formattedDay} ${year}`;

    const formattedOptionType = optionType === 'P' ? 'PUT' : 'CALL';

    return `${ticker} ${formattedDate} $${price}`;
}

export function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    const formattedDay = day;
    const formattedMonth = monthNames[monthIndex];
    
    return `${formattedMonth} ${formattedDay} ${year}`;
}

export function formatContractThinkPipeFormat(contractString, optionType) {
    if (!contractString || contractString === '-') return null;

    const monthMapping = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04',
        May: '05', Jun: '06', Jul: '07', Aug: '08',
        Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };

    if (optionType === '') {
        const regex = /^([A-Z]+)([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(P|C)([0-9]+(?:\.[0-9]+)?)$/;
        const match = contractString.match(regex);
        if (!match) return null;

        const [_, ticker, year, month, day, optType, price] = match;
        return [`.${ticker}${year.slice(-2)}${month}${day}${optType}${price}`, optType === 'P'? 'S':'B'];
    } else {
        const regex = /^([A-Z]+)\s+([A-Za-z]{3})\s+([0-9]{1,2})\s+([0-9]{4})\s+\$(\d+(\.\d{1,2})?)$/;
        const match = contractString.match(regex);
        if (!match) return null;

        const [_, ticker, month, day, year, price] = match;
        const formattedContract = `.${ticker}${year.slice(-2)}${monthMapping[month]}${day}${optionType}${price}`;
        return [formattedContract, optionType === 'P'? 'S':'B'];
    }
}



export function formatOptionSymbol(contractString) {
    if (!contractString) return '';

    // Match the contract format using regex
    const regex = /^([A-Z]+)(20[0-9]{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])(C|P)([0-9]+(?:\.[0-9]+)?)$/;
    const match = contractString.match(regex);

    if (!match) {
        console.error("Invalid contract format:", contractString);
        return contractString; // Return original if the format is invalid
    }

    // Extract parts from the match
    const [, ticker, year, month, day, type, strike] = match;

    // Format the year to 2 digits
    const shortYear = year.slice(-2);

    // Format the strike price with one decimal point
    const formattedStrike = parseFloat(strike).toFixed(1);

    // Construct the formatted option symbol
    return `.${ticker}${shortYear}${month}${day}${type}${formattedStrike}`;
}
