import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function UpdatePortfolio(portfolio_id,portfolio_name,pli) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };
  //const session_id = sessionStorage.getItem("sessionID");

    var raw = "";
    if(pli.length > 0){
        raw = JSON.stringify({
            session_id: Token,
            portfolio_id: portfolio_id,
            portfolio_name:portfolio_name,
            pli:pli,
        });
    } else{
        raw = JSON.stringify({
            session_id: Token,
            portfolio_id: portfolio_id,
            portfolio_name:portfolio_name
        });
    }
    var requestOptions = {
        method: "PATCH",
        body: raw,
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_PATCH_EDIT_PORTFOLIO_API,
                requestOptions
            );
            const message = await response.json();
            if (!response.ok) {
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
