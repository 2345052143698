import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import './AnalyticsDrawer.scss';
import RiskContribution from '../../Components/RiskContribution/RiskContribution';
import SummaryAnalytics from '../SummaryAnalytics/SummaryAnalytics';
import FactorAnalysis from '../../Components/FactorAnalysis/FactorAnalysis';
import BackTestV2 from '../../Components/BackTestV2/BackTestV2';
import ForwardTest from '../../Components/ForwardTest/ForwardTest';
import Toaster from '../../Components/Toaster/Toaster';
import Loader from '../../Components/Loader/Loader';
import { togglePortfolioSelection } from '../../actions/portfolioActions';
import PdfSummary from '../PDFComponent/PdfSummary';

function AnalyticsDrawer() {

    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const [isLoading, setIsLoading] = useState(false);
    const [activeTabLabel, setActiveTabLabel] = useState('Summary Analytics');

    const tabs = [
        { label: 'Summary Analytics', key: 'summaryAnalytics', component: SummaryAnalytics },
        { label: 'Risk Contribution', key: 'riskContribution', component: RiskContribution },
        { label: 'Factor Analysis', key: 'factorAnalysis', component: FactorAnalysis },
        { label: 'Backtest', key: 'Backtest', component: BackTestV2 },
        { label: 'Forward Test', key: 'forwardTest', component: ForwardTest },
        { label: 'PDF Summary', key: 'pdfSummary', component: PdfSummary },
    ];

    const renderTabComponent = (tab) => {
        if (activeTabLabel === tab.label) {
            const Component = tab.component;
            return <Component
                portfolioId={drawerData.portfolioId}
                isEmpty={drawerData.isEmpty}
                activeTabLabel={activeTabLabel} />;
        }
        return null;
    };

    const handleCloseDrawer = (portfolioId) => {
        dispatch(togglePortfolioSelection(portfolioId));
        dispatch(closeDrawer());
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawer">
                {isLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>) : (
                    <>
                        <div className="drawer-header">
                            <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                            <h1>{drawerData.Name} / {drawerData.portfolio}</h1>
                            <div className="drawerbottom actions">
                                <button className='ai-btn primary solid'
                                    onClick={() => dispatch(openDrawer('Recommendation', { portfolioId: drawerData.portfolioId, Name: drawerData.Name, portfolio: drawerData.portfolio, isEmpty: drawerData.isEmpty }))}>Shield Recommendations</button>
                                <button className="close-drawer" onClick={() => handleCloseDrawer(drawerData.portfolioId)}>✕</button>                         
                                {/* <button className='ai-btn secondary solid' onClick={() => handleDownloadClick()}>Download Report</button> */}                                
                            </div>
                        </div>
                        <div className="tab">
                            {tabs.map(tab => (
                                <button
                                    key={tab.label}
                                    onClick={() => setActiveTabLabel(tab.label)}
                                    className={activeTabLabel === tab.label ? 'active' : ''}
                                >
                                    {tab.label}
                                    {activeTabLabel === 'Forward Test' && tab.label === 'Forward Test' && (
                                        <span className="beta-v">Beta</span>
                                    )}
                                </button>
                            ))}
                        </div>
                        {tabs.map(tab => renderTabComponent(tab))}

                    </>
                )}
            </div>
        </>
    );
};

export default AnalyticsDrawer;
