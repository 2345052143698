import {
    FETCH_USER_PICTURE_START,
    FETCH_USER_PICTURE_SUCCESS,
    FETCH_USER_PICTURE_FAILURE,
} from '../actions/userPictureActions';

const initialState = {
    userPictureData: {},
    isLoading: false,
    error: null,
};



const userPictureReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_PICTURE_START:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_USER_PICTURE_SUCCESS:
            const updatedDetails = Object.fromEntries(
                Object.entries(action.payload.details).map(([key, value]) => [
                    key,
                    value ? `data:image/jpeg;base64,${value}` : ''
                ])
            );
            return {
                ...state,
                isLoading: false,
                userPictureData: updatedDetails,
                error: null,
            };
        case FETCH_USER_PICTURE_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};

export default userPictureReducer;
