import React from "react";
import { addZeroes } from "../../Utilities/AddZeros";
import MediumFont from "../../fonts/Inter-Medium.ttf";
import SemiBoldFont from "../../fonts/Inter-SemiBold.ttf";
import BoldFont from "../../fonts/Inter-Bold.ttf";
import RegularFont from "../../fonts/Inter-Regular.ttf";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import {
    Page,
    Text,
    Document,
    StyleSheet,
    PDFDownloadLink,
    pdf,
    Font,
    View,
    Image as PDFImage,
    Svg,
  } from "@react-pdf/renderer";
import { Center } from "@chakra-ui/react";

    Font.register({ family: "Inter-Bold", src: BoldFont });
    Font.register({ family: "Inter-V", src: RegularFont });
    Font.register({ family: "Inter-SemiBold", src: SemiBoldFont });
    Font.register({ family: "Inter-Medium", src: MediumFont });

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          fontSize: 14,
        },
        image: {
          marginVertical: 15,
          textAlign: "left",
          height: 40,
          width: 150,
        },
        p:{
            fontSize: 14, 
        },
        header: {
          fontSize: 12,
          marginBottom: 20,
          textAlign: "center",
          color: "grey",
        },
        footer: {
          position: "absolute",
          fontSize: 10,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: "left",
          color: "#3E4968",
          paddingLeft: 30,
          paddingRight: 30,
        },
        tableMain:{
            borderCollapse: "collapse",
        },
        summaryHeader: {
          color: "#3E4968",
          fontSize: 18,
          textAlign: "left",
          fontWeight: "bold",
          marginVertical: 15,
          fontFamily: "Inter-Bold",
        },
        tableheader: {
          backgroundColor: "#ffffff",
          borderRadius: 5,
          color: "#60034c",
          fontSize: 10,
          padding: 10,
          fontFamily: "Inter-Bold",
          borderCollapse: "collapse",
        },
    
        tablebody: {
          fontSize: 10,
          padding: 8,
          textAlign: "left",
          fontFamily: "Inter-V",
          color: '#333333',
          textTransform: 'capitalize',
          borderCollapse: "collapse",
        },
        tableth: {
            fontSize:10,
            padding:4,
            border:"1px #bdc2c7 solid",
            color: "#e58b76",
            borderCollapse: "collapse",
        },
        tableCell:{
            fontSize:10,
            padding:4,
            border:"1px #bdc2c7 solid",
            borderCollapse: "collapse",
        },
        tableRow: {
          flexDirection: "row",
          height: 25,
          borderCollapse: "collapse",
          '&:nthOfType(even)': {
            backgroundColor: '#d3d3d3',
          },
          textTransform: 'capitalize',
        },
    
        detailsHeader: {
          backgroundColor: 'rgba(96, 3, 76, 0.1)',
          padding: 10,
          color: "#60034c",
          fontSize: 10,
          fontFamily: "Inter-Bold",
        },
    
        summaryFooter: {
          backgroundColor: 'rgba(96, 3, 76, 0.1)',
          padding: 10,
          color: "#3E4968",
          fontSize: 10,
          fontFamily: "Inter-Bold",
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'align-items',
          alignItems: 'center',
        },
        portfolioinfotop:{
            backgroundColor: '#60034c',
          padding: 10,
          display: 'flex',
          color: "#fff",
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: "Inter-V",
          marginBottom: 20,
          fontSize: 14,
        },
        portfolioinfoBar: {
          backgroundColor: '#fcf3f1',
          borderRadius: 5,
          padding: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: "Inter-V",
          marginBottom: 20,
          fontSize: 12,
        },
    
        Footnotes: {
          fontSize: 10,
          color: "#60034C",
          textAlign: "left",
          fontFamily: "Inter-Medium",
          marginTop: 20,
          display: 'flex',
        },
        tagcontent:{
            fontSize: 12,
            color: "#000000",
            textAlign: "left",
            fontFamily: "Inter-Medium",
            flexDirection: 'row',
            display: 'flex',
        },
        tagimg:{
            height: "auto",
            height: 150,
        },
        tagtitle:{
            fontSize: 16,
            color: "#60034C",
            textAlign: "left",
            fontFamily: "Inter-Medium",
            marginTop: 20,
            marginBottom: 20,
            flexDirection: 'row',
            display: 'flex',
        },
        tagspan:{
            backgroundColor: 'rgba(96, 3, 76, 0.1)',
            padding: 20,
            color: "#60034c",
            fontSize: 10,
            fontFamily: "Inter-Bold",
            display: 'flex',
            flexDirection: 'row',
            columnGap: 32,
        },
        Row: {
          flexDirection: 'row',
          padding: 10,
        },
        RowContainer: {
          width: '50%',
          padding: 5,
        },
        content: {
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        },
        pageformat:{
           backgroundColor: "#f5f5f5" ,
           padding:20,
           borderRadius:10,
        },
        pagetitle:{
            padding:20,
            color: "#e58b76",
            fontSize: 28,
            fontFamily: "Inter-Bold",
            display: 'flex',
            textAlign:"center",
            flexDirection: 'row',
            columnGap: 32,
            marginTop: 100,
            marginBottom: 50,   
            margin:0,
            width:"100%"
        },
        subtitlte:{
            padding:8,
            color: "#000000",
            fontSize: 16,
            textAlign:"center",
            display: 'flex',
            flexDirection: 'row',
        },
        backtestEvent: {
            fontFamily: "Inter-V",
            fontSize: 10,
        },
      });

    const periods = [
        { period_int: 0, period_value: "Select"},
        { period_int: 30, period_value: "1 Month"},
        { period_int: 90, period_value: "3 Months"},
        { period_int: 180, period_value: "6 Months"},
        { period_int: 360, period_value: "Year"},
        { period_int: 365*2, period_value: "2 Years"},
        { period_int: 365*3, period_value: "3 Years"},
        { period_int: 365*5, period_value: "5 Years"},
        { period_int: 365*10, period_value: "10 Years"},
    ];

    const levels = [
        { percent: 0, level: "Select" },
        { percent: 0.80, level: "80%" },
        { percent: 0.85, level: "85%" },
        { percent: 0.90, level: "90%" },
        { percent: 0.95, level: "95%" },
        { percent: 1.0, level: "100%" },
    ];

    const simulationPeriod = [
        { simulation_period: 0, simulation_value: "Select" },
        { simulation_period: 0.09, simulation_value: "1 Month" },
        { simulation_period: 0.17, simulation_value: "2 Months" },
        { simulation_period: 0.25, simulation_value: "3 Months" },
        { simulation_period: 0.5, simulation_value: "6 Months" },
        { simulation_period: 1, simulation_value: "1 Year" },
        { simulation_period: 2, simulation_value: "2 Years" },
        { simulation_period: 3, simulation_value: "3 Years" },
        { simulation_period: 4, simulation_value: "4 Years" },
        { simulation_period: 5, simulation_value: "5 Years" },
        { simulation_period: 6, simulation_value: "6 Years" },
        { simulation_period: 7, simulation_value: "7 Years" },
        { simulation_period: 8, simulation_value: "8 Years" },
        { simulation_period: 9, simulation_value: "9 Years" },
        { simulation_period: 10, simulation_value: "10 Years" },
    ];
    
    const NumberOfPaths = [
    { path_no: 0, path_value: "Select" },
    { path_no: 100, path_value: "100" },
    { path_no: 500, path_value: "500" },
    { path_no: 1000, path_value: "1,000" },
    { path_no: 2000, path_value: "2,000" },
    { path_no: 5000, path_value: "5,000" },
    { path_no: 10000, path_value: "10,000" }
    ];

    const targetRsik = [
        {percent: 0, level: "Select"},
        {percent: 0.05, level: "5%"},
        {percent: 0.1, level: "10%"},
        {percent: 0.15, level: "15%"},
        {percent: 0.2, level: "20%"},
        {percent: 0.25, level: "25%"},
        {percent: 0.3, level: "30%"},
        {percent: 0.35, level: "35%"},
        {percent: 0.4, level: "40%"},
        {percent: 0.45, level: "45%"},
        {percent: 0.5, level: "50%"} 
    ]

    export function coverPageElement(props,portfolioName){
        let currentDate = new Date();
        return(
            <html>
                <body>
                    <h1 style={styles.pagetitle}>Adaptive Portfolio Report</h1>
                    <div style={styles.pageformat}>
                        <p style={styles.subtitlte}>Prepared for {`${props.firstname.toUpperCase()} ${props.lastname.toUpperCase()}, ${props.companyname}`}</p>
                        <p style={styles.subtitlte}>Report As Of Date : {`${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`}</p>
                        <p style={styles.subtitlte}>Portfolio: {portfolioName.toUpperCase()}</p>
                    </div>
                </body>
            </html>
        )
    }
    
    export function headerElement(portfolioName,totalPortfolioHoldings,totalPortfolioValue){
        return (
            <html>
                <body>
                    <div style={styles.portfolioinfotop}>
                        <p>Portfolio: {portfolioName} </p>
                        <p>Holdings: {totalPortfolioHoldings}</p>
                        <p>Portfolio Value: ${" "}{Number(addZeroes(Number(parseFloat(totalPortfolioValue).toFixed(2)))).toLocaleString()}</p>
                    </div>
                </body>
            </html>
        )}

    export const SummaryTable = (tableHeader, tableData, displayElement) =>{
        if(displayElement){
            return(
                <html>
                    <body>
                        <div>
                            <table style={styles.tableMain}>
                                <tbody style={styles.tablebody}>
                                    {Object.entries(tableData).map(([key, value]) => (
                                        <tr style={styles.tableRow}>
                                            <td style={styles.tableCell}>{key}</td>
                                            <td style={styles.tableCell}>{value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                    </body>
                </html>
            )
        }
    }

    export const FactorTables = (tableHeader, tableData, displayElement,portfolioName) => {
        const updatedData = Object.entries(tableData).map(([key, value]) => {
            const updatedValue = Object.entries(value).reduce((acc, [k, v]) => {
              const newKey =
                k === "imp_vol"
                  ? "Implied Volatility"
                  : k === "hist_vol"
                  ? "Historical Volatility"
                  : k === "symbol"
                  ? "Symbol"
                  : k;
              if (
                newKey === "Implied Volatility" ||
                newKey === "Historical Volatility"
              ) {
                v = v === "N.A." ? "N.A." : (parseFloat(v) * 100).toFixed(2) + "%";
              }
              return { ...acc, [newKey]: v };
            }, {});
            const newValue = {
              Symbol: value.Symbol || portfolioName,
              ...updatedValue,
            };
            return { [key]: newValue };
        });
        const uniqueKeys = Array.from(
            new Set(updatedData.flatMap((obj) => Object.keys(obj[Object.keys(obj)[0]])))
        );

        if(displayElement){
            return(
                <html>
                    <body>
                        <div className={displayElement?"table_height tabbody":"table_height tabbody grey"}>
                            <table style={styles.tableMain}>
                                <thead style={styles.tableheader}>
                                    <tr>
                                    {tableHeader.map((header, index) => (
                                        <th style={styles.tableth} key={index}>
                                        {header}
                                        </th>
                                    ))}
                                    </tr>
                                </thead>
                                {tableData.length !== undefined ? (
                                    <tbody style={styles.tablebody}>
                                        {Object.values(tableData).map(
                                        (item, index) => (
                                            <tr key={item.number} style={styles.tableRow}>
                                            <td style={styles.tableCell}>{index + 1}</td>
                                            <td style={styles.tableCell}>
                                                {item.StockTicker}
                                            </td>
                                            <td style={styles.tableCell}>
                                                {(
                                                parseFloat(item.Weight) * 100
                                                ).toFixed(2) + "%"}
                                            </td>
                                            <td style={styles.tableCell}>
                                                {item.Description}
                                            </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody style={styles.tablebody}>
                                        {uniqueKeys.map((key, index) => (
                                        <tr key={index} style={styles.tableRow}>
                                            <td style={styles.tableCell}>
                                            {key}
                                            </td>
                                            {updatedData.map((obj, i) => {
                                                const subObj = obj[Object.keys(obj)[0]];
                                                return (
                                                    <td style={styles.tableCell} key={i}>
                                                    {subObj[key]}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </body>
                </html>
            );
        }
    }

    const GenerateTableFooter = (chartName, totalSums, TotalPortfolioValue) => {
        if(chartName === 'Risk_Contribution_Table'){
            return (
                <tr>
                    <td style={styles.tableCell} colSpan="3">Portfolio Total:</td>
                    <td style={styles.tableCell}>100%</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.marketContribution).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.idiosyncraticContribution).toFixed(2))))}</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.diversifiedRisk).toFixed(2))))}</td>
                    <td style={styles.tableCell}></td>
                </tr>
            )
        } else if (chartName === 'Portfolio_Details_Table'){
            return(
                <tr>
                    <td style={styles.tableCell} colSpan="3">Portfolio Total:</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(TotalPortfolioValue).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                    <td style={styles.tableCell}>100%</td>
                    <td style={styles.tableCell}></td>
                </tr>
            )
        } else if(chartName === 'Backtest_Table'){
            return(
                <tr>
                    <td style={styles.tableCell} colSpan="3">Portfolio Total:</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.totalMSShieldCost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.totalMSCompoundedShieldCost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.totalMSShieldPayout).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                    <td style={styles.tableCell}>{Number(addZeroes(Number(parseFloat(totalSums.totalMSCompoundedPayout).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                
                </tr>
            )
        } else{
            <tr>
                <td style={styles.tableCell}></td>
            </tr>
        }
    }

    export const GenerateTable = (tableHeader, tableData, totalSums, displayElement,TotalPortfolioValue,userSelectedData,activeTab,chartName) => {
        if(displayElement){
            return (
                <html>
                    <body>
                        {userSelectedData && (
                            <>
                                {activeTab === 'Backtest' && (
                                    <>
                                        <p style={styles.backtestEvent}>
                                            {userSelectedData.historicalEventSelected
                                                ? `Event: ${userSelectedData.historicalEventSelected}`
                                                : ''}
                                        </p>
                                        <div style={styles.portfolioinfoBar}>
                                            <p>
                                                Protection Model: {userSelectedData.protection_model? userSelectedData.protection_model: 'N/A'}
                                            </p>
                                            <p>
                                                Asset Allocation: {userSelectedData.asset_allocation_range? userSelectedData.asset_allocation_range: 'N/A'}
                                            </p>
                                            <p>
                                                Target Risk: {targetRsik && userSelectedData.target_risk ? targetRsik.filter(level => level.percent === userSelectedData.target_risk).map(level=>level.level): 'N/A'}
                                            </p>
                                            <p>
                                                Protection Level: {levels && userSelectedData.protection_level? levels.filter(level => level.percent === userSelectedData.protection_level).map(level => level.level): 'N/A'}
                                            </p>
                                        </div>
                                        <div style={styles.portfolioinfoBar}>
                                            <p>
                                                Protection Period: {periods && userSelectedData.protection_period? periods.filter(period => period.period_int === userSelectedData.protection_period).map(period => period.period_value): 'N/A'}
                                            </p>
                                            <p>
                                                Start Date: {userSelectedData.startDate ? userSelectedData.startDate.toISOString().split("T")[0] : 'N/A'}
                                            </p>
                                            <p>
                                                End Date: {userSelectedData.endDate ? userSelectedData.endDate.toISOString().split("T")[0] : 'N/A'}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {activeTab === 'Forward Test' && (
                                    <>
                                        <div style={styles.portfolioinfoBar}>
                                            <p>
                                                Protection Level: {levels && userSelectedData.level? levels.filter(level => level.percent === userSelectedData.level).map(level => level.level): 'N/A'}
                                            </p>
                                            <p>
                                                Protection Period: {periods && userSelectedData.period? periods.filter(period => period.period_int === userSelectedData.period).map(period => period.period_value): 'N/A'}
                                            </p>
                                            <p>
                                                Simulation Length: {simulationPeriod && userSelectedData.Simulation? simulationPeriod.filter(simulation => simulation.simulation_period === userSelectedData.Simulation).map(simulation => simulation.simulation_value): 'N/A'}
                                            </p>
                                            <p>
                                                Number of Paths: {NumberOfPaths && userSelectedData.Path? NumberOfPaths.filter(path => path.path_no === userSelectedData.Path).map(path => path.path_value): 'N/A'}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <div className={displayElement?"table_holder table_head m0i": "table_holder table_head m0i grey"}>
                            <table style={styles.tableMain}>
                                <thead style={styles.tableheader}>
                                    <tr>
                                        {tableHeader.map((headerItem, index) => (
                                            <th style={styles.tableth} key={index}>
                                            {headerItem.header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody style={styles.tablebody}>
                                    {tableData.map((rowData, index) => (
                                        <tr key={index} style={styles.tableRow}>
                                            {tableHeader.map((headerItem, index) => (
                                                <td style={styles.tableCell} key={index}>{rowData[headerItem.accessorKey]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {GenerateTableFooter(chartName,totalSums,TotalPortfolioValue)}
                                </tbody>
                            </table>
                        </div>
                        <br></br>
                    </body>
                </html>
            );
        }
    };

    export const GenerateChart = (imgData, displayElement,userSelectedData,activeTab) => {
        if(displayElement){
            return (
                <html>
                    <body>
                        {userSelectedData && (
                            <>
                                {(activeTab === 'Backtest' || activeTab === 'Summary Analytics') && (
                                    <>
                                        <p style={styles.backtestEvent}>
                                            {userSelectedData.historicalEventSelected
                                                ? `Event: ${userSelectedData.historicalEventSelected}`
                                                : ''}
                                        </p>
                                        <div style={styles.portfolioinfoBar}>
                                            <p>
                                                Protection Model: {userSelectedData.protection_model? userSelectedData.protection_model: 'N/A'}
                                            </p>
                                            <p>
                                                Asset Allocation: {userSelectedData.asset_allocation_range? userSelectedData.asset_allocation_range: 'N/A'}
                                            </p>
                                            <p>
                                                Target Risk: {targetRsik && userSelectedData.target_risk ? targetRsik.filter(level => level.percent === userSelectedData.target_risk).map(level=>level.level): 'N/A'}
                                            </p>
                                            <p>
                                                Protection Level: {levels && userSelectedData.protection_level? levels.filter(level => level.percent === userSelectedData.protection_level).map(level => level.level): 'N/A'}
                                            </p>
                                        </div>
                                        <div style={styles.portfolioinfoBar}>
                                            <p>
                                                Protection Period: {periods && userSelectedData.protection_period? periods.filter(period => period.period_int === userSelectedData.protection_period).map(period => period.period_value): 'N/A'}
                                            </p>
                                            <p>
                                                Start Date: {userSelectedData.startDate ? userSelectedData.startDate.toISOString().split("T")[0] : 'N/A'}
                                            </p>
                                            <p>
                                                End Date: {userSelectedData.endDate ? userSelectedData.endDate.toISOString().split("T")[0] : 'N/A'}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {activeTab === 'Forward Test' && (
                                    <>
                                        <div style={styles.portfolioinfoBar}>
                                            <p>
                                                Protection Level: {levels && userSelectedData.level? levels.filter(level => level.percent === userSelectedData.level).map(level => level.level): 'N/A'}
                                            </p>
                                            <p>
                                                Protection Period: {periods && userSelectedData.period? periods.filter(period => period.period_int === userSelectedData.period).map(period => period.period_value): 'N/A'}
                                            </p>
                                            <p>
                                                Simulation Length: {simulationPeriod && userSelectedData.Simulation? simulationPeriod.filter(simulation => simulation.simulation_period === userSelectedData.Simulation).map(simulation => simulation.simulation_value): 'N/A'}
                                            </p>
                                            <p>
                                                Number of Paths: {NumberOfPaths && userSelectedData.Path? NumberOfPaths.filter(path => path.path_no === userSelectedData.Path).map(path => path.path_value): 'N/A'}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <div className={displayElement?"table_holder table_head m0i": "table_holder table_head m0i grey"}>
                            <img src={imgData} />
                        </div>
                        <br></br>
                    </body>
                </html>
              );
        }
    };

    export const customNote = (note) => {
        return(
            <html>
                <body>
                    <div
                        dangerouslySetInnerHTML={{ __html: note }}
                    />
                </body>
            </html>
        )
    }

    export const footerText = 'Adaptive funds are not held in a brokerage account and are not protected by SIPC. Brokerage assets are held by Adaptive Investment Services, a division of Adaptive Investments LLC.';

    