import React from "react";
import AdaptiveCSVImportWizardComponent from "../../Components/OnboardClientPortfolio/AdaptiveCSVImportWizardComponent";
import './AdaptiveImportWizardScreen.css';
import Navbar from "../../Components/Navbar/Navbar";

function AdaptiveCSVImportWizardScreen() {

    return (
        <>
            <div className="container">
                <div className="onboard_wrap">
                    <AdaptiveCSVImportWizardComponent/>
                </div>
            </div>
        </>
    );
}

export default AdaptiveCSVImportWizardScreen;
