import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions/notificationActions';

const initialState = {
    message: '',
    alertType: '',
    popupType: '',
    redirectLink: '',
    loginRedirect: false,
    onSuccessRouteTo: '',
    confirmActionYes: null,
    confirmActionNo: null
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return { ...state, ...action.payload };
        case HIDE_NOTIFICATION:
            return initialState;
        default:
            return state;
    }
};

export default notificationReducer;
