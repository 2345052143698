import React from "react";
import Loader from '../Loader/Loader';
import AiInput from "../../Components/AiInput/ai-input";
import AiFormError from "../../Components/AiFormError/ai-form-error";
import {
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
import TagManager from "react-gtm-module";
import "./Signup.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Signup({ setScreen , setClientMetadata , userType}) {
  const accountTypes = [
    { name: "Investor", url: "investor", icon: "../Assets/investor.svg" },
    { name: "Advisor", url: "advisor", icon: "../Assets/advisor.svg" }
  ];
  const [selectedAcType, setSelectedAcType] = React.useState(userType ===  null ?"":userType);
  const [bdopen, setBdOpen] = React.useState(false);
  const [isAccnVisible, setIsAccnVisible] = React.useState(userType===undefined);
  const [isLoginVisible, setIsLoginVisible] = React.useState(!(userType ===  undefined));
  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmpassword, setConfirmPassword] = React.useState("");
  const [CRDNumber, setCRDNumber] = React.useState("");
  const [companyname, setCompanyName] = React.useState("")
  const [inputerrors, setInputErrors] = React.useState({});
  const history = useHistory();
  const navigateLogin = () => {
    setScreen("Login")
    userType !== undefined  && history.push("/");

  }
  const [passwordFocused, setPasswordFocused] = React.useState(false);
  const OTPConfirmation = () => {
    // setUserEmail(email);
    setScreen("OTP");
  }

  React.useEffect(() => {
    window.sessionStorage.setItem("selectedAcType", selectedAcType);
  }, [selectedAcType]);

  const handleAccnVisible = () => {
    setIsAccnVisible(!isAccnVisible);
  };
  const handleLoginVisible = () => {
    setIsLoginVisible(!isLoginVisible);
  };

  const pushInfo = () => {
    let attributeList = [];
    let dataEmail = {
      Name: "email",
      Value: email.trim().toLowerCase(),
    };

    let dataLname = {
      Name: "family_name",
      Value: lastname.trim(),
    };

    let dataFname = {
      Name: "name",
      Value: firstname.trim(),
    };
    let attributeEmail = new CognitoUserAttribute(dataEmail);
    let attributeFirstName = new CognitoUserAttribute(dataFname);
    let attributeLastName = new CognitoUserAttribute(dataLname);
    attributeList.push(attributeEmail);
    attributeList.push(attributeFirstName);
    attributeList.push(attributeLastName);

    return attributeList;
  };

  const validateSignupForm = () => {
    let errors = {};
    let validFormFlag = true;

    if (!firstname) {
      validFormFlag = false;
      errors["firstname"] = "First Name cannot be empty.";
    }

    if (firstname && !(/^[A-Za-z][A-Za-z.]+$/.test(firstname))) {
      validFormFlag = false;
      errors["firstname"] = "Invalid First Name.";
    }

    if (!lastname) {
      validFormFlag = false;
      errors["lastname"] = "Last Name cannot be empty.";
    }

    if (lastname && !(/^[A-Za-z][A-Za-z.]+$/.test(lastname))) {
      validFormFlag = false;
      errors["lastname"] = "Invalid Last Name.";
    }

    if (!email) {
      validFormFlag = false;
      errors["email"] = "Email cannot be empty.";
    }

    if (email && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))) {
      validFormFlag = false;
      errors["email"] = "Invalid Email."
    }

    if (!password) {
      validFormFlag = false;
      errors["password"] = "Password cannot be empty.";
    }

    if (password) {
      if (!(/.*[A-Z].*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have an uppercase letter."
      }
      else if (!(/.*[a-z].*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have an lowercase letter."
      }
      else if (!(/\w*\d\w*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have a digit."
      }
      else if (!(/.*[!@#$%^&*].*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have a special character."
      }

      else if (!(/^.{8,}$/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password should be at least 8 characters long."
      }

      else if (!(/^(.{1,30})$/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password can not have more than 30 characters."
      }

    }

    if (password !== confirmpassword && confirmpassword !== "") {
      validFormFlag = false;
      errors["confirmpassword"] =
        "Your password and confirmation password do not match.";
    }

    if (!confirmpassword) {
      validFormFlag = false;
      errors["confirmpassword"] = "Confirm Password cannot be empty.";
    }
    setInputErrors(errors);
    return validFormFlag;
  }
  const validateAdvisorSignupForm = () => {
    let errors = {};
    let validFormFlag = true;
    if (!firstname) {
      validFormFlag = false;
      errors["firstname"] = "First Name cannot be empty.";
    }

    if (firstname && !(/^[A-Za-z][A-Za-z.]+$/.test(firstname))) {
      validFormFlag = false;
      errors["firstname"] = "Invalid First Name.";
    }

    if (!lastname) {
      validFormFlag = false;
      errors["lastname"] = "Last Name cannot be empty.";
    }

    if (lastname && !(/^[A-Za-z][A-Za-z.]+$/.test(lastname))) {
      validFormFlag = false;
      errors["lastname"] = "Invalid Last Name.";
    }

    if (!email) {
      validFormFlag = false;
      errors["email"] = "Email cannot be empty.";
    }

    if (email && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))) {
      validFormFlag = false;
      errors["email"] = "Invalid Email."
    }

    if (!companyname) {
      validFormFlag = false;
      errors["companyname"] = "Company Name cannot be empty.";
    }

    if (!password) {
      validFormFlag = false;
      errors["password"] = "Password cannot be empty.";
    }

    if (password) {
      if (!(/.*[A-Z].*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have an uppercase letter."
      }
      else if (!(/.*[a-z].*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have an lowercase letter."
      }
      else if (!(/\w*\d\w*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have a digit."
      }
      else if (!(/.*[!@#$%^&*].*/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password needs to have a special character."
      }

      else if (!(/^.{8,}$/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password should be at least 8 characters long."
      }

      else if (!(/^(.{1,30})$/.test(password))) {
        validFormFlag = false;
        errors["password"] = "The password can not have more than 30 characters."
      }

    }

    if (password !== confirmpassword && confirmpassword !== "") {
      validFormFlag = false;
      errors["confirmpassword"] =
        "The Confirm Password doesn't match with Create Password.";
    }

    if (!confirmpassword) {
      validFormFlag = false;
      errors["confirmpassword"] = "Confirm Password cannot be empty.";
    }

    setInputErrors(errors);
    return validFormFlag;
  }
  const changeAccountType = (accountType) => {
    setSelectedAcType(accountType);
    setInputErrors({});
  }

  const returnClassname = (accountType) => {
    if (accountType === selectedAcType) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  }

  const submitSignupForm = (e) => {
    e.preventDefault();
    if (selectedAcType === "Investor") {
      if (validateSignupForm()) {
        setBdOpen(true);
        // CheckCustomerAccess(email, firstname, lastname, email, selectedAcType).then(
        //   (customer_access) => {
        //     if (customer_access === "False") {
        //       setBdOpen(false);
        //       setPopupState(true);
        //       return;
        //     } else {
        let attributeList = pushInfo();
        UserPool.signUp(
          email.trim().toLowerCase(),
          password.trim(),
          attributeList,
          null,
          (err, data) => {
            if (err) {
              let errors = {};
              setBdOpen(false);
              if (err.message === "User already exists") {
                errors["email"] =
                  "This email id is already taken, try another one.";
              }
              if (err.message === "Invalid email address format.") {
                errors["email"] = "Invalid Email.";
              }
              if (
                err.message ===
                "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
              ) {
                errors["email"] = "Invalid Email.";
              }
              if (err.code === "InvalidPasswordException") {
                errors["password"] =
                  "Please make sure that your password length is at least 8 characters and should not exceed more than 30 characters. Should have a number, a uppercase letter, a lowercase letter and a special character.";
              }
              console.error(err)
              setInputErrors(errors);
            } else {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'User Signup',
                  userType: selectedAcType
                },
              });
              window.sessionStorage.setItem("userEmail", email);
              setClientMetadata({
                user_type:selectedAcType,
                origin: 'sign-up'
              });
              OTPConfirmation();
            }
          }
        );
      }
    } else if (selectedAcType === "Advisor") {
      if (validateAdvisorSignupForm()) {
        setBdOpen(true);
        let attributeList = pushInfo();
        UserPool.signUp(
          email.trim().toLowerCase(),
          password.trim(),
          attributeList,
          null,

          (err, data) => {
            if (err) {
              let errors = {};
              setBdOpen(false);
              if (err.message === "User already exists") {
                errors["email"] =
                  "This email id is already taken, try another one.";
              }
              if (err.message === "Invalid email address format.") {
                errors["email"] = "Invalid Email.";
              }
              if (
                err.message ===
                "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
              ) {
                errors["email"] = "Invalid Email.";
              }
              if (err.code === "InvalidPasswordException") {
                errors["password"] =
                  "Please make sure that your password length is at least 8 characters and should not exceed more than 30 characters. Should have a number, a uppercase letter, a lowercase letter and a special character.";
              }
              if (err.message === "Invalid phone number format.") {
                errors["mobile"] = "Invalid phone number format. Please enter mobile number in 10 digits format"
              }
              setInputErrors(errors);
            } else {
              sessionStorage.setItem(
                "selectedAcType",
                selectedAcType
              );
              TagManager.dataLayer({
                dataLayer: {
                  event: 'User Signup',
                  userType: selectedAcType
                },
              });
              window.sessionStorage.setItem("userEmail", email.trim().toLowerCase());
              setClientMetadata({
                crd_no: CRDNumber,
                companyname: companyname,
                user_type:selectedAcType,
                origin: 'sign-up'
              });
              OTPConfirmation();
            }
          },
        )
      }
    }
  }
  return (
    <>
      {bdopen ? (
        <div className="loading-overlay">
          <Loader/>
        </div>) : (
        <>
          <div className="ai-login-section">
            {isAccnVisible && (
              <div className="ai-field-group">
                <h2>Sign Up</h2>
                <nav className="ai-tabs nav-tabs usertab">
                  {accountTypes.map((accountType) => (
                    <a
                      onClick={() => { history.push(`/${accountType.url}signup`); handleAccnVisible(); handleLoginVisible(); changeAccountType(accountType.name)}}
                      className={returnClassname(accountType.name)}
                    >
                      <img className="accounticon" src={accountType.icon}></img>
                      <span>{accountType.name}</span>
                      <img className="accounticon" src="./Assets/Right.svg"></img>
                    </a>
                  ))}
                </nav>
                <div class="calendlysaprator"><span>OR</span></div>
                <div className="ai-field-group">
                  <div className="ai-row v-middle">
                    <div className="ai-column">
                      <div className="ai-signin middle-text">
                        <p>Already have an account?</p>
                        <a to="/" className="ai-link" onClick={() => navigateLogin()}>
                          Sign In
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isLoginVisible && (
              <>
                <h2>
                  <a className="backtouser" onClick={() => { changeAccountType(""); handleAccnVisible(); handleLoginVisible(); history.push("/")}}><img src="../Assets/arrowright.svg"></img></a>Sign Up as {selectedAcType === "Investor" ? (
                    "Investor"
                  ) : (
                    "Advisor"
                  )}</h2>
                {selectedAcType === "Investor" && (
                  <form
                    method="post"
                    name="signupForm"
                    onSubmit={submitSignupForm}
                  >
                    <div className="ai-field-group">
                      <div className="ai-row ai-two-column">
                        <div className="ai-column">
                          <AiInput
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            onChange={(event) => setFirstName(event.target.value)}
                          />
                          <AiFormError
                            errormessage={
                              inputerrors.firstname
                            }
                          />
                        </div>
                        <div className="ai-column">
                          <AiInput
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            onChange={(event) => setLastName(event.target.value)}
                          />
                          <AiFormError
                            errormessage={
                              inputerrors.lastname
                            }
                          />
                        </div>
                      </div>
                      <div className="ai-row">
                        <div className="ai-column">
                          <AiInput
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={(event) => setEmail(event.target.value)}
                          />
                          <AiFormError
                            errormessage={
                              inputerrors.email
                            }
                          />
                        </div>
                      </div>
                      <div className="ai-row">
                        <div className="ai-column">
                          <AiInput
                            type="password"
                            name="password"
                            placeholder="Create Password"
                            onChange={(event) => setPassword(event.target.value)}
                            autoComplete="new-password"
                            onFocus={() => setPasswordFocused(true)}
                            onBlur={() => setPasswordFocused(false)}
                          />
                          {passwordFocused && (
                            <AiFormError
                              errormessage={
                                "Please make sure that your password length is at least 8 characters and should not exceed more than 30 characters. Should have a number, a uppercase letter, a lowercase letter and a special character."
                              }
                            />
                          )}
                          <AiFormError
                            errormessage={
                              inputerrors.password
                            }
                          />
                        </div>
                      </div>
                      <div className="ai-row">
                        <div className="ai-column">
                          <AiInput
                            type="password"
                            name="confirmpassword"
                            placeholder="Confirm Password"
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            autoComplete="new-password"
                          />
                          <AiFormError
                            errormessage={
                              inputerrors.confirmpassword
                            }
                          />
                        </div>
                      </div>
                      <div className="ai-row" />
                    </div>
                    <div className="ai-field-group">
                      <div className="ai-row ai-two-column">
                        <div className="ai-column">
                          <div className="ai-field-group">
                            <div className="ai-row ai-two-column">
                              <div className="ai-column">
                                <div className="ai-signin">
                                  <p>Already have an account?</p>
                                  <a to="/" className="ai-link" onClick={() => { navigateLogin()}}>
                                    Sign In
                                  </a>
                                </div>
                              </div>
                              <button className="ai-btn primary round">
                                <img src="./Assets/next.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
                {selectedAcType === "Advisor" && (

              <form
                method="post"
                name="signupForm"
                onSubmit={submitSignupForm}
              >
                <div className="ai-field-group">
                  <div className="ai-row ai-two-column">
                    <div className="ai-column">
                      <AiInput
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                      <AiFormError
                        errormessage={
                          inputerrors.firstname
                        }
                      />
                    </div>
                    <div className="ai-column">
                      <AiInput
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        onChange={(event) => setLastName(event.target.value)}
                      />
                      <AiFormError
                        errormessage={
                          inputerrors.lastname
                        }
                      />
                    </div>
                  </div>
                  <div className="ai-row">
                    <div className="ai-column">
                      <AiInput
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                      <AiFormError
                        errormessage={
                          inputerrors.email
                        }
                      />
                    </div>
                  </div>
                  <div className="ai-row">
                    <div className="ai-column">
                      <AiInput
                        type="text"
                        name="companyname"
                        placeholder="Company Name"
                        onChange={(event) => setCompanyName(event.target.value)}
                      />
                      <AiFormError
                        errormessage={
                          inputerrors.companyname
                        }
                      />
                    </div>
                  </div>
                  <div className="ai-row">
                    <div className="ai-column">
                      <AiInput
                        type="text"
                        name="crdnumer"
                        placeholder="CRD Number (optional)"
                        onChange={(event) => setCRDNumber(event.target.value)}
                      />
                      <AiFormError
                        errormessage={
                          inputerrors.mobile
                        }
                      />
                    </div>
                  </div>
                  <div className="ai-row">
                    <div className="ai-column">
                      <AiInput
                        type="password"
                        name="password"
                        placeholder="Create Password"
                        onChange={(event) => setPassword(event.target.value)}
                        autoComplete='new-password'
                        onFocus={(event) => setPasswordFocused(true)} 
                        onBlur={(event) => setPasswordFocused(false)}
                      />
                      {passwordFocused && (
                        <AiFormError
                        errormessage={
                          "A password must contain uppercase, lowercase, a special character, and a number. Min. length is eight characters; max. length is 30 characters."
                        }
                      />
                      )}
                      <AiFormError
                        errormessage={
                          inputerrors.password
                        }
                      />
                    </div>
                  </div>
                  <div className="ai-row">
                    <div className="ai-column">
                      <AiInput
                        type="password"
                        name="confirmpassword"
                        placeholder="Confirm Password"
                        onChange={(event) => setConfirmPassword(event.target.value)}
                        autoComplete="new-password"
                      />
                      <AiFormError
                        errormessage={
                          inputerrors.confirmpassword
                        }
                      />
                    </div>
                  </div>
                  <div className="ai-row" />

                    </div>
                    <div className="ai-field-group">
                      <div className="ai-row ai-two-column">
                        <div className="ai-column">
                          <div className="ai-signin">
                            <p>Already have an account?</p>
                            <a to="/" className="ai-link" onClick={() => { navigateLogin() }}>
                              Sign In
                            </a>
                          </div>
                        </div>
                        <button className="ai-btn primary round">
                          <img src="./Assets/next.svg" />
                        </button>
                      </div>
                    </div>
                  </form>
                )} </>
            )}
          </div>
        </>)}
    </>
  );
}
export default Signup;