import React from "react";
import AiInput from "../../Components/AiInput/ai-input";
import AiFormError from "../../Components/AiFormError/ai-form-error";
import Loader from '../Loader/Loader';
import { Link } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../UserPool";
// import AdvisorPool from "../../AdvisorPool";
import { withRouter } from "react-router";
import "./ForgotPassword.css";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function ForgotPassword({setScreen}) {

    const [stage, setStage] = React.useState(1);
    const [popupState, setPopupState] = React.useState(false);
    const [inputerrors, setInputErrors] = React.useState({});
    const [newpassworderrors, setNewPasswordErrors] = React.useState({});
    const [username, setUserName] = React.useState("");
    const [code, setCode] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [passwordFocused, setPasswordFocused] = React.useState(false);
    const [bdopen, setBdOpen] = React.useState(false);

    const history = useHistory();
    const loginPage = () => history.push("/");

    const validateForgotForm = () => {
        let errors = {};
        let validFormFlag = true;
        if (!username) {
            validFormFlag = false;
            errors["username"] = "Email cannot be empty.";
        }
        if(username && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username))){
            validFormFlag = false;
            errors["username"]= "Invalid Email.";
        }
        setInputErrors(errors);
        return validFormFlag;
    }

    const validateNewPasswordForm = () => {
        let errors = {};
        let validNewPasswordFormFlag = true;
        if (newPassword !== confirmPassword) {
            validNewPasswordFormFlag = false;
            errors["confirmPassword"] = "The Confirm Password doesn't match with Create Password.";
        }
        if (!newPassword) {
            validNewPasswordFormFlag = false;
            errors["newPassword"] = "Password cannot be empty.";
        }
        if(newPassword){
            if(!(/.*[A-Z].*/.test(newPassword))){
                validNewPasswordFormFlag= false;
                errors["newPassword"] = "The password needs to have an uppercase letter."
            }
            else if(!(/.*[a-z].*/.test(newPassword))){
                validNewPasswordFormFlag= false;
                errors["newPassword"] = "The password needs to have an lowercase letter."
            }
            else if(!(/\w*\d\w*/.test(newPassword))){
                validNewPasswordFormFlag= false;
                errors["newPassword"] = "The password needs to have a digit."
            }
            else if(!(/.*[!@#$%^&*].*/.test(newPassword))){
                validNewPasswordFormFlag= false;
                errors["newPassword"] = "The password needs to have a special character."
            }

            else if(!(/^.{8,}$/.test(newPassword))){
                validNewPasswordFormFlag= false;
                errors["newPassword"] = "The password should be at least 8 characters long."
            }

            else if(!(/^(.{1,30})$/.test(newPassword))){
                validNewPasswordFormFlag= false;
                errors["newPassword"] = "The password can not have more than 30 characters."
            }
        }
        if (!confirmPassword) {
            validNewPasswordFormFlag = false;
            errors["confirmPassword"] = "Confirm Password cannot be empty.";
        }
        if (!code) {
            validNewPasswordFormFlag = false;
            errors["code"] = "Verification Code cannot be empty.";
        }
        setNewPasswordErrors(errors);
        return validNewPasswordFormFlag;
    }

    const getUser = () => {

            return new CognitoUser({
                Username: username.trim().toLowerCase(),
                Pool: UserPool,
            });
    }

    const sendCode = () => {
        setBdOpen(true);
        let errors = {};
        getUser().forgotPassword({
            onSuccess: (data) => {
            },
            onFailure: (err) => {
                setBdOpen(false);
                errors["username"] = "Email not found. Please enter correct Email";
                setInputErrors(errors);
            },
            inputVerificationCode: (data) => {
                setStage(2);
                setBdOpen(false);
            },
        });
    }

    const resetPassword = (event) => {
        let errors = {};
        setBdOpen(true);
        getUser().confirmPassword(
            code,
            newPassword,
            {
                onSuccess: () => {

                    setBdOpen(false);
                    setPopupState(true);
                    setScreen("Login");
                },
                onFailure: (err) => {
                    setBdOpen(false);
                    if (err["message"] === "Invalid verification code provided, please try again.") {
                        errors["code"] = "Invalid Verification Code.";
                    } else {
                        errors["confirmPassword"] = err["message"]
                    }
                    setNewPasswordErrors(errors);
                },
            },
            {
                origin: 'forgot-password'
            }
        );
    }
    const handlePopup = () => {
        setPopupState(false);
    }

    const submitForgotPasswordForm = (e) => {
        e.preventDefault();
        if (validateForgotForm()) {
            sendCode();
        }
    }

    const submitNewPasswordForm = (e) => {
        e.preventDefault();
        if (validateNewPasswordForm()) {
            resetPassword();
        }
    }

    return (
        // <section className="ai-container ai-bording-page">

        // <div className="ai-login-container">
        //     <div className="ai-signup-wrapper">
        <>
        {bdopen ? (
            <div className="loading-overlay">
              <Loader/>
            </div>) : (
            <div className="ai-login-form">
                <div className="ai-login-section">
                    <div className="ai-field-group">
                        <h2>Forgot Password</h2>
                    </div>
                    {stage === 1 && (
                        <form
                            name="submitForgotPasswordForm"
                            onSubmit={submitForgotPasswordForm}
                        >
                            <div className="ai-field-group">
                                <div className="ai-row">
                                    <div className="ai-column">
                                        <AiInput
                                            type="text"
                                            name="username"
                                            placeholder="Enter Email Id"
                                            onChange={(event) => setUserName(event.target.value)}
                                        />
                                        <AiFormError
                                            errormessage={
                                                inputerrors.username
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ai-row" />
                            </div>
                            <div className="ai-field-group">
                                <div className="ai-row ai-two-column">
                                    <div className="ai-column">
                                        <div className="ai-signin" onClick={() => { setScreen("Login") }}>
                                            Back to{" "}
                                            <a to="/" className="ai-link">
                                                Sign In
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ai-column ai-align-right">
                                        <button className="ai-btn primary round">
                                            <ArrowForwardIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}

                    {stage === 2 && (
                        <form
                            name="submitNewPasswordForm"
                            onSubmit={submitNewPasswordForm}
                        >
                            <div className="ai-field-group">
                                <div className="ai-row">
                                    <div className="ai-column">
                                        Verification code has been sent to your
                                        registered email address. Please check
                                        your inbox.
                                    </div>
                                </div>
                                <div className="ai-row">
                                    <div className="ai-column">
                                        <div className="ai-form-group">
                                            <input
                                                type="text"
                                                placeholder="Enter Verification Code"
                                                name="code"
                                                className="ai-form-value"
                                                onChange={(event) => setCode(event.target.value)}
                                                autoComplete="new-password" />

                                        </div>
                                        <AiFormError
                                            errormessage={
                                                newpassworderrors.code
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ai-row">
                                    <div className="ai-column">
                                        <div className="ai-form-group">
                                            <input
                                                type="password"
                                                placeholder="Enter New Password"
                                                name="newPassword"
                                                className="ai-form-value"
                                                onChange={(event) => setNewPassword(event.target.value)}
                                                autoComplete="new-password" 
                                                onFocus={() => setPasswordFocused(true)} 
                                                onBlur={() => setPasswordFocused(false)}/>
                                        </div>
                                        {passwordFocused && (
                                            <AiFormError
                                            errormessage={
                                                "Please make sure that your password length is at least 8 characters and should not exceed more than 30 characters. Should have a number, a uppercase letter, a lowercase letter and a special character"
                                            }
                                            />
                                        )}
                                        <AiFormError
                                            errormessage={
                                                newpassworderrors.newPassword
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ai-row">
                                    <div className="ai-column">
                                        <div className="ai-form-group">
                                            <input
                                                type="password"
                                                placeholder="Enter Confirm Password"
                                                name="confirmPassword"
                                                className="ai-form-value"
                                                onChange={(event) => setConfirmPassword(event.target.value)}
                                                autoComplete="new-password" />
                                        </div>
                                        <AiFormError
                                            errormessage={
                                                newpassworderrors.confirmPassword
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ai-row" />
                            </div>
                            <div className="ai-field-group">
                                <div className="ai-row ai-two-column">
                                    <div className="ai-column">
                                        <div className="ai-signin" onClick={() => { setScreen("Login") }}>
                                            Back to{" "}
                                            <a to="/" className="ai-link">
                                                Sign In
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ai-column ai-align-right">
                                        <button className="ai-btn primary round">
                                            <ArrowForwardIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>)}
                </div>
            </div>
            //     </div>
            // </div>
            // </section>
        )}
        </>
    );

}
export default ForgotPassword;
