import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";

export async function RollOrSell(apiData) {
    console.log(" in api ", apiData)
    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };
    apiData.session_id = Token;

    // Stringify the modified apiData object
    const raw = JSON.stringify(apiData);

    console.log(raw);
    var requestOptions = {
        method: "POST",
        body: raw,
        headers: header,
        redirect: "follow",
    };
    try {
        const response = await fetchWithTimeout(
            process.env.REACT_APP_OPTION_ACTIONS_API,
            requestOptions
        );
        const message = await response.json();
        if (!response.ok) {
            return { status: response.status, error: message.error || message };
        } else {
            console.log("message", message)
            return { status: response.status, result: message.result };
        }
    } catch (error) {
        console.log("Error:", error.message);
        return { status: 0, error: error.message };
    }

}