import { UPDATE_PDF_SUMMARY_DATA, UPDATE_DISPLAY_ELEMENT, UPDATE_CUSTOM_NOTE, DELETE_PDF_SUMMARY_DATA_ELEMENT, CLEAR_PDF_SUMMARY_DATA , UPDATE_CUSTOM_TITLE} from "../actions/pdfSummaryActions";


const initialState = {};

const pdfSummaryReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_PDF_SUMMARY_DATA:
            if(action.payload.chartName !== ''){
                return {
                    ...state,
                    [action.payload.chartName]: action.payload.data
                }
            } else {
                return action.payload.data
            }
            
        case UPDATE_DISPLAY_ELEMENT:
            return {
                ...state,
                [action.payload.chartName]: {
                    ...state[action.payload.chartName],
                    displayElement : action.payload.displayValue
                }
            };
            
        case UPDATE_CUSTOM_NOTE:
            return {
                ...state,
                [action.payload.chartName]: {
                    ...state[action.payload.chartName],
                    customNote: action.payload.customNote
                }
            };

        case DELETE_PDF_SUMMARY_DATA_ELEMENT:
            const newState = { ...state };
            if (newState[action.payload.chartName]) {
                delete newState[action.payload.chartName];
            }
            return newState;
        
        case CLEAR_PDF_SUMMARY_DATA:
            const curState = { ...state };
            if (Object.keys(curState).length === 0) {
                return state;
            }
            Object.keys(curState).forEach(key => {
                const item = curState[key];
                if (item.activePortfolioId !== action.payload.portfolioId) {
                    delete curState[key];
                }
            });

            return curState;
        
        case UPDATE_CUSTOM_TITLE:
            return {
                ...state,
                [action.payload.chartName]: {
                    ...state[action.payload.chartName],
                    elementTitle: action.payload.title
                }
        };
                        
        default:
            return state;
    }
};

export default pdfSummaryReducer;
