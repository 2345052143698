import { GetUserPicture } from '../Apis/GetUserPicture';
import { PostUserPicture } from '../Apis/PostUserPicture';
import { DeleteUserPicture } from '../Apis/DeleteUserPicture';
import { handleApiError } from './notificationActions';

export const FETCH_USER_PICTURE_START = "FETCH_USER_PICTURE_START";
export const FETCH_USER_PICTURE_SUCCESS = "FETCH_USER_PICTURE_SUCCESS";
export const FETCH_USER_PICTURE_FAILURE = "FETCH_USER_PICTURE_FAILURE";

export const fetchUserPicture = () => async dispatch => {
    dispatch({ type: FETCH_USER_PICTURE_START });
    try {
        const response = await GetUserPicture();
        if (response.status === 200) {
            dispatch({ 
                type: FETCH_USER_PICTURE_SUCCESS, 
                payload: { details: response.result.result[0] } 
            });
        } else {
            handleApiError(response, dispatch);
        }
    } catch (error) {
        dispatch({ 
            type: FETCH_USER_PICTURE_FAILURE, 
            payload: { error: error.toString() } 
        });
    }
};


export const updateUserPicture = (payload) => async (dispatch) => {
    try {
        const response = await PostUserPicture(payload);
        if (response.status === 200) {
            dispatch(fetchUserPicture());
        } else {
            handleApiError(response, dispatch);
        }
    } catch (error) {
        handleApiError(error, dispatch);
    }
};

export const deleteUserPicture = (image_type) => async (dispatch) => {
    try {
        const response = await DeleteUserPicture(image_type);
        if (response.status === 200) {
            dispatch(fetchUserPicture());
        } else {
            handleApiError(response, dispatch);
        }
    } catch (error) {
        handleApiError(error, dispatch);
    }
};
