import {
  CognitoUser,
} from "amazon-cognito-identity-js";
import "./OTPConfirmationScreen.css";
import { useDispatch } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import UserPool from "../../UserPool";
import React from "react";
import AiFormError from "../../Components/AiFormError/ai-form-error";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { showNotification } from '../../actions/notificationActions';
import TagManager from "react-gtm-module";

function OTPConfirmation({setScreen,clientMetadata}) {
  const dispatch = useDispatch(); 
  const [bdopen, setBdOpen] = React.useState(false);
  const goToSignIn = () => setScreen("Login");
  const [invalidOTP, setInvalidOTP] = React.useState("");
  const [otp, setOtp] = React.useState(new Array(6).fill(""));
  const userEmail = sessionStorage.getItem("userEmail")

  const onSubmit = (event) => {
    event.preventDefault();


      var userData = {
        Username: userEmail.trim().toLowerCase(),
        Pool: UserPool,
      };
      var cognitoUser = new CognitoUser(userData);
      var otpValue = otp.join("");
      if (otpValue === "") {
        setInvalidOTP("Please enter your OTP");
      } else {
        setBdOpen(true);
        cognitoUser.confirmRegistration(otpValue, true, function (err, result) {
          if (err) {
            setInvalidOTP(err.message || JSON.stringify(err));
            setBdOpen(false);
            return;
          }
          TagManager.dataLayer({
            dataLayer: {
              'event': 'OTPVerified',
               userType: sessionStorage.getItem("selectedAcType"),
            },
          });
          dispatch(showNotification("Signup successful", "success", "toaster", null, false));
          goToSignIn();
        },clientMetadata);
      }
  };

  const handleOTPChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <>
    {bdopen ? (
      <div className="loading-overlay">
        <Loader/>
      </div>) : (
        <>
    <div className="ai-login-form">
      <div className="ai-login-section">
        <div className="ai-field-group">
          <h2>Sign Up</h2>
        </div>
        <form name="submitOTPForm" onSubmit={onSubmit}>
          <div className="ai-field-group">
            <div className="ai-row">
              <div className="ai-column">
                <div className="ai-otp-screen">

                  <p>
                    Please enter the one-time password (OTP) emailed to {`${userEmail}`}
                  </p>
                  {/* // ) : (
                  //   <p>
                  //     Please enter a OTP we sent on your registered email id {" "}
                  //   </p>
                  // )} */}
                </div>
              </div>
            </div>
            <div className="ai-row ai-align-center">
              <div className="ai-column">
                <div className="otp-field">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-form-value ai-form-value"
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) =>
                          handleOTPChange(e.target, index)
                        }
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                </div>
                <AiFormError errormessage={invalidOTP} />
              </div>
            </div>
            <div className="ai-row" />
          </div>
          <div className="ai-field-group">
            <div className="ai-row v-middle">
              <div className="ai-column">
                <div className="ai-signin" onClick={() => { setScreen("Login") }}>
                  Back to{" "}
                  <a to="/" className="ai-link">
                    Sign In
                  </a>
                </div>
              </div>
              <button className="ai-btn primary round">
                <ArrowForwardIcon />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>)}
    </>

  );
}

export default OTPConfirmation;
