import { fetchWithTimeout } from "./Timeout";
import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function DeletePortfolio(portfolio_id) {
    const Token = sessionStorage.getItem("Token");

    const header = { Authorization: Token };

    var raw = JSON.stringify({ 
    session_id: Token,
    portfolio_id: portfolio_id 
    });

    var requestOptions = {
        method: "DELETE",
        body: raw,
        headers: header,
        redirect: "follow",
    };
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetchWithTimeout(
                process.env.REACT_APP_DELETE_PORTFOLIO_API,
                requestOptions
            );
            const message = await response.json();
            if (!response.ok) {
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
