import React, { useEffect, useState, useMemo } from "react";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "./ForwardTest.scss";
import { addZeroes } from "../../Utilities/AddZeros";
import { TabContainer, TabPane, TabContent } from "react-bootstrap";
import { FetchForwardTest } from "../../Apis/FetchForwardTest";
import MonteCarloSimulation from "./MonteCarloGraph";
import PortfolioReturnGraph from "./PortfolioReturnGraph";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { Tooltip } from "react-tooltip";
import TagManager from "react-gtm-module";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../Components/Loader/Loader";
import {
  handleApiError,
  showConfirmNotification,
} from "../../actions/notificationActions";
import Toaster from "../../Components/Toaster/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { toPng } from "html-to-image";
import { updatePdfData } from "../../actions/pdfDataActions";
import TooltipComponent from "../TooltipComponent/TooltipComponent";
import { MaterialReactTable } from "material-react-table";
import CaptureButtons from "../CaptureButtons/CaptureButtons";
import { customColumnSort } from "../../Utilities/CustomColumnSort";

export function ForwardTest({ portfolioId, isEmpty }) {
  var currObj = {
    style: "currency",
    currency: "USD",
  };
  const forwardTestApiData = useSelector(
    (state) => state.pdfData["Forward Test"]
  );
  const [show2, setShow2] = useState(true);
  const levels = [
    { percent: 0, level: "Select" },
    { percent: 0.8, level: "80%" },
    { percent: 0.85, level: "85%" },
    { percent: 0.9, level: "90%" },
    { percent: 0.95, level: "95%" },
    { percent: 1.0, level: "100%" },
  ];
  const periods = [
    { period_int: 0, period_value: "Select" },
    { period_int: 30, period_value: "1 Month" },
    { period_int: 60, period_value: "2 Months" },
    { period_int: 91, period_value: "3 Months" },
    { period_int: 182, period_value: "6 Months" },
    { period_int: 365, period_value: "Year" },
  ];

  const simulationPeriod = [
    { simulation_period: 0, simulation_value: "Select" },
    { simulation_period: 0.09, simulation_value: "1 Month" },
    { simulation_period: 0.17, simulation_value: "2 Months" },
    { simulation_period: 0.25, simulation_value: "3 Months" },
    { simulation_period: 0.5, simulation_value: "6 Months" },
    { simulation_period: 1, simulation_value: "1 Year" },
    { simulation_period: 2, simulation_value: "2 Years" },
    { simulation_period: 3, simulation_value: "3 Years" },
    { simulation_period: 4, simulation_value: "4 Years" },
    { simulation_period: 5, simulation_value: "5 Years" },
    { simulation_period: 6, simulation_value: "6 Years" },
    { simulation_period: 7, simulation_value: "7 Years" },
    { simulation_period: 8, simulation_value: "8 Years" },
    { simulation_period: 9, simulation_value: "9 Years" },
    { simulation_period: 10, simulation_value: "10 Years" },
  ];

  const NumberOfPaths = [
    { path_no: 0, path_value: "Select" },
    { path_no: 100, path_value: "100" },
    { path_no: 500, path_value: "500" },
    { path_no: 1000, path_value: "1,000" },
    { path_no: 2000, path_value: "2,000" },
    { path_no: 5000, path_value: "5,000" },
    { path_no: 10000, path_value: "10,000" },
  ];

  const [level, setLevel] = React.useState(
    forwardTestApiData &&
      forwardTestApiData.portfolioid === portfolioId &&
      forwardTestApiData.allData.level
      ? forwardTestApiData.allData.level
      : 0.9
  );
  const [period, setPeriod] = React.useState(
    forwardTestApiData &&
      forwardTestApiData.portfolioid === portfolioId &&
      forwardTestApiData.allData.period
      ? forwardTestApiData.allData.period
      : 30
  );
  const [Simulation, setSimulation] = React.useState(
    forwardTestApiData &&
      forwardTestApiData.portfolioid === portfolioId &&
      forwardTestApiData.allData.simulation
      ? forwardTestApiData.allData.simulation
      : 1
  );
  const [Path, setPath] = React.useState(
    forwardTestApiData &&
      forwardTestApiData.portfolioid === portfolioId &&
      forwardTestApiData.allData.path
      ? forwardTestApiData.allData.path
      : 1000
  );
  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(false);
  const [GotData, setGotData] = React.useState(false);
  const [portfolioName, setPortfolioName] = React.useState([]);
  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [popupLoginRedierct, setPopupLoginRedirect] = React.useState(false);
  const [FtPortfolioID, setFtPortfolioID] = React.useState(0);
  const [selectedPortfolioTotalStocks, setSelectedPortfolioTotalStocks] =
    React.useState(0);
  const [selectedPortfolioTotalValue, setSelectedPortfolioTotalValue] =
    React.useState(0);
  const [tabletabvalue, setTableTabValue] = React.useState("Portfolio Details");
  const [tabvalue, setTabValue] = React.useState("Relative Performance");
  const [PortfolioDetailsTable, setPortfolioDetailsTable] = React.useState([]);
  const [SimulationDetailsTable, setSimulationDetailsTable] = React.useState(
    []
  );
  const [SimulationSummaryTable, setSimulationSummaryTable] = React.useState();

  const [HistogramShieldedData, setHistogramShieldedData] = React.useState([]);
  const [HistogramUnShieldedData, setHistogramUnShieldedData] = React.useState(
    []
  );
  const [HistogramRelativeData, setHistogramRelativeData] = React.useState([]);
  const [ShieldedGraphData, setShieldedGraphData] = React.useState([]);
  const [UnshieldedGraphData, setUnshieldedGraphData] = React.useState([]);
  const [DisableRunButton, setDisableRunButton] = React.useState(true);
  const [ShieldedMarketGraph, setShieldedMarketGraph] = React.useState([]);
  const [UnShieldedMarketGraph, setUnShieldedMarketGraph] = React.useState([]);
  const [GotForwardTData, setGotForwardTData] = React.useState(false);
  const [ApiCount, setApiCount] = React.useState(0);
  const [HistXMax, setHistXMax] = React.useState();
  const [HistYMax, setHistYMax] = React.useState();
  const [HistXMin, setHistXMin] = React.useState();
  const [LineYMin, setLineYMin] = React.useState();
  const [LineYMax, setLineYMax] = React.useState();
  const [PortfolioRisk, setPortfolioRisk] = React.useState(0);
  const [IndexRisk, setIndexRisk] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const dispatch = useDispatch();
  const [showGraph, setShowGraph] = React.useState(true);
  // console.log(forwardT)
  const [userSelectedData, setUserSelectedData] = useState([]);
  const changePeriod = (event) => {
    if (period === "") {
      setPopupState(true);
      setPopupMessage(
        "Protection period not recognized. Please try again or contact support@adaptive-investments.com."
      );
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setPeriod(event.target.value);
  };

  const changeLevel = (event) => {
    if (level === "") {
      setPopupState(true);
      setPopupMessage(
        "Protection level not recognized. Please try again or contact support@adaptive-investments.com."
      );
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setLevel(event.target.value);
  };

  const changeSimulationPeriod = (event) => {
    if (Simulation === "") {
      setPopupState(true);
      setPopupMessage("Please select valid simulation length");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setSimulation(event.target.value);
  };

  const changeNumberOfPath = (event) => {
    if (Path === "") {
      setPopupState(true);
      setPopupMessage("Please select valid number of path");
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    setPath(event.target.value);
  };

  if (DisableRunButton === true) {
    if (
      portfolioId !== 0 &&
      Simulation !== 0 &&
      period !== 0 &&
      level !== 0 &&
      Path !== 0 &&
      ApiCount === 0
    ) {
      setDisableRunButton(false);
    }
  }

  const captureAndDispatchData = async (
    dataAvailable,
    simulationtabledetails,
    data,
    level,
    period,
    Simulation,
    Path
  ) => {
    if (dataAvailable === "Data Available") {
      dispatch(
        updatePdfData("Forward Test", {
          allData: {
            ftdata: data,
            level: level,
            period: period,
            simulation: Simulation,
            path: Path,
          },
          portfolioid: portfolioId,
          dataFlag: dataAvailable,
        })
      );
    } else {
      dispatch(
        updatePdfData("Forward Test", {
          allData: {
            level: level,
            period: period,
            simulation: Simulation,
            path: Path,
          },
          portfolioid: portfolioId,
          dataFlag: dataAvailable,
        })
      );
    }
  };

  const calculateForwardTest = () => {
    if (period === 0) {
      setPopupState(true);
      setPopupMessage(
        "Protection period not recognized. Please try again or contact support@adaptive-investments.com."
      );
      setAlertType("warning");
      // setPeriod(0);
      return;
    }
    if (ApiCount > 0) {
      setDisableRunButton(false);
    }
    fetchForwardSimulation(portfolioId, Simulation, period, level, Path);
  };

  useEffect(() => {
    if (portfolioId !== undefined) {
      fetchForwardSimulation(portfolioId, Simulation, period, level, Path);
    }
  }, [portfolioId]);

  useEffect(() => {
    let lyst = [];
    lyst.push({
      level: level,
      period: period,
      Simulation: Simulation,
      Path: Path,
    });
    setUserSelectedData([...lyst]);
  }, [Simulation, period, level, Path]);

  function GenerateHistData(data) {
    let rawData = Object.entries(data).map(
      ([interval, { count, cumulative_expected_loss }]) => ({
        interval,
        count,
        cumulative_expected_loss,
      })
    );

    let intervalData = rawData.reduce(
      (acc, { interval, count, cumulative_expected_loss }) => {
        const intervals = JSON.stringify(interval)
          .replace("(", "")
          .replace("]", "")
          .split(",");
        const key = Math.floor(parseInt(interval.match(/-?\d+/), 10) / 5) * 5;
        const prev = acc.find((d) => d.interval === `${key}-${key + 5}`);
        if (prev) {
          prev.count += count;
        } else {
          acc.push({
            interval: `${key + 5}`,
            count,
            Return_percent: [
              Number(parseFloat(intervals[0].replace('"', "")).toFixed(2)),
              Number(parseFloat(intervals[1].replace('"', "")).toFixed(2)),
            ],
            Probability_of_occuring: Number(parseFloat(count * 100)).toFixed(2),
            Return_value: [
              Number(
                parseFloat(
                  Number(parseFloat(intervals[0].replace('"', "") / 100)) *
                    selectedPortfolioTotalValue
                )
              ).toFixed(2),
              Number(
                parseFloat(
                  Number(parseFloat(intervals[1].replace('"', "") / 100)) *
                    selectedPortfolioTotalValue
                )
              ).toFixed(2),
            ],
            Cumulative_expected_loss: Number(
              parseFloat(cumulative_expected_loss / 100) *
                selectedPortfolioTotalValue
            ).toFixed(2),
          });
        }
        return acc;
      },
      []
    );
    intervalData.sort((a, b) => parseInt(a.interval) - parseInt(b.interval));
    return intervalData;
  }
  function ProcessHistData(rawData) {
    const totalCount = rawData.frequency.reduce((sum, item) => sum + item, 0);

    const processedData = rawData.bin_range.map((bin, index) => {
      const binPercentage = bin * 100;
      const frequency = rawData.frequency[index];
      const cdfValue = rawData.cdf[index];
      const probabilityLoss = cdfValue;
      const probabilityHigher = 1 - cdfValue;
      return {
        interval: binPercentage,
        count: frequency / totalCount,
        cdf: cdfValue,
        probability_loss: probabilityLoss,
        probability_higher: probabilityHigher,
      };
    });
    return processedData;
  }
  function FillMissingIntervals(data1, data2) {
    const intervals1 = Object.keys(data1);
    const intervals2 = Object.keys(data2);
    const uniqueIntervals = new Set([...intervals1, ...intervals2]);

    const newData1 = {};
    const newData2 = {};
    for (const interval of uniqueIntervals) {
      const count1 = data1[interval]?.count || 0;
      const cm_loss1 = data1[interval]?.cumulative_expected_loss || 0;
      const count2 = data2[interval]?.count || 0;
      const cm_loss2 = data2[interval]?.cumulative_expected_loss || 0;
      newData1[interval] = {
        count: count1,
        cumulative_expected_loss: cm_loss1,
      };
      newData2[interval] = {
        count: count2,
        cumulative_expected_loss: cm_loss2,
      };
    }

    let output_1 = GenerateHistData(newData1);
    let output_2 = GenerateHistData(newData2);
    return [output_1, output_2];
  }
  useEffect(() => {
    // Hide the graph after it's initially rendered
    const timer = setTimeout(() => {
      setShowGraph(false);
    }, 7500); // Adjust the delay as needed
    return () => clearTimeout(timer);
  }, [showGraph]);

  const setApiData = (response) => {
    if (response.status === 200) {
      setGotForwardTData(true);
      setFlag(true);
      const data = response.result;
      // const [ShieldedData, UnShieldedData] = FillMissingIntervals(data["historgram_shielded_height"], data["historgram_unshielded_height"]);
      setSelectedPortfolioTotalValue(
        data.portfolio_details.reduce((sum, portfolio) => {
          return sum + portfolio["Market Value"];
        }, 0)
      );
      setHistogramShieldedData(ProcessHistData(data["histograms"]["shielded"]));
      setHistogramUnShieldedData(
        ProcessHistData(data["histograms"]["unshielded"])
      );
      setHistogramRelativeData(ProcessHistData(data["histograms"]["relative"]));
      setSimulationDetailsTable(data["simulation_details"]);
      setShieldedGraphData(data["cumulative_returns"]["shielded"]);
      // setUnshieldedGraphData(data['unshielded_portfolio_value']);
      setPortfolioDetailsTable(data["portfolio_details"]);
      setSimulationDetailsTable(data["simulation_details"]);
      setIndexRisk(data["index_vol"]);
      setPortfolioRisk(data["potfolio_vol"]);
      setSelectedIndex(data["chosen_index"]);
      setSimulationSummaryTable(data["summary_table"]);
      // const xMax = Math.max(
      //   ...ShieldedData.map((item) => item.interval),
      //   ...UnShieldedData.map((item) => item.interval)
      // );
      // const xMin = Math.min(
      //   ...ShieldedData.map((item) => item.interval),
      //   ...UnShieldedData.map((item) => item.interval)
      // );
      // setHistXMax(xMax);
      // setHistXMin(xMin);

      // const yMax = Math.max(
      //   ...ShieldedData.map((item) => item.count),
      //   ...UnShieldedData.map((item) => item.count)
      // );
      // setHistYMax(Math.ceil(yMax * 100) / 100);

      const lines1 = Object.keys(
        data["cumulative_returns"]["shielded"][0]
      ).filter((key) => key !== "index");
      // const lines2 = Object.keys(data["unshielded_portfolio_value"][0]).filter(key => key !== 'index')
      setShieldedMarketGraph(lines1);
      // setUnShieldedMarketGraph(lines2);

      const yMax1 = Math.max(
        ...data["cumulative_returns"]["shielded"].map((row) =>
          Math.max(...lines1.map((key) => row[key]))
        )
        // ...data["unshielded_portfolio_value"].map(row => Math.max(...lines2.map(key => row[key])))
      );

      const yMin1 = Math.min(
        ...data["cumulative_returns"]["shielded"].map((row) =>
          Math.max(...lines1.map((key) => row[key]))
        )
        // ...data["unshielded_portfolio_value"].map(row => Math.max(...lines2.map(key => row[key])))
      );

      setLineYMin(yMin1);
      setLineYMax(yMax1);

      TagManager.dataLayer({
        dataLayer: {
          event: "Forward Simulation",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
      setTimeout(() => {
        captureAndDispatchData(
          "Data Available",
          data["simulation_details"],
          response,
          level,
          period,
          Simulation,
          Path
        );
      }, 2000);
      setShowGraph(true);
    } else {
      handleApiError(response, dispatch);
      setTimeout(() => {
        captureAndDispatchData(
          "Data Not Available",
          level,
          period,
          Simulation,
          Path
        );
      }, 2000);
    }
  };

  const fetchForwardSimulation = async (
    portfolio_id,
    simulation_period,
    protection_period,
    protection_level,
    number_of_paths
  ) => {
    if (isEmpty) return;
    setBdOpen(true);
    setApiCount(1);
    setDisableRunButton(true);
    if (
      forwardTestApiData &&
      forwardTestApiData.portfolioid === portfolioId &&
      simulation_period === forwardTestApiData.allData.simulation &&
      protection_period === forwardTestApiData.allData.period &&
      protection_level === forwardTestApiData.allData.level &&
      number_of_paths === forwardTestApiData.allData.path
    ) {
      setPeriod(forwardTestApiData.allData.period);
      setLevel(forwardTestApiData.allData.level);
      setSimulation(forwardTestApiData.allData.simulation);
      setPath(forwardTestApiData.allData.path);
      setApiData(forwardTestApiData.allData.ftdata);
    } else {
      const response = await FetchForwardTest(
        portfolio_id,
        simulation_period,
        protection_period,
        protection_level,
        number_of_paths
      );
      setApiData(response);
    }
    setBdOpen(false);

    // } else {
    //   setBdOpen(false);
    //   setPopupState(true);
    //   setPopupMessage(
    //     "No stocks found for simulation to run. Please add atleast one stock."
    //   );
    //   setAlertType("error");
    //   setPopupLoginRedirect(false);
    //   return;
    // }
  };

  function handleTableTabChange(tabletabvalue) {
    setTableTabValue(tabletabvalue);
  }
  function handleTabChange(tabvalue) {
    setTabValue(tabvalue);
  }

  const PortfolioDetailsData = useMemo(
    () =>
      PortfolioDetailsTable.map((value, index) => ({
        sr: index + 1,
        symbol: value.Ticker,
        company: value.Name,
        quantity: Number(
          addZeroes(Number(parseFloat(value.Quantity).toFixed(2)))
        ).toLocaleString("en-US"),
        closingprice: Number(
          addZeroes(Number(parseFloat(value.ClosePrice).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        MktVal: Number(
          addZeroes(Number(parseFloat(value.MktVal).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        weight: (value.Weight * 100).toFixed(2) + "%",
        sigma: Number(
          parseFloat(value.sigma * Math.sqrt(252)).toFixed(3)
        ).toLocaleString("en-US"),
      })),
    [PortfolioDetailsTable]
  );

  const PortfolioTableColumns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      {
        accessorKey: "symbol",
        header: "Symbol",
        footer: "Total:",
        size: "130",
      },
      { accessorKey: "company", header: "Position Name", footer: "" },
      { 
        accessorKey: "quantity", 
        header: "Quantity", 
        footer: "",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'quantity')
      },
      {
        accessorKey: "closingprice",
        header: "Closing Price",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'closingprice'),
        footer: "",
      },
      {
        accessorKey: "MktVal",
        header: "Market Value",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'MktVal'),
        footer: Number(
          addZeroes(Number(parseFloat(selectedPortfolioTotalValue).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        size: "170",
      },
      {
        accessorKey: "weight",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'weight'),
        header: "Weight",
        footer: "100%",
      },
      {
        accessorKey: "sigma",
        header: "Implied Volatility",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'sigma'),
        footer: "",
      },
    ],
    [PortfolioDetailsTable]
  );

  const SimulationDetailsData = useMemo(
    () =>
      SimulationDetailsTable.map((value, index) => ({
        path: value.path_number + 1,
        endingportfoliovaluewithoutshield: Number(
          addZeroes(
            Number(parseFloat(value.ending_value_without_shield).toFixed(2))
          )
        ).toLocaleString("en-US", currObj),
        endingvaluewithshield: Number(
          addZeroes(
            Number(parseFloat(value.ending_value_with_shield).toFixed(2))
          )
        ).toLocaleString("en-US", currObj),
        totalshieldcost: Number(
          addZeroes(Number(parseFloat(value.total_cost).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        totalshieldpayoff: Number(
          addZeroes(Number(parseFloat(value.total_payout).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        averageshieldcost: Number(
          addZeroes(Number(parseFloat(value.average_cost).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        averageshieldpayoff: Number(
          addZeroes(Number(parseFloat(value.average_payout).toFixed(2)))
        ).toLocaleString("en-US", currObj),
      })),
    [SimulationDetailsTable]
  );

  const SimulationTableColumns = useMemo(
    () => [
      { accessorKey: "path", header: "Path", size: "70" },
      {
        accessorKey: "endingportfoliovaluewithoutshield",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'endingportfoliovaluewithoutshield'),
        header: "ENDING PORTFOLIO VALUE WITHOUT SHIELD",
        size: "130",
      },
      {
        accessorKey: "endingvaluewithshield",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'endingvaluewithshield'),
        header: "ENDING PORTFOLIO VALUE WITH SHIELD",
      },
      { 
        accessorKey: "totalshieldcost", 
        header: "TOTAL SHIELD COST",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'totalshieldcost')
       },
      {
        accessorKey: "totalshieldpayoff", 
        header: "TOTAL SHIELD PAYOFF",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'totalshieldpayoff')
      },
      { 
        accessorKey: "averageshieldcost", 
        header: "AVERAGE SHIELD COST",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'averageshieldcost') 
      },
      { 
        accessorKey: "averageshieldpayoff", 
        header: "AVERAGE SHIELD PAYOFF",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'averageshieldpayoff')
      },
    ],
    [PortfolioDetailsTable]
  );

  return (
    <>
      {!isEmpty ? (
        <>
          {bdopen ? (
            <div className="loading-overlay">
              <Loader />
            </div>
          ) : (
            <div className="drawercontent">
              <div className="forwardtest-holder">
                <div className="details_wrap self_assembly">
                  <div className="infocard-wrap">
                    <div id="selectsimulationlength" className="infocard">
                      <h3>Simulation Length</h3>
                      <Select
                        className="ai-form-value-bt"
                        onChange={changeSimulationPeriod}
                        value={Simulation}
                      >
                        {simulationPeriod.map((value, i) => (
                          <MenuItem value={value.simulation_period}>
                            {value.simulation_value}
                          </MenuItem>
                        ))}
                      </Select>
                      <span className="ai-selfassembly-select-arrow"></span>
                    </div>

                    <div id="selectprotectionperiod" className="infocard">
                      <h3>Protection Period</h3>
                      <Select
                        className="ai-form-value-bt"
                        value={period}
                        onChange={changePeriod}
                      >
                        {periods.map(
                          (value, i) =>
                            Simulation * 365 >= value.period_int && (
                              <MenuItem value={value.period_int}>
                                {Simulation !== 0
                                  ? value.period_value
                                  : "Please select valid simulation"}
                              </MenuItem>
                            )
                        )}
                      </Select>
                      <span className="ai-selfassembly-select-arrow"></span>
                    </div>

                    <div id="selectprotectionlevel" className="infocard">
                      <h3>Protection Level</h3>
                      <Select
                        className="ai-form-value-bt"
                        value={level}
                        onChange={changeLevel}
                      >
                        {levels.map((value, i) => (
                          <MenuItem value={value.percent}>
                            {value.level}
                          </MenuItem>
                        ))}
                      </Select>
                      <span className="ai-selfassembly-select-arrow"></span>
                    </div>

                    <div id="selectnumberofpaths" className="infocard">
                      <h3>Number of Paths</h3>
                      <Select
                        className="ai-form-value-bt"
                        value={Path}
                        onChange={changeNumberOfPath}
                      >
                        {NumberOfPaths.map((value, i) => (
                          <MenuItem value={value.path_no}>
                            {value.path_value}
                          </MenuItem>
                        ))}
                      </Select>
                      <span className="ai-selfassembly-select-arrow"></span>
                    </div>
                  </div>
                  <div className="buttonbuy">
                    <button
                      id="runforwardsimulation"
                      className="ai-btn primary solid"
                      // disabled={DisableRunButton}
                      onClick={calculateForwardTest}
                    >
                      Simulation
                    </button>
                    {!DisableRunButton && (
                      <p className="infomessage">
                        {" "}
                        Press “Run..” to recalculate the simulation{" "}
                      </p>
                    )}
                  </div>
                </div>
                <div className="dataforwardtest">
                  {GotForwardTData && !showGraph ? (
                    <>
                      <div className="riskhead" id="graphHeader">
                        <div className="riskstate">
                          <h3 id="portfoliorisk">{PortfolioRisk.toFixed(2)}</h3>
                          <div className="lblrisk">
                            Portfolio Volatility
                            <TooltipComponent id={"Portfolio Risk"} />
                          </div>
                        </div>
                        <div className="riskstate">
                          <h3 id="portfoliorisk">{IndexRisk.toFixed(2)}</h3>
                          <div className="lblrisk">
                            Index Volatility
                            <>
                              &nbsp;
                              <img
                                src="Assets/risk-info.png"
                                title={`Historical ${selectedIndex} Volatility`}
                              ></img>
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="forwardtest sum-container">
                        {/* <div className="forgraphs">
                  <div id="portfoliowithshield" className="forward-column">
                    <h3><img className="ai-img" src="Assets/shield-green.png" />Portfolio with Shield <Tooltip content="Simulated outcomes for the selected portfolio, when using the Portfolio Shield."><img src="Assets/risk-info.png"></img></Tooltip></h3>
                    {ShieldedGraphData.length === 0 ? (
                      <div style={{ textAlign: "center" }}>
                      </div>
                    ) : (<MonteCarloSimulation data={ShieldedGraphData} lines={ShieldedMarketGraph}
                      yMin={LineYMin} yMax={LineYMax} portfolioTotalValue={selectedPortfolioTotalValue} />)}
                  </div>
                  <div id="portfoliowithoutshield" className="forward-column">
                    <h3><img className="ai-img" src="Assets/shield-red.png" />Portfolio without Shield <Tooltip content="Simulated outcomes for the selected portfolio, unprotected i.e. NOT using the Portfolio Shield."><img src="Assets/risk-info.png"></img></Tooltip></h3>
                    {UnshieldedGraphData.length === 0 ? (
                      <div style={{ textAlign: "center" }}>
                      </div>
                    ) : (<MonteCarloSimulation data={UnshieldedGraphData} lines={UnShieldedMarketGraph}
                      yMin={LineYMin} yMax={LineYMax} portfolioTotalValue={selectedPortfolioTotalValue} />)}
                  </div>
                </div> */}
                        <CaptureButtons
                          elementType="chart"
                          id={
                            tabvalue === "Shielded Portfolio Return"
                              ? "bar1"
                              : tabvalue === "Relative Performance"
                              ? "bar3"
                              : "bar2"
                          }
                          chartName={
                            tabvalue === "Shielded Portfolio Return"
                              ? "Forward_Test_Chart_1"
                              : tabvalue === "Relative Performance"
                              ? "Forward_Test_Chart_2"
                              : "Forward_Test_Chart_3"
                          }
                          activeTab="Forward Test"
                          userSelectedData={userSelectedData[0]}
                        ></CaptureButtons>
                        <div className="forwardanalyze-portfolio">
                          <ul className="subnavbar-menu navtab">
                            <li>
                              <a
                                id="relativeperformancetab"
                                className={
                                  tabvalue === "Relative Performance"
                                    ? "SUBITEM-selected"
                                    : "SUBITEM"
                                }
                                eventKey="Relative Performance"
                                onClick={() =>
                                  handleTabChange("Relative Performance")
                                }
                              >
                                Relative Performance{" "}
                                <Tooltip content="The portfolio with Portfolio Shield will be protected from severe market downturns because protection is in place.The portfolio with Portfolio Shield will still benefit from stock market gains (less the cost of the Shield).">
                                  <img src="Assets/risk-info.png"></img>
                                </Tooltip>
                              </a>
                            </li>
                            <li>
                              <a
                                id="shieldedportfolioreturntab"
                                className={
                                  tabvalue === "Shielded Portfolio Return"
                                    ? "SUBITEM-selected "
                                    : "SUBITEM"
                                }
                                eventKey="Shielded Portfolio Return"
                                onClick={() =>
                                  handleTabChange("Shielded Portfolio Return")
                                }
                              >
                                Shielded Portfolio Return{" "}
                                <Tooltip content="The portfolio with Portfolio Shield will be protected from severe market downturns because protection is in place.The portfolio with Portfolio Shield will still benefit from stock market gains (less the cost of the Shield).">
                                  <img src="Assets/risk-info.png"></img>
                                </Tooltip>
                              </a>
                            </li>
                            <li>
                              <a
                                id="unshieldedportfolioreturntab"
                                className={
                                  tabvalue === "Unshielded Portfolio Return"
                                    ? "SUBITEM-selected"
                                    : "SUBITEM"
                                }
                                eventKey="Unshielded Portfolio Return"
                                onClick={() =>
                                  handleTabChange("Unshielded Portfolio Return")
                                }
                              >
                                Unshielded Portfolio Return{" "}
                                <Tooltip content="The portfolio with Portfolio Shield will be protected from severe market downturns because protection is in place.The portfolio with Portfolio Shield will still benefit from stock market gains (less the cost of the Shield).">
                                  <img src="Assets/risk-info.png"></img>
                                </Tooltip>
                              </a>
                            </li>
                          </ul>
                          <div className="tabbody">
                            <TabContainer defaultActiveKey={tabvalue}>
                              <TabContent>
                                <TabPane
                                  key="shielded-portfolio-return-tab"
                                  eventKey="Shielded Portfolio Return"
                                  active={
                                    tabvalue === "Shielded Portfolio Return"
                                  }
                                >
                                  <div
                                    id="portfolioreturnbarchart"
                                    className="barchart"
                                  >
                                    <div id="bar1">
                                      <PortfolioReturnGraph
                                        data={HistogramShieldedData}
                                        gradientId="colorUv1"
                                      />
                                    </div>
                                    {/* <div id="bar2"><PortfolioReturnGraph data={HistogramUnShieldedData} gradientId="colorUv2" xMin={HistXMin} xMax={HistXMax} yMax={HistYMax} /></div> */}
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "-10000px",
                                      top: "-10000px",
                                    }}
                                    className="barchart"
                                  >
                                    <div>
                                      <PortfolioReturnGraph
                                        data={HistogramRelativeData}
                                        gradientId="colorUv3"
                                      />
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane
                                  key="relative-performance-tab"
                                  eventKey="Relative Performance"
                                  active={tabvalue === "Relative Performance"}
                                >
                                  <div
                                    id="relativeperformancebarchart"
                                    className="barchart"
                                  >
                                    <div id="bar3">
                                      <PortfolioReturnGraph
                                        data={HistogramRelativeData}
                                        gradientId="colorUv3"
                                      />
                                    </div>
                                  </div>
                                </TabPane>
                                <TabPane
                                  key="unshielded-portfolio-return-tab"
                                  eventKey="Unshielded Portfolio Return"
                                  active={
                                    tabvalue === "Unshielded Portfolio Return"
                                  }
                                >
                                  <div className="barchart">
                                    <div id="bar2">
                                      <PortfolioReturnGraph
                                        data={HistogramUnShieldedData}
                                        gradientId="colorUv2"
                                      />
                                    </div>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </TabContainer>
                          </div>
                        </div>
                      </div>
                      <div
                        id="forwardtestriskreturnratio"
                        className="sum-container"
                        style={{ textAlign: "center", padding: "20px 0" }}
                      >
                        <CaptureButtons
                          elementType="chart"
                          id="forwardtestriskreturnratio"
                          chartName="Forward_Test_Chart_Risk"
                          activeTab="Forward Test"
                          userSelectedData={userSelectedData[0]}
                        ></CaptureButtons>
                        <table className="risk-return-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Portfolio With Market Shield</th>

                              <th>Portfolio Without Shield</th>
                              {/* {showPortfolioShield && <th>Portfolio with Portfolio Shield</th>} */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Annual Returns</td>
                              <td>
                                {parseFloat(
                                  parseFloat(
                                    SimulationSummaryTable["Shielded"][
                                      "Average Return"
                                    ]
                                  )
                                ).toFixed(2)}
                                %
                              </td>

                              <td>
                                {parseFloat(
                                  parseFloat(
                                    SimulationSummaryTable["Unshielded"][
                                      "Average Return"
                                    ]
                                  )
                                ).toFixed(2)}
                                %
                              </td>
                            </tr>

                            <tr>
                              <td>Downside Risk</td>
                              <td>
                                {parseFloat(
                                  parseFloat(
                                    SimulationSummaryTable["Shielded"][
                                      "Average Downside Risk"
                                    ]
                                  )
                                ).toFixed(2)}
                                %
                              </td>

                              <td>
                                {parseFloat(
                                  parseFloat(
                                    SimulationSummaryTable["Unshielded"][
                                      "Average Downside Risk"
                                    ]
                                  )
                                ).toFixed(2)}
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Return/Risk Ratio
                                <TooltipComponent id={"Risk Return Ratio"} />
                              </td>
                              <td>
                                {parseFloat(
                                  SimulationSummaryTable["Shielded"][
                                    "Risk-Return Ratio"
                                  ]
                                ).toFixed(2)}
                              </td>

                              <td>
                                {parseFloat(
                                  SimulationSummaryTable["Unshielded"][
                                    "Risk-Return Ratio"
                                  ]
                                ).toFixed(2)}
                              </td>
                              {/* {showPortfolioShield && <td>{parseFloat(rawData['return_ratio']['shielded_ratio']).toFixed(2)}</td>}
                            <td>{parseFloat(rawData['return_ratio']['sp500_ratio']).toFixed(2)}</td> */}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="portfolio-table contributio-table nogap factortbl sum-container">
                      <CaptureButtons
                            elementType="table"
                            chartName={
                              tabletabvalue === "Portfolio Details"
                                ? "Portfolio_Details_Table"
                                : "Forward_Test_Table"
                            }
                            activeTab={
                              tabletabvalue === "Portfolio Details"
                                ? "Portfolio Details"
                                : "Forward_Test"
                            }
                            tableHeaders={
                              tabletabvalue === "Portfolio Details"
                                ? PortfolioTableColumns
                                : SimulationTableColumns
                            }
                            tableData={
                              tabletabvalue === "Portfolio Details"
                                ? PortfolioDetailsData
                                : SimulationDetailsData
                            }
                            userSelectedData={userSelectedData[0]}
                          ></CaptureButtons>
                        <div className="table_holder table_head ai-toggle-con m0i">
                          <h3
                            onClick={() => setShow2(!show2)}
                            className="ai-toggle-ttl"
                          >
                            {show2 && <span className="minus">-</span>}{" "}
                            {!show2 && <span>+</span>}
                            <span className="tgltitle">Portfolio Details</span>
                          </h3>
                          {show2 && (
                            <>
                              <ul className="subnavbar-menu navtab">
                                <li>
                                  <a
                                    id="portfoliodetailstab"
                                    className={
                                      tabletabvalue === "Portfolio Details"
                                        ? "SUBITEM-selected "
                                        : "SUBITEM"
                                    }
                                    eventKey="Portfolio Details"
                                    onClick={() =>
                                      handleTableTabChange("Portfolio Details")
                                    }
                                  >
                                    {" "}
                                    Portfolio Details
                                  </a>
                                </li>
                                <li>
                                  <a
                                    id="simulationdetailstab"
                                    className={
                                      tabletabvalue === "Simulation Details"
                                        ? "SUBITEM-selected"
                                        : "SUBITEM"
                                    }
                                    eventKey="Simulation Details"
                                    onClick={() =>
                                      handleTableTabChange("Simulation Details")
                                    }
                                  >
                                    Simulation Details
                                  </a>
                                </li>
                              </ul>

                              <TabContainer defaultActiveKey={tabletabvalue}>
                                <TabContent className="table_height tabbody">
                                  <TabPane
                                    eventKey="Portfolio Details"
                                    active={
                                      tabletabvalue === "Portfolio Details"
                                    }
                                  >
                                    <TableContainer
                                      id="portfoliodetailstable"
                                      className="table_height"
                                    >
                                      <>
                                        <MaterialReactTable
                                          columns={PortfolioTableColumns}
                                          data={PortfolioDetailsData}
                                          enableStickyHeader
                                          enableStickyFooter
                                          enableBottomToolbar={false}
                                          enableColumnActions={false}
                                          enableDensityToggle={false}
                                          enableColumnResizing
                                          enableColumnOrdering
                                          enableRowPinning
                                          enablePagination={false}
                                          enableFullScreenToggle={false}
                                          muiTableContainerProps={{
                                            sx: {
                                              maxHeight: "400px",
                                            },
                                          }}
                                          initialState={{
                                            density: "compact",
                                          }}
                                          muiTableHeadCellProps={{
                                            sx: {
                                              backgroundColor: "white",
                                              position: "sticky",
                                              top: 0,
                                              zIndex: 1,
                                            },
                                          }}
                                          muiTableFootCellProps={{
                                            sx: {
                                              backgroundColor: "white",
                                              position: "sticky",
                                              bottom: 0,
                                              zIndex: 1,
                                            },
                                          }}
                                        />
                                      </>
                                    </TableContainer>
                                  </TabPane>
                                  <TabPane
                                    eventKey="Simulation Details"
                                    active={
                                      tabletabvalue === "Simulation Details"
                                    }
                                  >
                                    <TableContainer
                                      id="simulationdetailstable"
                                      className="table_height"
                                    >
                                      <>
                                        <MaterialReactTable
                                          columns={SimulationTableColumns}
                                          data={SimulationDetailsData}
                                          enableStickyHeader
                                          enableStickyFooter
                                          enableBottomToolbar={false}
                                          enableColumnActions={false}
                                          enableDensityToggle={false}
                                          enableColumnResizing
                                          enableColumnOrdering
                                          enableRowPinning
                                          enablePagination={false}
                                          enableFullScreenToggle={false}
                                          muiTableContainerProps={{
                                            sx: {
                                              maxHeight: "400px",
                                            },
                                          }}
                                          initialState={{
                                            density: "compact",
                                          }}
                                          muiTableHeadCellProps={{
                                            sx: {
                                              backgroundColor: "white",
                                              position: "sticky",
                                              top: 0,
                                              zIndex: 1,
                                            },
                                          }}
                                          muiTableFootCellProps={{
                                            sx: {
                                              backgroundColor: "white",
                                              position: "sticky",
                                              bottom: 0,
                                              zIndex: 1,
                                            },
                                          }}
                                        />
                                      </>
                                    </TableContainer>
                                  </TabPane>
                                </TabContent>
                              </TabContainer>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="forwardtest">
                        <div className="forgraphs">
                          <div id="portfoliowithshield">
                            <h3>
                              <img
                                className="ai-img"
                                src="Assets/shield-green.png"
                              />
                              Portfolio with Shield{" "}
                              <Tooltip content="Simulated outcomes for the selected portfolio, when using the Portfolio Shield.">
                                <img src="Assets/risk-info.png"></img>
                              </Tooltip>
                            </h3>
                            {ShieldedGraphData.length === 0 ? (
                              <div style={{ textAlign: "center" }}></div>
                            ) : (
                              <MonteCarloSimulation
                                data={ShieldedGraphData}
                                lines={ShieldedMarketGraph}
                                yMin={LineYMin}
                                yMax={LineYMax}
                                portfolioTotalValue={
                                  selectedPortfolioTotalValue
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="drawercontent">
          <p>Portfolio is Empty. Please add stock to proceed</p>
        </div>
      )}
    </>
  );
}
export default ForwardTest;
