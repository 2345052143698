import React from "react";
import AdaptiveModelPortfolioWizardComponent from "../../Components/OnboardClientPortfolio/AdaptiveModelPortfolioWizardComponent";
import Navbar from "../../Components/Navbar/Navbar";
import './AdaptiveImportWizardScreen.css';

function AdaptiveModelPortfolioWizardScreen() {

    return (
        <>
            <div className="container">
                <div className="onboard_wrap">
                    <AdaptiveModelPortfolioWizardComponent/>
                </div>
            </div>
        </>
    );
}

export default AdaptiveModelPortfolioWizardScreen;
